import { useState } from 'react';
import { useHistory } from "react-router-dom";
import resetPin from '../../assets/img/userskey.png';
import logo from '../../assets/img/logo.png';
import arrow from '../../assets/img/arrow.png';
import './style.css';
import Step from './Step';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../services/AxiosService';

export default function Resetpin() {
  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);

  let history = useHistory();

  const [input, setInput] = useState({
    bi: '',
    biTest: '',
    codigo: '',
    pin: '',
    equalPin: '',
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInput((values) => ({ ...values, [name]: value }));
  };

  const handleNext = async () => {
    setIsSubmitting(true);
    // setStep(step)
    try {
      let link, sendData;
      switch (step) {
        case 1:
          if (input.bi === '') {
            toast.error('Informe o BI para Validar', {
              position: toast.POSITION.TOP_CENTER,
            });
            return;
          }
          link = `/change-pin/${input.bi}`;
          const res = await axios.put(link);

          if (res.status === 200) {
            toast.success(res?.data?.message, {
              position: toast.POSITION.TOP_CENTER,
            });
            setStep(step + 1);
          }
          break;
        case 3:
          // if (input.codigo === '') {
          //   toast.error('Informe o código enviado para Validar', {
          //     position: toast.POSITION.TOP_CENTER,
          //   });
          //   return;
          // }
          // sendData = {
          //   confirmationCode: input.codigo,
          //   pin: {
          //     value: input.pin,
          //   },
          // };
          // link = `/change-pin/${input.bi}/confirmation-code`;
          // const res2 = await axios.put(link, sendData);
          // if (res2.status === 200) {
          //   toast.success(res2?.data?.message, {
          //     position: toast.POSITION.TOP_CENTER,
          //   });
          //   setStep(step + 1);
          // }
          break;
        case 2:
          if (input.pin === '' || input.equalPin === '') {
            toast.error('Informe o pin para Validar', {
              position: toast.POSITION.TOP_CENTER,
            });
            return;
          } else if (input.pin !== input.equalPin) {
            toast.error('O Pin informado náo são iguais', {
              position: toast.POSITION.TOP_CENTER,
            });
            return;
          }
          if (input.codigo === '') {
            toast.error('Informe o código enviado para Validar', {
              position: toast.POSITION.TOP_CENTER,
            });
            return;
          }
          sendData = {
            confirmationCode: input.codigo,
            pin: {
              value: input.pin,
            },
          };
          link = `/change-pin/${input.bi}/confirmation-code`;
          const res2 = await axios.put(link, sendData);
          if (res2.status === 200) {
            toast.success(res2?.data?.message, {
              position: toast.POSITION.TOP_CENTER,
            });
            // setStep(step + 1);
            history.push('/welcome');
            setTimeout(() => {
              window.location.reload();
            }, 500);

          }
          // setStep(step + 1);
          // sendData = {
          //   bi: input.bi,
          //   pin: input.pin,
          // };
          // link = `/change-pin/${input.bi}/set-pin`;
          // const res3 = await axios.put(link, sendData);
          // if (res3.status === 200) {
          //   toast.success(res3?.data?.message, {
          //     position: toast.POSITION.TOP_CENTER,
          //   });
          //   setStep(step + 1);
          // }
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
      toast.error('Ocorreu um erro. Tente novamente.', {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <div className='flex flex-row gap-10'>
            <div className='w-1/12'>
              <Step step={1} />
            </div>
            <div className='w-11/12 px-8 py-5 bbred shadow-store bg-white'>
              <h4 className='text-myred font-medium text-lg my-10'>
                INSERIR BI
              </h4>
              <div className=''>
                <div style={{ width: '100%' }}>
                  <label style={{ display: 'block' }} htmlFor=''>
                    BI*
                  </label>
                  <input
                    type='text'
                    placeholder='123456789LA052'
                    name='bi'
                    style={{ width: '90%' }}
                    value={input.bi}
                    onChange={handleChange}
                    disabled={false}
                  />
                </div>
              </div>

              <div className='mb-4 flex gap-10 mt-10 justify-end'>
                <button
                  onClick={handleNext}
                  disabled={isSubmitting}
                  className='border-none cursor-pointer px-16 uppercase py-4 font-bold rounded-lg bg-myred text-white'
                >
                  PRÓXIMO
                </button>
              </div>
            </div>
          </div>
        );
      // case 3:
      //   return (
      //     <div className='flex gap-10 flex-row'>
      //       <div className='w-1/12'>
      //         <Step step={3} />
      //       </div>
      //       <div className='w-11/12 px-8 py-5 bbred shadow-store bg-white'>
      //         <h4 className='text-myred text-lg my-10 font-medium'>
      //           CÓDIGO DE CONFIRMAÇÃO
      //         </h4>
      //         <div className='grid grid-cols-1 md:grid-cols-2 gap-10'>
      //           <div>
      //             <label htmlFor='codigo'> Inserir código* </label>
      //             <input
      //               type='text'
      //               placeholder='784949873'
      //               name='codigo'
      //               value={input.codigo || ''}
      //               onChange={handleChange}
      //               style={{ width: '90%' }}
      //             />
      //           </div>
      //         </div>
      //         <div className='mb-4 flex gap-10 mt-10 justify-end'>
      //           <button
      //             onClick={handleBack}
      //             className='cursor-pointer px-16 py-4 border-2 border-myred text-green rounded-lg bg-white font-bold back'
      //           >
      //             ANTERIOR
      //           </button>
      //           <button
      //             onClick={handleNext}
      //             disabled={isSubmitting}
      //             className='border-none cursor-pointer px-16 py-4 rounded-lg bg-myred font-bold text-white'
      //           >
      //             PRÓXIMO
      //           </button>
      //         </div>
      //       </div>
      //     </div>
      //   );
      case 2:
        return (
          <div className='flex gap-10 flex-row'>
            <div className='w-1/12'>
              <Step step={2} />
            </div>
            <div className='w-11/12 px-8 py-5 bbred shadow-store bg-white'>
              <h4 className='text-myred text-lg my-10 font-medium'>
                {/* CÓDIGO DE CONFIRMAÇÃO */}
                CRIE UM NOVO PIN
              </h4>
              <div>
                <label htmlFor='codigo'> Inserir código* </label>
                <input
                  type='text'
                  placeholder='784949873'
                  name='codigo'
                  value={input.codigo || ''}
                  onChange={handleChange}
                  style={{ width: '90%' }}
                />
              </div>
              <div className="mt10">
                <div>
                  <label htmlFor=''> Novo PIN* </label>
                  <input
                    type='text'
                    placeholder='784949873'
                    name='pin'
                    value={input.pin || ''}
                    onChange={handleChange}
                    style={{ width: '90%' }}
                  />
                </div>
              </div>
              <div className='mt10'>
                <div>
                  <label htmlFor=''> Confirmar PIN* </label>
                  <input
                    type='text'
                    placeholder='784949873'
                    name='equalPin'
                    value={input.equalPin || ''}
                    onChange={handleChange}
                    style={{ width: '90%' }}
                  />
                </div>
              </div>
              <div className='mb-4 flex gap-10 mt-10 justify-end'>
                <button
                  onClick={handleBack}
                  className='cursor-pointer px-16 py-4 border-2 border-myred text-green rounded-lg bg-white font-bold back'
                >
                  ANTERIOR
                </button>
                <button
                  onClick={handleNext}
                  disabled={isSubmitting}
                  className='border-none cursor-pointer px-16 py-4 rounded-lg bg-myred font-bold text-white'
                >
                  CONFIRMAR
                </button>
              </div>
            </div>
          </div>
        );
        // case 0:
        return (
          <div className=''>
            <div className='px-8 py-5 bbred shadow-store bg-white'>
              <h4 className='text-myred my-10 font-medium text-center'>
                REDEFINIR PIN
              </h4>
              <h5 className='text-center'> PIN alterado com sucesso ! </h5>

              <div className='flex justify-center text-center'>
                <div className='w-190 flex gap-2 bg-myred px-16 py-4 text-white border-r-2'>
                  <a style={{ color: '#fff', textDecoration: 'none' }} href='/'>
                    Entrar na conta
                  </a>
                  <img src={arrow} alt='' />
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className='flex flex-1 flex-wrap justify-evenly items-center py-8'>
      <div className='w-[40%]'>
        <img src={resetPin} alt='' className="" />
      </div>
      <div className='w-[60%]'>
        <div>
          <div className='imgcenter'>
            <img src={logo} alt='logo' className="w-full" />
          </div>
          <div className='my-6'>
            <h2 className='title'> REDEFINIR PIN </h2>
            <span>
              Para redefinir o seu PIN deves introduzir o seu BI para receber um
              código de confirmação.
            </span>
          </div>

          <div>{renderStepContent()}</div>
        </div>
      </div>
    </div>
  );
}