import axios from "axios";
import Keycloak from "keycloak-js";

const keycloak = Keycloak({
  clientId: process.env.REACT_APP_CLIENT_ID,
  realm: process.env.REACT_APP_REALM,
  url: process.env.REACT_APP_KEYCLOAK_URL,
  "ssl-required": process.env.REACT_APP_SSL_REQUIRED,
  resource: process.env.REACT_APP_RESOURCE,
  "public-client": process.env.REACT_APP_PUBLIC_CLIENT,
  "confidential-port": process.env.REACT_APP_CONFIDENTIAL_PORT,
});

const loginActivity = () => {
  const isFirstLogin = localStorage.getItem('firstLogin')
  if (!isFirstLogin) {
    axios.post(`${process.env.REACT_APP_API_URL}/atividades/dataLogin`, {
      nome: keycloak.tokenParsed.preferred_username,
      userId: keycloak.tokenParsed.email
    }).then(response => {
      const data = response.data;
      localStorage.setItem('userData', JSON.stringify(data));
      localStorage.setItem('firstLogin', '1');
    }).catch(error => {
      console.error('Erro no login actividades: ', error);
    });
  }
  return
}

const initKeycloak = (callback) => {
  keycloak
    .init({
      onLoad: "check-sso",
      silentCheckSsoRedirectUri:
        window.location.origin + "/silent-check-sso.html",
      pkceMethod: "S256",
      checkLoginIframe: false,
    })
    .then((authenticated) => {
      if (authenticated) {
        loginActivity()
        callback();
      } else {
        console.error("Unable to authenticate");
        doLogin();
      }
    });
};

const doLogin = () => {
  keycloak.login();
};

const doLogout = () => {
  KeycloakService.getUserInfo().then(async user => {

    if (user.email !== null && user.email !== "" && user.email !== undefined) {
      axios.put(
        `/atividades/dataLogout`,
        {
          nome: user.preferred_username,
          userId: user.email
        },
        {
          headers: {
            Authorization: `Bearer ${KeycloakService.getToken()}`,
          },
        }
      ).then((data) => {
        localStorage.clear();

      }).catch((e) => {
        console.log(e, "error");
      });
    } else {

      axios.put(
        `/atividades/dataLogout`,
        {
          nome: user.name,
          userId: user.preferred_username
        },
        {
          headers: {
            Authorization: `Bearer ${KeycloakService.getToken()}`,
          },
        }
      ).then((data) => {
        localStorage.clear();
      }).catch((e) => {
        console.log(e, "error");
      });
    }
  })


  keycloak.logout();
};

const getToken = () => {
  return keycloak.token;
};

const updateToken = (successCallback) => {
  keycloak
    .updateToken(10)
    .then(successCallback)
    .catch(() => window.location.reload());
};

const getUserInfo = async () => {
  try {
    const userInfo = await keycloak.loadUserInfo();
    return userInfo;
  } catch {
    window.location.reload();
  }
};

const hasRole = (roles) =>
  roles.some((role) => keycloak.tokenParsed.realm_access.roles.includes(role));

const KeycloakService = {
  initKeycloak,
  getToken,
  updateToken,
  doLogin,
  doLogout,
  getUserInfo,
  hasRole,
  keycloak,
};

export default KeycloakService;
