import React from 'react';
import '../cards.css';

const TinyCard = () => {
    return (
        <div className="card">
            <div className="card-icon">
                {/* Substitua pelo ícone que você está usando */}
                <svg width="24" height="24" fill="currentColor"><path d="M21 5H3C1.9 5 1 5.9 1 7V17C1 18.1 1.9 19 3 19H21C22.1 19 23 18.1 23 17V7C23 5.9 22.1 5 21 5ZM21 17H3V7H21V17ZM14 11H18V13H14V11Z" /></svg>
            </div>
            <div className="card-content">
                <div className="card-title">Bookings</div>
                <div className="card-value">281</div>
                <div className="card-percentage">+55% than last week</div>
            </div>
        </div>
    );
}

export default TinyCard;
