import * as actionTypes from "./actionTypes";

const initialState = {
  isAuthenticated: false,
  userType: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
      };

    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        userType: null,
      };

    case actionTypes.CHANGE_USERTYPE:
      return {
        ...state,
        userType: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
