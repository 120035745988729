import React, { useEffect, useState } from "react";
import KeycloakService from "../../../services/KeycloakService";
import axios from "../../../services/AxiosService";
import { Image } from "./Styles";
import defaultImage from "../../../assets/img/img-placeholder.png";

const Avatar = ({ imgUrlBase, itemId }) => {
  const [image, setImage] = useState("");

  useEffect(async () => {
    if (itemId) {
      const response = await axios.get(`/${imgUrlBase}/${itemId}/files`, {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
        responseType: "arraybuffer",
      });
      if (response.data) {
        let base64ImageString = Buffer.from(response.data, "binary").toString(
          "base64"
        );
        let srcValue = "data:image/png;base64," + base64ImageString;
        setImage(srcValue);
      }
    }
  }, [itemId]);

  return (
    <div style={{ display: "flex", padding: "8px 0" }}>
      <Image src={image || defaultImage} alt="Imagem" />
    </div>
  );
};

export default Avatar;
