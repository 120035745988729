import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import SideMenu from '../../components/SideMenu/SideMenu';
import Typography from "@mui/material/Typography";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function formatData(data) {
  const date = new Date(data);
  const dateString = date.toLocaleDateString('pt-BR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  const timeString = date.toLocaleTimeString('pt-BR', {
    hour: '2-digit',
    minute: '2-digit',
  });
  return `${dateString.replace(/\//g, '-')} ${timeString}`;
}

const ActividadesQuadro = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const quadro = JSON.parse(localStorage.getItem('quadroData'));

  useEffect(() => {
    console.log('Quadro data: ', quadro)
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/atividades/seeLogs/${quadro.institution}`);
        const formattedData = response.data.atividade.map((item) => ({
          ...item,
          logsContaId: {
            ...item.logsContaId,
            dataLogin: formatData(item.logsContaId.dataLogin),
            dataLogout: formatData(item.logsContaId.dataLogout),
          },
        }));
        setData(formattedData);
      } catch (error) {
        setData([])
        console.error('Erro ao buscar actividades: ', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  return (
    <Box sx={{ heigh: "100vh", display: "flex" }}>
      <SideMenu />
      <Box sx={{ display: "flex", flexDirection: "column", padding: "50px 50px" }}>
        <Box
          sx={{
            flexBasis: "100%",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <Typography style={{ fontSize: "32px", fontWeight: "bold", lineHeight: "32px" }}>{quadro ? quadro.name : 'Actividades'}</Typography>
            <Typography color="text.primary">Actividades recentes</Typography>
          </div>

          <Box sx={{ marginTop: "2rem" }}>
            <TableContainer component={Paper}>
              <Table sx={{ width: 950 }} aria-label="Tabela das actividades">
                <TableHead>
                  <TableRow>
                    <TableCell>Inicio de sessões</TableCell>
                    <TableCell>Fim de sessões</TableCell>
                    <TableCell>Actividades realizadas</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={3} align="center">Carregando...</TableCell>
                    </TableRow>
                  ) : data && data.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={3} align="center">Sem atividades</TableCell>
                    </TableRow>
                  ) : (
                    data && data.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.logsContaId.dataLogin}
                        </TableCell>
                        <TableCell>{row.logsContaId.dataLogout}</TableCell>
                        <TableCell>{row.atividade}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ActividadesQuadro;