import React from 'react';
// import { Box, Grid, Paper, Typography, LinearProgress } from '@mui/material';
// import { Bar, Doughnut } from 'react-chartjs-2';
// import { Chart, registerables } from 'chart.js';
import SideMenu from '../../admin/components/SideMenu/SideMenu';
import AdminTitleHeader from '../../admin/components/AdminTitleHeader/AdminTitleHeader';
import TinyCard from './cards/TinyCard';
import BarChart from './bar/BarChart';
import "./dash.css";


// Chart.register(...registerables);

const AdminDashboard = () => {

    return (
        <div className='flex'>
            <SideMenu />
            <div className="flex flex-col contain-dash">

                <AdminTitleHeader title={"Dashboard"} />
                <span> Explorando Insights: Navegando pelas Métricas e Tendências </span>

                <div className="card-container flex-col">
                    <div className='flex'>
                        <div className="col-3">
                            <TinyCard />
                        </div>
                        <div className="col-3">
                            <TinyCard />
                        </div>
                        <div className="col-3">
                            <TinyCard />
                        </div>
                        <div className="col-3">
                            <TinyCard />
                        </div>
                    </div> 
                    <BarChart />
                </div>
            </div>
        </div>
    );
};

export default AdminDashboard;
