import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import Input from "../../../components/Form/Input";
import AdminTitleHeader from "../../components/AdminTitleHeader/AdminTitleHeader";
import { Form } from "@unform/web";
import KeycloakService from "../../../services/KeycloakService";
import axios from "../../../services/AxiosService";
import * as Yup from "yup";
import PhoneSchema from "../../../schemas/PhoneSchema";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../../store/reducers/users/actionCreators";
import { useHistory } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import styles from './AlterarTelefoneQuadro.module.css'

const AlterarTelefone = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const formRef = useRef();
  const [sendCodeBtnText, setSendCodeBtnText] = useState("Enviar Código");
  const [resourceMobility, setResourceMobility] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [isPinVisible, setIsPinVisible] = useState(false);

  useEffect(() => {
    if (match.params.id) {
      axios
        .get(`/resource-mobility/employee-Info/${match.params.id}`, {
          headers: {
            Authorization: `Bearer ${KeycloakService.getToken()}`,
          },
        })
        .then((response) => {
          if (response.data) {
            setResourceMobility(response.data);
          }
        });
    }
  }, [match.params]);

  const changePhoneRequest = (data) => {
    axios
      .put(`/employees/${match.params.id}/phone`, data, {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      })
      .then((response) => {
        if (response.data.status === "OK") {
          dispatch(
            setSnackbar({
              snackbarIsOpen: true,
              snackbarMessage: response.data.message,
              snackbarType: "success",
            })
          );
          setCurrentStep(2);
          setSendCodeBtnText("Reenviar Código");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const validatePhone = (formData) => {
    PhoneSchema.validate(formData, {
      abortEarly: false,
    })
      .then((submitData) => {
        console.log(submitData);
        changePhoneRequest(submitData);
        formRef.current.setErrors({});
      })
      .catch((error) => {
        console.log("schema is not valid:");
        if (error instanceof Yup.ValidationError) {
          const errorMessages = {};
          error.inner.forEach((error) => {
            console.log(error.message);
            errorMessages[error.path] = error.message;
          });
          formRef.current.setErrors(errorMessages);
        }
      });
  };

  const sendConfirmationCode = (data) => {
    axios
      .put(`/employees/${match.params.id}/phone/confirmation-code`, data, {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      })
      .then((response) => {
        if (response.data.status === "OK") {
          dispatch(
            setSnackbar({
              snackbarIsOpen: true,
              snackbarMessage: response.data.message,
              snackbarType: "success",
            })
          );
          isAdmin
            ? history.push(`/admin/quadros/${match.params.id}`)
            : history.push(`/welcome`);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const confirmPhoneChange = (formData) => {
    sendConfirmationCode({ value: formData.code });
  };

  const handleSubmit = (formData, sendConfirmation) => {
    if (!sendConfirmation) {
      validatePhone({ ...formData, phone: formData.phone.trim() });
    }
    if (sendConfirmation) {
      confirmPhoneChange(formData);
    }
  };

  const isAdmin = history.location.pathname.includes("admin");

  const containerStyles = isAdmin ? {} : { minHeight: "80vh" };

  return (
    <div style={containerStyles}>
      {isAdmin && (
        <AdminTitleHeader
          title={`Alterar telefone de ${
            resourceMobility?.employee?.name || "Não Especificado"
          }`}
        />
      )}
      
      <Box className={styles.mycontainer}>
        <Box
          className={styles.myflex}>
          <Box sx={{ width: "50%", display: "flex", gap: "2rem" }}>
            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                alignItems: "flex-end",
                color: theme.palette.primary.dark,
                fontWeight: "bold",
              }}
            >
              <span>Nome do Quadro</span>
              <span>Orgão Pertencente</span>
            </Box>
            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                alignItems: "flex-start",
                color: "#3B4144",
              }}
            >
              <span>
                {resourceMobility?.employee?.name || "Não Especificado"}
              </span>
              <span>{resourceMobility?.unit?.name || "Não Especificado"}</span>
            </Box>
          </Box>
          <Box sx={{ width: "50%", display: "flex", gap: "2rem" }}>
            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                alignItems: "flex-end",
                color: theme.palette.primary.dark,
                fontWeight: "bold",
              }}
            >
              <span>Nome da Entidade</span>
              <span>Número Antigo</span>
            </Box>
            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                alignItems: "flex-start",
                color: "#3B4144",
              }}
            >
              <span>
                {resourceMobility?.institutionName || "Não Especificado"}
              </span>
              <span>
                {resourceMobility?.employee?.phone || "Não Especificado"}
              </span>
            </Box>
          </Box>
        </Box>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{}}
          style={{ boxShadow: "none", borderRadius: "0", padding: "0" }}
        >
          <Box className={styles.boxInput}>
            <Box
            className={styles.boxContainer} >
              <Box
                sx={{
                  width: "30%",
                  color: theme.palette.primary.dark,
                  fontWeight: "bold",
                  textAlign: "right",
                }}
              >
                <span>PIN</span>
              </Box>
              <Box
                sx={{
                  width: "40%",
                  color: "#3B4144",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #dfdfdf !important",
                    borderRadius: "4px",
                  }}
                >
                  <Input
                    label=""
                    isPasswordField
                    name="pin.value"
                    placeholder="Inserir PIN"
                    type={isPinVisible ? "number" : "password"}
                    style={{ width: "91.5%" }}
                  />
                  {isPinVisible ? (
                    <Visibility
                      style={{
                        fontSize: "24px",
                        paddingRight: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => setIsPinVisible((prev) => !prev)}
                    />
                  ) : (
                    <VisibilityOff
                      style={{
                        fontSize: "24px",
                        paddingRight: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => setIsPinVisible((prev) => !prev)}
                    />
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  width: "30%",
                  color: "#3B4144",
                }}
              ></Box>
            </Box>
            <Box className={styles.boxContainer}>
              <Box
                sx={{
                  width: "30%",
                  color: theme.palette.primary.dark,
                  fontWeight: "bold",
                  textAlign: "right",
                }}
              >
                <span>Actualizar Telefone</span>
              </Box>
              <Box
                sx={{
                  width: "40%",
                  color: "#3B4144",
                }}
              >
                <Input
                  name="phone"
                  label=""
                  type="text"
                  placeholder="Inserir Telefone com indicativo"
                />
              </Box>
              <Box
                sx={{
                  width: "30%",
                  color: "#3B4144",
                }}
              >
                <Button
                  sx={{ height: "45px" }}
                  variant="outlined"
                  type="cancel"
                  onClick={(e) => {
                    e.preventDefault();
                    const formData = formRef?.current?.getData();
                    handleSubmit(formData, false);
                  }}
                >
                  {sendCodeBtnText}
                </Button>
              </Box>
            </Box>
            <Box className={styles.boxContainer}>
              <Box
                sx={{
                  width: "30%",
                  color: theme.palette.primary.dark,
                  fontWeight: "bold",
                  textAlign: "right",
                }}
              >
                <span>Código de Verificação</span>
              </Box>
              <Box
                sx={{
                  width: "40%",
                  /* display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start", */
                  color: "#3B4144",
                }}
              >
                <Input
                  name="code"
                  label=""
                  type="text"
                  disabled={currentStep == 1}
                  placeholder="Inserir Código de verificação"
                />
              </Box>
              <Box
                sx={{
                  width: "30%",
                  color: "#3B4144",
                }}
              >
                <Button
                  sx={{ height: "45px" }}
                  disabled={currentStep == 1}
                  variant="contained"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    const formData = formRef?.current?.getData();
                    handleSubmit(formData, true);
                  }}
                >
                  Validar Alteração
                </Button>{" "}
              </Box>
            </Box>
          </Box>
        </Form>
      </Box>
    </div>
  );
};

export default AlterarTelefone;
