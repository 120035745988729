import React from "react";
import { LinearProgress, useTheme } from "@mui/material";
import { useSelector } from "react-redux";

const LinearProgressBar = ({ children }) => {
  const isLoading = useSelector((state) => state.adminReducer.isLoading);
  const isLoadingUsers = useSelector((state) => state.usersReducer.isLoading);
  const theme = useTheme();

  return (
    <div>
      {(isLoading || isLoadingUsers) && (
        <LinearProgress sx={{ backgroundColor: theme.custom.linkSelection }} />
      )}
      {children}
    </div>
  );
};

export default LinearProgressBar;
