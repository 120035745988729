import * as Yup from "yup";
import KnowledgeAreaSchema from "./KnowledgeAreaSchema";

const CourseSchema = Yup.object().shape({
  name: Yup.string().required("O curso é obrigatório."),
  knowledgeArea: KnowledgeAreaSchema.transform((value, original) => {
    return value === null ? { id: "" } : value;
  }).concat(
    Yup.object().shape({
      id: Yup.string().required("A area de conhecimento é obrigatória."),
    })
  ),
});

export default CourseSchema;
