export const employeeDocTypes = [
  { cod: "RESIDENCE_CARD", name: "Cartão de Residência" },
  { cod: "WORK_VISA", name: "Visto de Trabalho" },
  { cod: "PASSPORT", name: "Passaporte" },
];

export const mapCodToEmployeeDocTypes = (cod) => {
  switch (cod) {
    case "RESIDENCE_CARD":
      return "Cartão de Residência";
    case "WORK_VISA":
      return "Visto de Trabalho";
    case "PASSPORT":
      return "Passaporte";
    default:
      return "";
  }
};
