import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import editImage from '../../../assets/img/common/editImage.png';
import defaultImage from '../../../assets/img/img-placeholder.png';
import { Container, ImageContainer } from './styles';
import { ErrorMessage } from '../ErrorMessage';
import { Label } from '../Label';
import { Avatar } from '@mui/material';

const ImagePicker = ({ name, label, ...rest }) => {
  const imageInputRef = useRef();
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: imageInputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const loadImage = () => {
    imageInputRef.current.click();
  };

  const onImageLoad = (event) => {
    const files = event.target.files;
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        rest.onImageChange(files[0], reader.result);
      }
    };
    reader.readAsDataURL(files[0]);
  };

  return (
    <Container style={rest.styles}>
      <Label>{label}</Label>
      <ImageContainer>
        <Avatar
          src={rest.defaultValue || defaultImage}
          alt='Imagem escolhida'
          variant='square'
          sx={{ height: '100%', width: '100%' }}
        />
        <img src={editImage} alt='Escolha uma imagem' onClick={loadImage} />
        <input
          ref={imageInputRef}
          type='file'
          accept='.jpg, .png'
          onChange={(event) => onImageLoad(event)}
          disabled={rest.disabled}
        />
      </ImageContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
};

export default ImagePicker;
