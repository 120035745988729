import * as Yup from "yup";

const PhoneSchema = Yup.object().shape({
  phone: Yup.string(),
  // .typeError("O telefone deve ter somente dígitos.")
  // .test(
  //   "isLengthValid",
  //   "O telefone deve ter 9 dígitos sem espaços.",
  //   (val) => {
  //     return val !== "" ? val.length === 9 : true;
  //   }
  // ),
});

export default PhoneSchema;
