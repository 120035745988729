import styled from "styled-components";

export const Styles = styled.div`
  margin: 4rem 12rem 1rem 12rem;
  z-index: 9999;

  @media (max-width: 640px) {
    margin: 1rem 1rem 1rem 1rem
  }

  @media (max-width: 768px) {
    margin: 1rem 1rem 1rem 1rem
  }
`;
