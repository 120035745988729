import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  error: "",
  profilePicture: "",
  countries: [],
  entityItems: [],
  totalPages: 0,
  totalElements: 0,
  entityItem: null,
  entityImage: null,
  secondaryEntityItems: null,
  snackbarIsOpen: false,
  snackbarMessage: "",
  snackbarType: "success",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GETALL_COUNTRIES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.GETALL_COUNTRIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        countries: action.payload.data,
      };

    case actionTypes.GETALL_COUNTRIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        countries: [],
        error: action.payload.error,
      };

    case actionTypes.GET_ENTITIES_REQUEST:
      return {
        ...state,
        isLoading: true,
        entityItems: [],
        totalPages: 0,
        totalElements: 0,
      };

    case actionTypes.GET_ENTITIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        entityItems: action.payload.data,
        totalPages: action.payload.totalPages || 0,
        totalElements: action.payload.totalElements || 0,
      };

    case actionTypes.GET_ENTITIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        entityItems: [],
        totalPages: 0,
        totalElements: 0,
        error: action.payload.error,
      };

    case actionTypes.GET_EMPLOYEES_BY_INSTITUTION_REQUEST:
      return {
        ...state,
        isLoading: true,
        entityItems: [],
        totalPages: 0,
        totalElements: 0,
      };

    case actionTypes.GET_EMPLOYEES_BY_INSTITUTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        entityItems: action.payload.data,
        totalPages: action.payload.totalPages || 0,
        totalElements: action.payload.totalElements || 0,
      };

    case actionTypes.GET_EMPLOYEES_BY_INSTITUTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        entityItems: [],
        totalPages: 0,
        totalElements: 0,
        error: action.payload.error,
      };

    case actionTypes.GET_SECONDARY_ENTITIES_REQUEST:
      return {
        ...state,
        isLoading: true,
        secondaryEntityItems: null,
      };

    case actionTypes.GET_SECONDARY_ENTITIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        secondaryEntityItems: action.payload.data,
      };

    case actionTypes.GET_SECONDARY_ENTITIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        secondaryEntityItems: [],
        error: action.payload.error,
      };

    case actionTypes.GET_ENTITY_REQUEST:
      return {
        ...state,
        isLoading: true,
        entityItem: null,
      };

    case actionTypes.GET_ENTITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        entityItem: action.payload.data,
      };

    case actionTypes.GET_ENTITY_FAILURE:
      return {
        ...state,
        isLoading: false,
        entityItem: null,
        error: action.payload.error,
      };

    case actionTypes.ADD_ENTITY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.ADD_ENTITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.ADD_ENTITY_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.EDIT_ENTITY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.EDIT_ENTITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.EDIT_ENTITY_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.DELETE_ENTITY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.DEACTIVATE_ENTITY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.DELETE_ENTITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.DELETE_ENTITY_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.SEARCH_ENTITIES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.SEARCH_ENTITIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        entityItems: action.payload.data,
        totalPages: action.payload.totalPages || 0,
        totalElements: action.payload.totalElements || 0,
      };

    case actionTypes.SEARCH_ENTITIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        entityItems: [],
        totalPages: 0,
        totalElements: 0,
      };

    case actionTypes.FILTER_ENTITIES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.FILTER_ENTITIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        entityItems: action.payload.data,
        totalPages: action.payload.totalPages || 0,
        totalElements: action.payload.totalElements || 0,
      };

    case actionTypes.FILTER_ENTITIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        entityItems: [],
        totalPages: 0,
        totalElements: 0,
      };

    case actionTypes.GET_ENTITY_IMAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.GET_ENTITY_IMAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        entityImage: action.payload,
      };

    case actionTypes.GET_ENTITY_IMAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        entityImage: null,
        error: action.payload.error,
      };

    case actionTypes.SUBMIT_ENTITY_ACTION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.SUBMIT_ENTITY_ACTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.SUBMIT_ENTITY_ACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case actionTypes.UPLOAD_ENTITY_FILES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.UPLOAD_ENTITY_FILES_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.UPLOAD_ENTITY_FILES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case actionTypes.SET_SNACKBAR:
      return {
        ...state,
        snackbarIsOpen: action.payload.snackbarIsOpen,
        snackbarMessage: action.payload.snackbarMessage,
        snackbarType: action.payload.snackbarType,
      };

    default:
      return state;
  }
};

export default reducer;
