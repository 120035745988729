import { Box, Button } from '@mui/material';
import { Form } from '@unform/web';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from '../../../components/Form/DatePicker';
import Select from '../../../components/Form/Select';
import TextArea from '../../../components/Form/TextArea';
import KeycloakService from '../../../services/KeycloakService';
import axios from '../../../services/AxiosService';
import AdminTitleHeader from '../../components/AdminTitleHeader/AdminTitleHeader';
import useAlocacaoQuadros from '../../hooks/useAlocacaoQuadros';
import styles from './Quadro.module.css';

const CessarQuadro = ({ match }) => {
  const formRef = useRef();
  const [units, setUnits] = useState([]);

  const { ceaseFunctionsDialogSchemaValidation, ceaseFunctionsFieldsMetaData } =
    useAlocacaoQuadros({ institutionId: 0, unitId: 0 });

  useEffect(() => {
    if (match.params.id) {
      axios
        .get(`/resource-mobility?employeeId=${match.params.id}`, {
          headers: {
            Authorization: `Bearer ${KeycloakService.getToken()}`,
          },
        })
        .then((response) => {
          if (response.data.content.length > 0) {
            const filteredUnits = response.data.content?.map(
              (resource) => resource?.unit
            );
            setUnits(filteredUnits);
          }
        });
    }
  }, []);

  const fixDate = (formMeta, dataToBeReturned) => {
    formMeta.forEach((meta) => {
      if (meta.type == 'date') {
        const parentKey = meta.field;

        if (!dataToBeReturned[parentKey]) return dataToBeReturned;

        const unFormattedDate = moment(dataToBeReturned[parentKey]).format(
          'yyyy-MM-DD'
        );

        console.log('unFormattedDate', unFormattedDate);
        return (dataToBeReturned[parentKey] = unFormattedDate);
      }
    });
    return dataToBeReturned;
  };

  const handleCeaseFunctionsSubmit = (formData, { reset }) => {
    const withFixedValues = fixDate(
      ceaseFunctionsFieldsMetaData.formMeta,
      formData
    );
    console.log(withFixedValues, 'handleCeaseFunctionsSubmit');
    const dataActivity = JSON.parse(localStorage.getItem('userData'));
    const submitData = {
      obs: withFixedValues.obs,
      date: withFixedValues.date,
      motive: withFixedValues.motive,
      unitId: withFixedValues.unit.name,
      employeeId: match.params.id,
    };
    axios
      .put(`/resource-mobility/cease-functions`, submitData, {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      })
      .then((response) => {
        if (response.data.content.length > 0) {
          const filteredUnits = response.data.content?.map(
            (resource) => resource?.unit
          );
          setUnits(filteredUnits);
        }
      })
      .catch((e) => console.log(e));
  };

  const unitMetadata = {
    field: 'unit.name',
    label: 'Unidade Organica de Destino',
    type: 'select',
    options: units || [],
    fieldId: 'unit.id',
  };

  return (
    <div className={styles.sessarContainer}>
      {' '}
      <AdminTitleHeader title={`Cessar Quadro`} />
      <Box
        sx={{
          backgroundColor: '#fff',
          padding: '2rem 1.5rem',
          display: 'flex',
        }}
      >
        <Box
          sx={{
            flexBasis: '100%',
          }}
        >
          <Form
            ref={formRef}
            onSubmit={handleCeaseFunctionsSubmit}
            initialData={{}}
            style={{ borderRadius: 'unset', boxShadow: 'none', padding: '0' }}
          >
            <Box sx={{ display: 'flex', flexFlow: 'row wrap', gap: '1rem' }}>
              <Box sx={{ width: { xs: '100%', sm: '100%', md: '95%' } }}>
                <Select
                  name={ceaseFunctionsFieldsMetaData.formMeta[0].field}
                  options={ceaseFunctionsFieldsMetaData.formMeta[0].options}
                  label={ceaseFunctionsFieldsMetaData.formMeta[0].label}
                  disabled={ceaseFunctionsFieldsMetaData.formMeta[0].disabled}
                />
              </Box>
              <Box sx={{ width: { xs: '100%', sm: '100%', md: '95%' } }}>
                <DatePicker
                  name={ceaseFunctionsFieldsMetaData.formMeta[1].field}
                  type={ceaseFunctionsFieldsMetaData.formMeta[1].type}
                  label={ceaseFunctionsFieldsMetaData.formMeta[1].label}
                  placeholder={
                    ceaseFunctionsFieldsMetaData.formMeta[1].placeholder
                  }
                  dateFormat={
                    ceaseFunctionsFieldsMetaData.formMeta[1].dateFormat
                  }
                />
              </Box>
              <Box sx={{ width: { xs: '100%', sm: '100%', md: '95%' } }}>
                <Select
                  name={unitMetadata.field}
                  options={unitMetadata.options}
                  label={unitMetadata.label}
                  disabled={unitMetadata.disabled}
                />
              </Box>
              <Box sx={{ width: { xs: '100%', sm: '100%', md: '95%' } }}>
                <Select
                  name={ceaseFunctionsFieldsMetaData.formMeta[2].field}
                  options={ceaseFunctionsFieldsMetaData.formMeta[2].options}
                  label={ceaseFunctionsFieldsMetaData.formMeta[2].label}
                  disabled={ceaseFunctionsFieldsMetaData.formMeta[2].disabled}
                />
              </Box>
              <Box sx={{ width: { xs: '100%', sm: '100%', md: '95%' } }}>
                <TextArea
                  name={ceaseFunctionsFieldsMetaData.formMeta[3].field}
                  type={ceaseFunctionsFieldsMetaData.formMeta[3].type}
                  label={ceaseFunctionsFieldsMetaData.formMeta[3].label}
                  disabled={ceaseFunctionsFieldsMetaData.formMeta[3].disabled}
                  style={{ width: '96%' }}
                />
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  marginTop: 10,
                  gap: '1rem',
                }}
              >
                <>
                  <Button
                    variant='outlined'
                    type='cancel'
                    onClick={(e) => {
                      e.preventDefault();
                      // history.goBack();
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button type='submit' variant='contained'>
                    Guardar
                  </Button>
                </>
              </Box>
            </Box>
          </Form>
        </Box>
      </Box>
    </div>
  );
};

export default CessarQuadro;
