import React from "react";
import { ErrorMessage } from "../../../components/Form/ErrorMessage";
import { Box, Button, Stack, Typography } from "@mui/material";
import RenderOnRole from "../../../helpers/RenderOnRole";
import useAlterarPin from "./useAlterarPin";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Input } from "./styles";

const AlterarPin = () => {
  const {
    error,
    formValues,
    onFormValueChange,
    handleSubmit,
    handleConfirmationCodeSubmit,
    showConfirmationCode,
    isNewPinVisible,
    setIsNewPinVisible,
    isActualPinVisible,
    setIsActualPinVisible,
    isConfirmationPinVisible,
    setIsConfirmationPinVisible,
  } = useAlterarPin();

  const formStyles = {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
  };

  const labelStyles = {
    fontSize: "16px !important",
    fontWeight: "bold !important",
  };

  return (
    <RenderOnRole roles={["user-quadro"]}>
      <Box
        height="65vh"
        mx="auto"
        my="4rem"
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Typography
          variant="h3"
          margin="0 auto 3rem auto"
          fontSize="24px !important"
          fontWeight="bold"
          letterSpacing="2px"
        >
          Alterar Pin
        </Typography>
        <form id="pin-form" style={formStyles}>
          <Stack gap="1.5rem">
            {!showConfirmationCode && (
              <>
                {" "}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.25rem",
                  }}
                >
                  <label style={labelStyles}>PIN Actual</label>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #dfdfdf !important",
                      borderRadius: "4px",
                    }}
                  >
                    <Input
                      type={isActualPinVisible ? "number" : "password"}
                      name="oldPin"
                      placeholder="Insira o seu pin actual"
                      value={formValues.oldPin}
                      onChange={onFormValueChange}
                    />
                    {isActualPinVisible ? (
                      <Visibility
                        style={{
                          fontSize: "24px",
                          paddingRight: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => setIsActualPinVisible((prev) => !prev)}
                      />
                    ) : (
                      <VisibilityOff
                        style={{
                          fontSize: "24px",
                          paddingRight: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => setIsActualPinVisible((prev) => !prev)}
                      />
                    )}
                  </Box>
                  {error?.oldPin && <ErrorMessage>{error.oldPin}</ErrorMessage>}
                </Box>
                <Box>
                  <label style={labelStyles}>Novo PIN</label>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #dfdfdf !important",
                      borderRadius: "4px",
                    }}
                  >
                    <Input
                      type={isNewPinVisible ? "number" : "password"}
                      name="newPin"
                      placeholder="Insira o seu novo pin"
                      value={formValues.newPin}
                      onChange={onFormValueChange}
                    />
                    {isNewPinVisible ? (
                      <Visibility
                        style={{
                          fontSize: "24px",
                          paddingRight: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => setIsNewPinVisible((prev) => !prev)}
                      />
                    ) : (
                      <VisibilityOff
                        style={{
                          fontSize: "24px",
                          paddingRight: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => setIsNewPinVisible((prev) => !prev)}
                      />
                    )}
                  </Box>
                  {error?.newPin && <ErrorMessage>{error.newPin}</ErrorMessage>}
                </Box>
                <Box>
                  <label style={labelStyles}>Confirmar PIN</label>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #dfdfdf !important",
                      borderRadius: "4px",
                    }}
                  >
                    <Input
                      type={isConfirmationPinVisible ? "number" : "password"}
                      name="confirmPin"
                      placeholder="Confirme o seu novo pin"
                      value={formValues.confirmPin}
                      onChange={onFormValueChange}
                    />
                    {isConfirmationPinVisible ? (
                      <Visibility
                        style={{
                          fontSize: "24px",
                          paddingRight: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          setIsConfirmationPinVisible((prev) => !prev)
                        }
                      />
                    ) : (
                      <VisibilityOff
                        style={{
                          fontSize: "24px",
                          paddingRight: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          setIsConfirmationPinVisible((prev) => !prev)
                        }
                      />
                    )}
                  </Box>
                  {error?.confirmPin && (
                    <ErrorMessage>{error.confirmPin}</ErrorMessage>
                  )}
                </Box>
              </>
            )}
            {showConfirmationCode && (
              <Box>
                <label style={labelStyles}>Código de Confirmação</label>
                <Input
                  type="text"
                  name="confirmationCode"
                  placeholder="Insira o código de confirmação"
                  value={formValues.confirmationCode}
                  onChange={onFormValueChange}
                />
                {error?.confirmationCode && (
                  <ErrorMessage>{error.confirmationCode}</ErrorMessage>
                )}
              </Box>
            )}
          </Stack>
          <Button
            sx={{
              backgroundColor: "#5EbEE1",
              color: "white",
              width: "100%",
              marginTop: "1.5rem",
              alignSelf: "stretch",
              "&:hover": {
                backgroundColor: "#00A0AF",
              },
            }}
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              showConfirmationCode
                ? handleConfirmationCodeSubmit(formValues)
                : handleSubmit(formValues);
            }}
          >
            Confirmar
          </Button>
        </form>
      </Box>
    </RenderOnRole>
  );
};

export default AlterarPin;
