export const GET_PROFILEPICTURE_REQUEST = "@ADMIN/GET_PROFILEPICTURE_REQUEST";
export const GET_PROFILEPICTURE_SUCCESS = "@ADMIN/GET_PROFILEPICTURE_SUCCESS";
export const GET_PROFILEPICTURE_FAILURE = "@ADMIN/GET_PROFILEPICTURE_FAILURE";

export const GETALL_COUNTRIES_REQUEST = "@ADMIN/GETALL_COUNTRIES_REQUEST";
export const GETALL_COUNTRIES_SUCCESS = "@ADMIN/GETALL_COUNTRIES_SUCCESS";
export const GETALL_COUNTRIES_FAILURE = "@ADMIN/GETALL_COUNTRIES_FAILURE";

export const GET_ENTITY_IMAGE_REQUEST = "@ADMIN/GET_IMAGE_REQUEST";
export const GET_ENTITY_IMAGE_SUCCESS = "@ADMIN/GET_IMAGE_SUCCESS";
export const GET_ENTITY_IMAGE_FAILURE = "@ADMIN/GET_IMAGE_FAILURE";

export const ADD_ENTITY_IMAGE_REQUEST = "@ADMIN/ADD_IMAGE_REQUEST";
export const ADD_ENTITY_IMAGE_SUCCESS = "@ADMIN/ADD_IMAGE_SUCCESS";
export const ADD_ENTITY_IMAGE_FAILURE = "@ADMIN/ADD_IMAGE_FAILURE";

export const EDIT_ENTITY_IMAGE_REQUEST = "@ADMIN/EDIT_IMAGE_REQUEST";
export const EDIT_ENTITY_IMAGE_SUCCESS = "@ADMIN/EDIT_IMAGE_SUCCESS";
export const EDIT_ENTITY_IMAGE_FAILURE = "@ADMIN/EDIT_IMAGE_FAILURE";

// NEW
export const GET_ENTITIES_REQUEST = "@ADMIN/GET_ENTITIES_REQUEST";
export const GET_ENTITIES_SUCCESS = "@ADMIN/GET_ENTITIES_SUCCESS";
export const GET_ENTITIES_FAILURE = "@ADMIN/GET_ENTITIES_FAILURE";

export const GET_EMPLOYEES_BY_INSTITUTION_REQUEST =
  "@ADMIN/GET_EMPLOYEES_BY_INSTITUTION_REQUEST";
export const GET_EMPLOYEES_BY_INSTITUTION_SUCCESS =
  "@ADMIN/GET_EMPLOYEES_BY_INSTITUTION_SUCCESS";
export const GET_EMPLOYEES_BY_INSTITUTION_FAILURE =
  "@ADMIN/GET_EMPLOYEES_BY_INSTITUTION_FAILURE";

export const SUBMIT_ENTITY_ACTION_REQUEST =
  "@ADMIN/SUBMIT_ENTITY_ACTION_REQUEST";
export const SUBMIT_ENTITY_ACTION_SUCCESS =
  "@ADMIN/SUBMIT_ENTITY_ACTION_SUCCESS";
export const SUBMIT_ENTITY_ACTION_FAILURE =
  "@ADMIN/SUBMIT_ENTITY_ACTION_FAILURE";

export const GET_SECONDARY_ENTITIES_REQUEST =
  "@ADMIN/GET_SECONDARY_ENTITIES_REQUEST";
export const GET_SECONDARY_ENTITIES_SUCCESS =
  "@ADMIN/GET_SECONDARY_ENTITIES_SUCCESS";
export const GET_SECONDARY_ENTITIES_FAILURE =
  "@ADMIN/GET_SECONDARY_ENTITIES_FAILURE";

export const GET_ENTITY_REQUEST = "@ADMIN/GET_ENTITY_REQUEST";
export const GET_ENTITY_SUCCESS = "@ADMIN/GET_ENTITY_SUCCESS";
export const GET_ENTITY_FAILURE = "@ADMIN/GET_ENTITY_FAILURE";

export const EDIT_ENTITY_REQUEST = "@ADMIN/EDIT_ENTITY_REQUEST";
export const EDIT_ENTITY_SUCCESS = "@ADMIN/EDIT_ENTITY_SUCCESS";
export const EDIT_ENTITY_FAILURE = "@ADMIN/EDIT_ENTITY_FAILURE";

export const ADD_ENTITY_REQUEST = "@ADMIN/ADD_ENTITY_REQUEST";
export const ADD_ENTITY_SUCCESS = "@ADMIN/ADD_ENTITY_SUCCESS";
export const ADD_ENTITY_FAILURE = "@ADMIN/ADD_ENTITY_FAILURE";

export const DELETE_ENTITY_REQUEST = "@ADMIN/DELETE_ENTITY_REQUEST";
export const DEACTIVATE_ENTITY_REQUEST = "@ADMIN/DEACTIVATE_ENTITY_REQUEST";
export const ACTIVATE_ENTITY_REQUEST = "@ADMIN/ACTIVATE_ENTITY_REQUEST";

export const DELETE_ENTITY_SUCCESS = "@ADMIN/DELETE_ENTITY_SUCCESS";
export const DEACTIVATE_ENTITY_SUCCESS = "@ADMIN/DEACTIVATE_ENTITY_SUCCESS";
export const ACTIVATE_ENTITY_SUCCESS = "@ADMIN/ACTIVATE_ENTITY_SUCCESS";

export const DELETE_ENTITY_FAILURE = "@ADMIN/DELETE_ENTITY_FAILURE";
export const DEACTIVATE_ENTITY_FAILURE = "@ADMIN/DEACTIVATE_ENTITY_FAILURE";
export const ACTIVATE_ENTITY_FAILURE = "@ADMIN/ACTIVATE_ENTITY_FAILURE";

export const SEARCH_ENTITIES_REQUEST = "@ADMIN/SEARCH_ENTITIES_REQUEST";
export const SEARCH_ENTITIES_SUCCESS = "@ADMIN/SEARCH_ENTITIES_SUCCESS";
export const SEARCH_ENTITIES_FAILURE = "@ADMIN/SEARCH_ENTITIES_FAILURE";

export const FILTER_ENTITIES_REQUEST = "@ADMIN/FILTER_ENTITIES_REQUEST";
export const FILTER_ENTITIES_SUCCESS = "@ADMIN/FILTER_ENTITIES_SUCCESS";
export const FILTER_ENTITIES_FAILURE = "@ADMIN/FILTER_ENTITIES_FAILURE";

export const UPLOAD_ENTITY_FILES_REQUEST = "@ADMIN/UPLOAD_ENTITY_FILES_REQUEST";
export const UPLOAD_ENTITY_FILES_SUCCESS = "@ADMIN/UPLOAD_ENTITY_FILES_SUCCESS";
export const UPLOAD_ENTITY_FILES_FAILURE = "@ADMIN/UPLOAD_ENTITY_FILES_FAILURE";
//------------------------------------------------------------------------//

export const SET_SNACKBAR = "@ADMIN/SET_SNACKBAR";
