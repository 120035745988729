import React, { useEffect } from 'react';
import '../cards.css';

const BarChart = () => {
    useEffect(() => {
        drawChart();
    }, []);

    const drawChart = () => {
        const data = [25, 15, 30, 20, 10, 25];
        const labels = ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        const chart = document.getElementById('chart');
        const maxDataValue = Math.max(...data);
        const chartHeight = 200;

        chart.innerHTML = '';

        data.forEach((value, index) => {
            const bar = document.createElement('div');
            bar.className = 'bar';
            bar.style.height = `${(value / maxDataValue) * chartHeight}px`;

            const label = document.createElement('div');
            label.className = 'label';
            label.innerText = labels[index];

            const barContainer = document.createElement('div');
            barContainer.className = 'bar-container';
            barContainer.appendChild(bar);
            barContainer.appendChild(label);

            chart.appendChild(barContainer);
        });
    };

    return (
        <div className="chart-card">
            <div className="chart-title">Performance</div>
            <div className="chart-subtitle">Total orders</div>
            <div id="chart" className="chart"></div>
        </div>
    );
}

export default BarChart;
