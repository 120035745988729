import { Avatar, Grid } from "@mui/material";
import React, { useState } from "react";
import styles from "./dashboard.module.css";
import RenderOnRole from "../../helpers/RenderOnRole";

const Homepage = () => {
  return (
    <div style={{ margin: "3rem 0" }}>
      <iframe
        title="Dasboard"
        className={styles.iframe}
        src={process.env.REACT_APP_MASTERDATA_LINK}
      ></iframe>
    </div>
  );
};

export default Homepage;
