import { createTheme, ThemeProvider } from "@mui/material";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import AppSnackbar from "./components/Notification/AppSnackbar";
import Footer from "./pages/Footer/Footer";
import Header from "./pages/header/header";
import LinearProgressBar from "./components/Generic/LinearProgressBar";
import Routes from "./routes/routes";
import store from "./store/index";
import theme from "./theme";
import { ToastContainer } from 'react-toastify';
import AxiosInterceptors from "./helpers/AxiosInterceptors";
import styled from "styled-components";
import { SnackbarProvider } from "notistack";
import Resetpin from "./components/Resetpin";

const Container = styled.div`
  min-height: 100vh;    
  display: flex;
  flex-direction: column;
`;

function Resetin() {
  const customTheme = createTheme(theme);

  return (
    <Provider store={store}>
      <Router>
        <ThemeProvider theme={customTheme}>
          <SnackbarProvider maxSnack={10}>
            <LinearProgressBar>
              <Container>
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
                <Resetpin />
                <Footer />
              </Container>
              <AppSnackbar />
              <AxiosInterceptors />

            </LinearProgressBar>
          </SnackbarProvider>
        </ThemeProvider>
      </Router>
    </Provider>
  );
}

export default Resetin;
