import React, { useEffect, useState } from "react";
import axios from "../../services/AxiosService";
import KeycloakService from "../../services/KeycloakService";

const useAutoCompleteOthers = ({
  optionsCode,
  allOptionsCode,
  autoCompleteRef,
  optionsBoxRef,
  disabledvalues = [],
}) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showOthers, setShowOthers] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        autoCompleteRef.current &&
        !autoCompleteRef.current.contains(event.target) &&
        optionsBoxRef.current &&
        !optionsBoxRef.current.contains(event.target)
      ) {
        setShowSuggestions(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [autoCompleteRef]);

  const onInputChange = (query) => {
    if (query.length === 0) {
      setShowSuggestions(false);
    }
    axios
      .get(process.env.REACT_APP_API_URL + `${optionsCode}/${query}`, {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      })
      .then((response) => {
        if (response.data) {
          const othersObj = { id: 0, name: "Outros" };
          setOptions([
            ...response.data.filter(
              (option) => !disabledvalues.includes(option.name.toLowerCase())
            ),
            othersObj,
          ]);
          if (response.data.length > 0) {
            setShowSuggestions(true);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onSuggestionClick = (item) => {
    if (item.name.toLowerCase() === "outros") {
      console.log("here");
      setShowOthers(true);
    }
    setShowSuggestions(false);
  };

  const onFocus = () => {
    setShowSuggestions(true);
  };

  return {
    showSuggestions,
    showOthers,
    options,
    onSuggestionClick,
    onInputChange,
    onFocus,
  };
};

export default useAutoCompleteOthers;
