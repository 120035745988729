import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import { format } from "date-fns";
import { Label, LabelContainer } from "./Label";
import { ErrorMessage } from "./ErrorMessage";
import { Button } from "@mui/material";

export default function AddOnInput({
  name,
  label,
  onAdd,
  isMandatory,
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      <LabelContainer>
        <Label htmlFor={fieldName}>{label}</Label>
        {isMandatory && <span style={{ color: "red" }}>*</span>}
      </LabelContainer>
      <div style={styles}>
        <input
          style={inputStyles}
          id={fieldName}
          ref={inputRef}
          defaultValue={defaultValue}
          {...rest}
        />
        <Button
          variant="contained"
          color="primary"
          style={buttonStyles}
          onClick={() => {
            onAdd(inputRef?.current?.value);
            inputRef.current.value = "";
          }}
        >
          Adicionar
        </Button>
      </div>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
}

const styles = {
  // width: "92.655%",
  display: "flex",
  justifyContent: "space-between",
};

const inputStyles = {
  width: "70.655%",
  float: "left",
};

const buttonStyles = {
  width: "20%",
  float: "right",
};
