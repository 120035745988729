import React, { useEffect, useState } from 'react';
import { Box, Avatar, useTheme, Grid, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getEntityRequest } from '../../../../store/reducers/admin/actionCreators';
import RenderOnRole from '../../../../helpers/RenderOnRole';
import PersonalDataCard from './PersonalDataCard';
import InfoPessoaisMetaForm from '../../../../pages/quadros/account/MetaForm/InfoPessoaisMetaForm';
import GenericDataCard from './GenericDataCard';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import banner from '../../../../assets/img/banner.jpg';
import {
  getEmployeeSection2Request,
  getEmployeeSection3Request,
  getEmployeeSection4Request,
  getEmployeeSection8Request,
  getEmployeeSectionRequest,
} from '../../../../store/reducers/users/actionCreators';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import CircleOutlined from '@mui/icons-material/CircleOutlined';
import CircleRounded from '@mui/icons-material/CircleRounded';

import { useHistory } from 'react-router-dom';
import styles from '../Quadro.module.css';
import { Styles } from './Styles';
import QRCode from 'react-qr-code';
import ProfilePicture from '../../../../components/Card/PersonalDataCard/ProfilePicture';
import { mapCodToGenders } from '../../../../utils/genders';
import { mapCodToMaritalStatus } from '../../../../helpers/MaritalStatus';
import { mapCodToEmployeeDocTypes } from '../../../../helpers/EmployeeDocType';
//import html2pdf from 'html2pdf.js';

const QuadroCV = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const employeeData = useSelector((state) => state.adminReducer.entityItem);
  const [literaryQualificationsObj, setLiteraryQualificationsObj] =
    useState(null);
  const [professionalExperiencesObj, setProfessionalExperiencesObj] =
    useState(null);
  const [idiomsObj, setIdiomsObj] = useState(null);
  const [trainingsObj, setTrainingsObj] = useState(null);
  const [administrationFramingObj, setAdministrationFramingObj] =
    useState(null);

  const literaryQualifications = useSelector(
    (state) => state.usersReducer.literaryQualifications
  );
  const professionalExperiences = useSelector(
    (state) => state.usersReducer.professionalExperiences
  );
  const trainings = useSelector((state) => state.usersReducer.trainings);
  const idioms = useSelector((state) => state.usersReducer.idiomas);
  const administrationFramings = useSelector(
    (state) => state.usersReducer.administrationFramings
  );

  useEffect(() => {
    if (match.params.employeeId) {
      dispatch(
        getEntityRequest({
          id: parseInt(match.params.employeeId),
          entity: 'employees',
        })
      );

      dispatch(
        getEmployeeSectionRequest({
          data: match.params.employeeId,
          sectionType: 'literary-qualifications',
          object: 'literaryQualifications',
          showNotification: false,
        })
      );

      dispatch(
        getEmployeeSection2Request({
          data: match.params.employeeId,
          sectionType: 'professional-experiences',
          object: 'professionalExperiences',
          showNotification: false,
        })
      );
      dispatch(
        getEmployeeSection3Request({
          data: match.params.employeeId,
          sectionType: 'idioms',
          object: 'idioms',
          showNotification: false,
        })
      );
      dispatch(
        getEmployeeSection4Request({
          data: match.params.employeeId,
          sectionType: 'trainings',
          object: 'trainings',
          showNotification: false,
        })
      );
      dispatch(
        getEmployeeSection8Request({
          data: match.params.employeeId,
          sectionType: 'administration-framing',
          object: 'administrationFramings',
          showNotification: false,
        })
      );
    }
    if (!match.params.employeeId) {
      return null;
    }
  }, [match?.params?.employeeId]);

  useEffect(() => {
    const getEmployeeCourse = (item) => {
      return item.course?.name
        ? item.course?.name
        : item.anotherCourse?.name
        ? item.anotherCourse?.name
        : '';
    };

    const getEducationalInstitution = (item) => {
      return item.educationalInstitution?.name
        ? item.educationalInstitution?.name
        : item.otherEducationalInstitution?.name
        ? item.otherEducationalInstitution?.name
        : '';
    };

    const generateAddress = (item) => {
      return `${item?.address || ''} ${
        item?.address && item.country?.name ? ', ' : ''
      } ${item.country?.name}`;
    };

    function getFormationNameByCod(cod) {
      const formationTypes = [
        { cod: 'POST_DOCTORAL', name: 'Pós Doutorado' },
        { cod: 'DOCTORAL', name: 'Doutorado' },
        { cod: 'POST_GRADUATION', name: 'Pós Graduação' },
        { cod: 'MASTER', name: 'Mestrado' },
        { cod: 'DEGREE', name: 'Licenciatura' },
        { cod: 'BACHELOR', name: 'Bacharelado' },
        { cod: 'SECONDARY_II', name: 'Ensino Médio - II Ciclo do Secundário' },
        { cod: 'SECONDARY_I', name: 'I Ciclo do Secundário' },
        { cod: 'ELEMENTARY', name: 'Ensino Primário' },
        { cod: 'ILLITERATE', name: 'Iletrado' },
      ];

      const formation = formationTypes.find((type) => type.cod === cod);

      return formation ? formation.name : '';
    }

    if (literaryQualifications?.length > 0) {
      setLiteraryQualificationsObj({
        title: 'Habilitações Literárias',
        items: literaryQualifications.map((literaryQualification) => {
          return {
            id: literaryQualification?.id,
            title: getFormationNameByCod(
              literaryQualification.levelOfEducation
            ),
            subTitle: getEmployeeCourse(literaryQualification),
            rows: [
              literaryQualification?.schoolingCycle?.name,
              `Média ${
                literaryQualification?.average
                  ? literaryQualification?.average
                  : 'N/A'
              } Val.`,
              `Concluído em ${
                literaryQualification?.completionYear
                  ? literaryQualification?.completionYear
                  : ''
              }`,
              literaryQualification?.course?.knowledgeArea?.name,
              getEducationalInstitution(literaryQualification),
              generateAddress(literaryQualification),
            ],
            list: literaryQualification?.mainCompetences?.map(
              (item) => item.name
            ),
          };
        }),
      });
    }
  }, [literaryQualifications]);

  useEffect(() => {
    const getProfession = (item) => {
      return item.profession?.name
        ? item.profession?.name
        : item.professionOther?.name
        ? item.professionOther?.name
        : '';
    };

    const getInstitution = (item) => {
      return item.institution?.name
        ? item.institution?.name
        : item.institutionOther?.name
        ? item.institutionOther?.name
        : '';
    };

    const generateDate = (date) => {
      if (!date) return 'n/d';
      const parsedDate = parseISO(date.split(' ')[0]); // Split to remove time, keep the date
      return format(parsedDate, 'dd MMM yyyy', { locale: ptBR });
    };

    if (professionalExperiences?.length > 0) {
      setProfessionalExperiencesObj({
        title: 'Experiência Profissional',
        items: professionalExperiences.map((professionalExperience) => {
          return {
            id: professionalExperience?.id,
            title: getProfession(professionalExperience),
            subTitle: getInstitution(professionalExperience),
            rows: [
              `${generateDate(
                professionalExperience.startDate
              )}. - ${generateDate(professionalExperience.endDate)}`,
              professionalExperience.functionDescription,
            ],
          };
        }),
      });
    }
  }, [professionalExperiences]);

  useEffect(() => {
    const generateDate = (date) => {
      if (!date) return 'n/d';
      const parsedDate = parseISO(date.split(' ')[0]); // Split to remove time, keep the date
      return format(parsedDate, 'dd MMM yyyy', { locale: ptBR });
    };

    if (administrationFramings?.length > 0) {
      setAdministrationFramingObj({
        title: 'Enquadramento',
        items: administrationFramings.map((administration) => {
          return {
            id: administration?.id,
            title: administration.positionName,
            subTitle: administration.unitInstitutionName,
            rows: [
              administration.categoryName,
              `Unidade: ${administration.unitName}`,
              `Entidade de origem: ${
                administration.institutionOfOriginName
                  ? administration.institutionOfOriginName
                  : 'Nenhuma'
              }`,
              `Carreira: ${administration.categoryCareerName}`,
              `${generateDate(administration.startedOn)}. - ${generateDate(
                administration.endedOn
              )}`,
            ],
          };
        }),
      });
    }
  }, [administrationFramings]);

  const getIdiomCircles = (text, item) => {
    switch (item) {
      case 'LOW':
        return (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <span style={{ width: '20%' }}>{text}</span>
            <CircleRounded
              style={{
                color: `${theme.palette.primary.main}`,
                fontSize: '1.5rem',
              }}
            />
            <CircleOutlined
              style={{
                color: `${theme.palette.primary.main}`,
                fontSize: '1.5rem',
              }}
            />
            <CircleOutlined
              style={{
                color: `${theme.palette.primary.main}`,
                fontSize: '1.5rem',
              }}
            />
            <CircleOutlined
              style={{
                color: `${theme.palette.primary.main}`,
                fontSize: '1.5rem',
              }}
            />
          </Box>
        );
      case 'MEDIUM':
        return (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <span style={{ width: '20%' }}>{text}</span>
            <CircleRounded
              style={{
                color: `${theme.palette.primary.main}`,
                fontSize: '1.5rem',
              }}
            />
            <CircleRounded
              style={{
                color: `${theme.palette.primary.main}`,
                fontSize: '1.5rem',
              }}
            />
            <CircleOutlined
              style={{
                color: `${theme.palette.primary.main}`,
                fontSize: '1.5rem',
              }}
            />
            <CircleOutlined
              style={{
                color: `${theme.palette.primary.main}`,
                fontSize: '1.5rem',
              }}
            />
          </Box>
        );
      case 'GOOD':
        return (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <span style={{ width: '20%' }}>{text}</span>
            <CircleRounded
              style={{
                color: `${theme.palette.primary.main}`,
                fontSize: '1.5rem',
              }}
            />
            <CircleRounded
              style={{
                color: `${theme.palette.primary.main}`,
                fontSize: '1.5rem',
              }}
            />
            <CircleRounded
              style={{
                color: `${theme.palette.primary.main}`,
                fontSize: '1.5rem',
              }}
            />
            <CircleOutlined
              style={{
                color: `${theme.palette.primary.main}`,
                fontSize: '1.5rem',
              }}
            />
          </Box>
        );
      case 'VERY_GOOD':
        return (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <span style={{ width: '20%' }}>{text}</span>
            <CircleRounded
              style={{
                color: `${theme.palette.primary.main}`,
                fontSize: '1.5rem',
              }}
            />
            <CircleRounded
              style={{
                color: `${theme.palette.primary.main}`,
                fontSize: '1.5rem',
              }}
            />
            <CircleRounded
              style={{
                color: `${theme.palette.primary.main}`,
                fontSize: '1.5rem',
              }}
            />
            <CircleRounded
              style={{
                color: `${theme.palette.primary.main}`,
                fontSize: '1.5rem',
              }}
            />
          </Box>
        );
      default:
        <Box sx={{ display: 'flex', gap: '1rem' }}>TESTE LOW</Box>;
    }
  };

  useEffect(() => {
    if (idioms?.length > 0) {
      setIdiomsObj({
        title: 'Idiomas',
        items: idioms.map((idiom) => {
          return {
            id: idiom?.id,
            title: idiom?.idiom?.name,
            rows: [
              getIdiomCircles('Leitura: ', idiom.readUnderstanding),
              getIdiomCircles('Oral: ', idiom.speechUnderstanding),
              getIdiomCircles('Escrita: ', idiom.writtenUnderstanding),
            ],
          };
        }),
      });
    }
  }, [idioms]);

  useEffect(() => {
    const getFormationType = (item) => {
      return item.formationType.name ? item.formationType.name : '';
    };

    const getEducationalInstitution = (item) => {
      return item.educationalInstitution?.name
        ? item.educationalInstitution?.name
        : item.otherEducationalInstitution?.name
        ? item.otherEducationalInstitution?.name
        : '';
    };

    const getWorkHours = (item) => {
      return item?.hourlyWorkload
        ? `${item?.hourlyWorkload} horas por dia`
        : '';
    };

    if (trainings?.length > 0) {
      setTrainingsObj({
        title: 'Formações e Capacitações',
        items: trainings.map((training) => {
          return {
            id: training?.id,
            title: getFormationType(training),
            subTitle: getEducationalInstitution(training),
            rows: [getWorkHours()],
          };
        }),
      });
    }
  }, [trainings]);

  const { personalDataCol1, personalDataCol2 } = InfoPessoaisMetaForm(
    employeeData?.typeEmployee,
    employeeData?.naturalness?.province?.id,
    null,
    [],
    employeeData?.foreignNationalityIdCard ? 'FOREIGN' : 'NATIONAL'
  );

  const formatDate = (date) => {
    if (!date) return '';
    const noHoursDate = date.split(' ')[0];

    const year = noHoursDate.split('-')[0];
    const month = noHoursDate.split('-')[1];
    const day = noHoursDate.split('-')[2];

    return `${day}-${month}-${year}`;
  };

  const runEval = (dataStr, meta) => {
    try {
      if (dataStr.includes('genre')) {
        return mapCodToGenders(eval(dataStr));
      }

      if (dataStr.includes('maritalStatus')) {
        return mapCodToMaritalStatus(eval(dataStr));
      }

      if (dataStr.includes('foreignNationalityIdCard')) {
        return mapCodToEmployeeDocTypes(eval(dataStr));
      }

      if (meta.type === 'date' && dataStr) {
        return formatDate(eval(dataStr));
      }

      return eval(dataStr);
    } catch (err) {}
    return null;
  };

  const getIcon = (label) => {
    switch (label) {
      case 'Morada':
        return (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            viewBox='0 0 24 24'
          >
            <path fill='#53bee1' d='M4 21V9l8-6l8 6v12h-6v-7h-4v7z' />
          </svg>
        );
      case 'Província de Naturalidade':
        return (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            viewBox='0 0 24 24'
          >
            <path
              fill='#53bee1'
              d='M12 12q.825 0 1.413-.587T14 10t-.587-1.412T12 8t-1.412.588T10 10t.588 1.413T12 12m0 10q-4.025-3.425-6.012-6.362T4 10.2q0-3.75 2.413-5.975T12 2t5.588 2.225T20 10.2q0 2.5-1.987 5.438T12 22'
            />
          </svg>
        );
      case 'Município de Naturalidade':
        return (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            viewBox='0 0 24 24'
          >
            <path
              fill='#53bee1'
              d='M12 12q.825 0 1.413-.587T14 10t-.587-1.412T12 8t-1.412.588T10 10t.588 1.413T12 12m0 10q-4.025-3.425-6.012-6.362T4 10.2q0-3.75 2.413-5.975T12 2t5.588 2.225T20 10.2q0 2.5-1.987 5.438T12 22'
            />
          </svg>
        );
      case 'Província de Residência':
        return (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            viewBox='0 0 24 24'
          >
            <path
              fill='#53bee1'
              d='M12 12q.825 0 1.413-.587T14 10t-.587-1.412T12 8t-1.412.588T10 10t.588 1.413T12 12m0 10q-4.025-3.425-6.012-6.362T4 10.2q0-3.75 2.413-5.975T12 2t5.588 2.225T20 10.2q0 2.5-1.987 5.438T12 22'
            />
          </svg>
        );
      case 'País de Residência':
        return (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            viewBox='0 0 24 24'
          >
            <path
              fill='#53bee1'
              d='M11 22.95v-2q-3.125-.35-5.363-2.587T3.05 13h-2v-2h2q.35-3.125 2.588-5.363T11 3.05v-2h2v2q3.125.35 5.363 2.588T20.95 11h2v2h-2q-.35 3.125-2.587 5.363T13 20.95v2zM12 19q2.9 0 4.95-2.05T19 12t-2.05-4.95T12 5T7.05 7.05T5 12t2.05 4.95T12 19m0-3q-1.65 0-2.825-1.175T8 12t1.175-2.825T12 8t2.825 1.175T16 12t-1.175 2.825T12 16'
            />
          </svg>
        );
      case 'Telefone':
        return (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            viewBox='0 0 24 24'
          >
            <path
              fill='#53bee1'
              d='M19.95 21q-3.125 0-6.175-1.362t-5.55-3.863t-3.862-5.55T3 4.05q0-.45.3-.75t.75-.3H8.1q.35 0 .625.238t.325.562l.65 3.5q.05.4-.025.675T9.4 8.45L6.975 10.9q.5.925 1.187 1.787t1.513 1.663q.775.775 1.625 1.438T13.1 17l2.35-2.35q.225-.225.588-.337t.712-.063l3.45.7q.35.1.575.363T21 15.9v4.05q0 .45-.3.75t-.75.3'
            />
          </svg>
        );
      case 'Email':
        return (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            viewBox='0 0 24 24'
          >
            <path
              fill='#53bee1'
              d='M4 20q-.825 0-1.412-.587T2 18V6q0-.825.588-1.412T4 4h16q.825 0 1.413.588T22 6v12q0 .825-.587 1.413T20 20zm8-7l8-5V6l-8 5l-8-5v2z'
            />
          </svg>
        );
      case 'Nº de Segurança Social':
        return (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            viewBox='0 0 24 24'
          >
            <path
              fill='#53bee1'
              d='M4 20q-.825 0-1.412-.587T2 18V6q0-.825.588-1.412T4 4h16q.825 0 1.413.588T22 6v12q0 .825-.587 1.413T20 20zm0-8h16V8H4z'
            />
          </svg>
        );
      case 'Estado Civil':
        return (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            viewBox='0 0 16 16'
          >
            <path
              fill='#53bee1'
              d='m7.536 8.657l2.828-2.83a1 1 0 0 1 1.414 1.416l-3.535 3.535a1 1 0 0 1-1.415.001l-2.12-2.12a1 1 0 1 1 1.413-1.415zM8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16m0-2A6 6 0 1 0 8 2a6 6 0 0 0 0 12'
            />
          </svg>
        );
      default:
        return null;
    }
  };

  const handlePrintCurriculum = async () => {
    const html2pdf = await require('html2pdf.js');
    const element = document.querySelector('#curriculum');

    html2pdf(element, {
      filename: `${employeeData?.id}.pdf`,
      image: { type: 'jpeg', quality: 1 },
    });
  };

  console.log('employeeData', employeeData);

  return (
    <RenderOnRole roles={['user-quadro', 'admin', 'user-entidade']}>
      <Box
        id='banner'
        sx={{
          display: 'flex',
          height: '245px',
          marginBottom: '20px',
          zIndex: 9,
        }}
      >
        <Avatar
          alt='banner'
          src={banner}
          variant='square'
          sx={{ width: '100%', height: '100%' }}
        />
        <Box
          onClick={() => history.goBack()}
          sx={{ marginLeft: '-95%', zIndex: '9', marginTop: '2rem' }}
        >
          <ArrowCircleLeftRoundedIcon className={styles.quadroCVBackButton} />
        </Box>
      </Box>
      <Styles
        style={{
          color: '#fff',
          boxShadow: '2px 2px 4px rgb(0 0 0 / 20%)',
          borderRadius: '4px',
          zIndex: 10,
          marginTop: '-9rem',
        }}
      >
        {employeeData && (
          <Box
            id='curriculum'
            sx={{
              zIndex: 99,
            }}
          >
            <Box>
              <Grid
                direction='row'
                container
                // className={`${styles.gridContainer} ${styles.card}`}
                justify-content='center'
                style={{
                  backgroundColor: '#fff',
                  height: '100%',
                  width: '100%',
                }}
              >
                <Grid
                  md={4.5}
                  sx={{ padding: '30px' }}
                  item
                  align='start'
                  style={{ backgroundColor: '#2B404E', color: '#fff' }}
                >
                  <Box sx={{ marginLeft: '48px' }}>
                    <ProfilePicture
                      entity={'employees'}
                      entityId={employeeData?.id}
                    />
                  </Box>

                  <Grid item>
                    <h2
                      style={{
                        borderBottom: '1px solid #fff',
                        paddingBottom: '4px',
                        fontSize: '20px',
                        fontWeight: 'normal',
                      }}
                    >
                      Sobre mim
                    </h2>
                    {personalDataCol1.map((meta, i) => {
                      return (
                        <Grid
                          container
                          direction='row'
                          alignItems='center'
                          mb='5px'
                          key={i}
                        >
                          <Grid
                            item
                            md={6}
                            sx={{
                              fontStyle: 'normal',
                              fontWeight: 'normal',
                              fontSize: '9px',
                              lineHeight: '20px',
                              color: '#f2f2f2',
                              alignItems: 'center',
                            }}
                          >
                            {meta.label}:
                          </Grid>
                          <Grid item md={6} color='#fff'>
                            {runEval('employeeData.' + [meta.field], meta) ||
                              'N/A'}
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Grid item style={{ marginTop: '42px' }}>
                    <h2
                      style={{
                        borderBottom: '1px solid #fff',
                        paddingBottom: '4px',
                        fontSize: '20px',
                        fontWeight: 'normal',
                      }}
                    >
                      Contactos
                    </h2>
                    {personalDataCol2.map((meta, i) => {
                      return (
                        <Grid
                          container
                          direction='row'
                          alignItems='center'
                          mb='5px'
                          key={i}
                        >
                          <Grid
                            item
                            md={6}
                            direction='row'
                            sx={{
                              fontStyle: 'normal',
                              fontWeight: 'normal',
                              fontSize: '9px',
                              lineHeight: '20px',
                              color: '#f2f2f2',
                              alignItems: 'center',
                            }}
                            rowSpacing={'4px'}
                            spacing={'4px'}
                            gap={'4px'}
                          >
                            {getIcon(meta.label)} {'  '} {meta.label}:
                          </Grid>
                          <Grid item md={6} color='#fff'>
                            {runEval('employeeData.' + [meta.field], meta)}
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>

                <Grid
                  md={6.5}
                  item
                  spacing={2}
                  style={{ backgroundColor: '#2B404E' }}
                >
                  <Box
                    sx={{
                      borderRadius: '0 0 56px 130px',
                      backgroundColor: '#fff',
                      padding: '20px 20px',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <img src='/logo.png' alt='logo' height={64} />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                    style={{
                      backgroundColor: '#2B404E',
                      color: '#fff',
                      width: '100%',
                      padding: '4px 24px 28px',
                      marginTop: '-4px',
                      borderRadius: '0px 48px 48px 0px',
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <h1
                        style={{
                          fontSize: '32px',
                          fontWeight: 'bold',
                        }}
                      >
                        {employeeData?.name}
                      </h1>
                      <Button
                        onClick={handlePrintCurriculum}
                        sx={{
                          cursor: 'pointer',
                          backgroundColor: 'transparent',
                          border: '1px solid #5EBEE1',
                          padding: '8px 16px',
                          borderRadius: '4px',
                          color: '#5EBEE1',
                        }}
                        data-html2canvas-ignore
                      >
                        Imprimir CV
                      </Button>
                    </Box>
                    <Box
                      style={{
                        backgroundColor: '#fff',
                        padding: '2px',
                        marginTop: '25px',
                        borderRadius: '4px',
                        width: '100px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <QRCode
                        style={{ height: '100px' }}
                        value={`${process.env.REACT_APP_API_URL}/documents/cv/${employeeData?.id}`}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      borderRadius: '48px 0 0 0',
                      backgroundColor: '#fff',
                      paddingBottom: '32px',
                    }}
                  >
                    {literaryQualifications?.length > 0 && (
                      <Box id='personal-data'>
                        <GenericDataCard
                          genericData={literaryQualificationsObj}
                        />
                      </Box>
                    )}
                    {professionalExperiences && (
                      <Box id='personal-data'>
                        <GenericDataCard
                          genericData={professionalExperiencesObj}
                        />
                      </Box>
                    )}
                    {trainings?.length > 0 && (
                      <Box id='personal-data'>
                        <GenericDataCard genericData={trainingsObj} />
                      </Box>
                    )}
                    {administrationFramings?.length > 0 && (
                      <Box id='personal-data'>
                        <GenericDataCard
                          genericData={administrationFramingObj}
                        />
                      </Box>
                    )}
                    {idioms?.length > 0 && (
                      <Box id='personal-data'>
                        <GenericDataCard genericData={idiomsObj} />
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
      </Styles>
      <Box></Box>
    </RenderOnRole>
  );
};

export default QuadroCV;
