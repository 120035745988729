export const maritalStatus = [
  { cod: "SINGLE", name: "Solteiro(a)" },
  { cod: "MARRIED", name: "Casado(a)" },
  { cod: "WIDOW", name: "Viúvo(a)" },
  { cod: "DIVORCED ", name: "Divorciado(a)" },
];

export const mapCodToMaritalStatus = (cod) => {
  switch (cod) {
    case "SINGLE":
      return "Solteiro(a)";
    case "MARRIED":
      return "Casado(a)";
    case "WIDOW":
      return "Viúvo(a)";
    case "DIVORCED":
      return "Divorciado(a)";
    default:
      return "";
  }
};
