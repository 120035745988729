import { useField } from "@unform/core";
import React, { useEffect, useRef } from "react";
import { ErrorMessage } from "../ErrorMessage";
import { Label } from "../Label";
import Checkbox from "./Checkbox";
import styled from "styled-components";


// TODO: END THIS -- RETURN IS NOT WORKING
export default function MultiSelect({ name, label, options, selectedValues }) {
  const groupRef = useRef(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  // useEffect(() => {
  //   registerField({
  //     name: fieldName,
  //     ref: groupRef.current,
  //   });
  // }, [fieldName, registerField]);

  // console.log('xxx', name);

  const nameResolver = (i) => {
    const split = name.split('.');
    const fieldName = split[0] + '[' + i + ']';
    // console.log({fieldName});
    return fieldName;
  }

  return (
    <div ref={groupRef}>
      <Label htmlFor={fieldName}>{label}</Label>
      <Options>
        {options.map((option, i) => (
          <div key={i}>
            <Checkbox
              name={nameResolver(i)}
              label={option.name}
              defaultValue={option.id}
              selectedValues={selectedValues}
            />
          </div>
        ))}
      </Options>
      {error && <ErrorMessage> {error} </ErrorMessage>}
    </div>
  );
};

const Options = styled.div`
  padding: 20px 50px;
  background: #f9f9f9;
  border-radius: 5px
`;
