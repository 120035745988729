import { Avatar } from "@mui/material";
import React from "react";
import logo from "../../assets/img/logo-gov-angola.png";
import { Container } from "./styles";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <Container>
      <Avatar
        src={logo}
        alt="Governo de angola"
        variant="square"
        sx={{
          width: "100px",
          height: "100%",
          marginBottom: "1rem",
          mixBlendMode: "luminosity",
          opacity: ".5",
        }}
      />
      <span>utg.gov.ao</span>
      <span>Copyright @ {year}</span>
    </Container>
  );
};

export default Footer;
