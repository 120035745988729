import * as actionTypes from "./actionTypes";

export const getProfilePictureRequest = (data) => {
  return {
    type: actionTypes.GET_PROFILEPICTURE_REQUEST,
    payload: data,
  };
};

export const getProfilePictureSuccess = (data) => {
  return {
    type: actionTypes.GET_PROFILEPICTURE_SUCCESS,
    payload: data,
  };
};

export const getProfilePictureError = (data) => {
  return {
    type: actionTypes.GET_PROFILEPICTURE_FAILURE,
    payload: data,
  };
};

export const getAllCountriesRequest = () => {
  return {
    type: actionTypes.GETALL_COUNTRIES_REQUEST,
  };
};

export const getAllCountriesSuccess = (data) => {
  return {
    type: actionTypes.GETALL_COUNTRIES_SUCCESS,
    payload: data,
  };
};

export const getAllCountriesError = (data) => {
  return {
    type: actionTypes.GETALL_COUNTRIES_FAILURE,
    payload: data,
  };
};

export const getEntityImageRequest = (data) => {
  return {
    type: actionTypes.GET_ENTITY_IMAGE_REQUEST,
    payload: data,
  };
};

export const getEntityImageSuccess = (data) => {
  return {
    type: actionTypes.GET_ENTITY_IMAGE_SUCCESS,
    payload: data,
  };
};

export const getEntityImageError = (data) => {
  return {
    type: actionTypes.GET_ENTITY_IMAGE_FAILURE,
    payload: data,
  };
};

export const addEntityImageRequest = (data) => {
  return {
    type: actionTypes.ADD_ENTITY_IMAGE_REQUEST,
    payload: data,
  };
};

export const addEntityImageSuccess = (data) => {
  return {
    type: actionTypes.ADD_ENTITY_IMAGE_SUCCESS,
    payload: data,
  };
};

export const addEntityImageError = (data) => {
  return {
    type: actionTypes.ADD_ENTITY_IMAGE_FAILURE,
    payload: data,
  };
};

export const editEntityImageRequest = (data) => {
  return {
    type: actionTypes.EDIT_ENTITY_IMAGE_REQUEST,
    payload: data,
  };
};

export const editEntityImageSuccess = (data) => {
  return {
    type: actionTypes.EDIT_ENTITY_IMAGE_SUCCESS,
    payload: data,
  };
};

export const editEntityImageError = (data) => {
  return {
    type: actionTypes.EDIT_ENTITY_IMAGE_FAILURE,
    payload: data,
  };
};

// NEW
export const getEntitiesRequest = (data) => {
  return {
    type: actionTypes.GET_ENTITIES_REQUEST,
    payload: data,
  };
};

export const getEntitiesSuccess = (data) => {
  return {
    type: actionTypes.GET_ENTITIES_SUCCESS,
    payload: data,
  };
};

export const getEntitiesError = (data) => {
  return {
    type: actionTypes.GET_ENTITIES_FAILURE,
    payload: data,
  };
};

export const getEmployeesByInsitutionRequest = (data) => {
  return {
    type: actionTypes.GET_EMPLOYEES_BY_INSTITUTION_REQUEST,
    payload: data,
  };
};

export const getEmployeesByInsitutionSuccess = (data) => {
  return {
    type: actionTypes.GET_EMPLOYEES_BY_INSTITUTION_SUCCESS,
    payload: data,
  };
};

export const getEmployeesByInsitutionError = (data) => {
  return {
    type: actionTypes.GET_EMPLOYEES_BY_INSTITUTION_FAILURE,
    payload: data,
  };
};

export const submitEntityActionRequest = (data) => {
  return {
    type: actionTypes.SUBMIT_ENTITY_ACTION_REQUEST,
    payload: data,
  };
};

export const submitEntityActionSuccess = (data) => {
  return {
    type: actionTypes.SUBMIT_ENTITY_ACTION_SUCCESS,
    payload: data,
  };
};

export const submitEntityActionError = (data) => {
  return {
    type: actionTypes.SUBMIT_ENTITY_ACTION_FAILURE,
    payload: data,
  };
};

export const getSecondaryEntitiesRequest = (data) => {
  return {
    type: actionTypes.GET_SECONDARY_ENTITIES_REQUEST,
    payload: data,
  };
};

export const getSecondaryEntitiesSuccess = (data) => {
  return {
    type: actionTypes.GET_SECONDARY_ENTITIES_SUCCESS,
    payload: data,
  };
};

export const getSecondaryEntitiesError = (data) => {
  return {
    type: actionTypes.GET_SECONDARY_ENTITIES_FAILURE,
    payload: data,
  };
};

export const getEntityRequest = (data) => {
  return {
    type: actionTypes.GET_ENTITY_REQUEST,
    payload: data,
  };
};

export const getEntitySuccess = (data) => {
  return {
    type: actionTypes.GET_ENTITY_SUCCESS,
    payload: data,
  };
};

export const getEntityError = (data) => {
  return {
    type: actionTypes.GET_ENTITY_FAILURE,
    payload: data,
  };
};

export const addEntityRequest = (data) => {
  return {
    type: actionTypes.ADD_ENTITY_REQUEST,
    payload: data,
  };
};

export const addEntitySuccess = (data) => {
  return {
    type: actionTypes.ADD_ENTITY_SUCCESS,
    payload: data,
  };
};

export const addEntityError = (data) => {
  return {
    type: actionTypes.ADD_ENTITY_FAILURE,
    payload: data,
  };
};

export const editEntityRequest = (data) => {
  return {
    type: actionTypes.EDIT_ENTITY_REQUEST,
    payload: data,
  };
};

export const editEntitySuccess = (data) => {
  return {
    type: actionTypes.EDIT_ENTITY_SUCCESS,
    payload: data,
  };
};

export const editEntityError = (data) => {
  return {
    type: actionTypes.EDIT_ENTITY_FAILURE,
    payload: data,
  };
};

export const deleteEntityRequest = (data) => {
  return {
    type: actionTypes.DELETE_ENTITY_REQUEST,
    payload: data,
  };
};

export const deactivateEntityRequest = (data) => {
  return {
    type: actionTypes.DEACTIVATE_ENTITY_REQUEST,
    payload: data,
  };
};

export const activateEntityRequest = (data) => {
  return {
    type: actionTypes.ACTIVATE_ENTITY_REQUEST,
    payload: data,
  };
};

export const deleteEntitySuccess = (data) => {
  return {
    type: actionTypes.DELETE_ENTITY_SUCCESS,
    payload: data,
  };
};

export const deactivateEntitySuccess = (data) => {
  return {
    type: actionTypes.DEACTIVATE_ENTITY_SUCCESS,
    payload: data,
  };
};

export const activateEntitySuccess = (data) => {
  return {
    type: actionTypes.ACTIVATE_ENTITY_SUCCESS,
    payload: data,
  };
};

export const deleteEntityError = (data) => {
  return {
    type: actionTypes.DELETE_ENTITY_FAILURE,
    payload: data,
  };
};
export const deactivateEntityError = (data) => {
  return {
    type: actionTypes.DEACTIVATE_ENTITY_FAILURE,
    payload: data,
  };
};

export const activateEntityError = (data) => {
  return {
    type: actionTypes.ACTIVATE_ENTITY_FAILURE,
    payload: data,
  };
};

export const searchEntitiesRequest = (data) => {
  return {
    type: actionTypes.SEARCH_ENTITIES_REQUEST,
    payload: data,
  };
};

export const searchEntitiesSuccess = (data) => {
  return {
    type: actionTypes.SEARCH_ENTITIES_SUCCESS,
    payload: data,
  };
};

export const searchEntitiesError = (data) => {
  return {
    type: actionTypes.SEARCH_ENTITIES_FAILURE,
    payload: data,
  };
};

export const filtersEntitiesRequest = (data) => {
  return {
    type: actionTypes.FILTER_ENTITIES_REQUEST,
    payload: data,
  };
};

export const filtersEntitiesSuccess = (data) => {
  return {
    type: actionTypes.FILTER_ENTITIES_SUCCESS,
    payload: data,
  };
};

export const filtersEntitiesError = (data) => {
  return {
    type: actionTypes.FILTER_ENTITIES_FAILURE,
    payload: data,
  };
};

export const uploadEntityFilesRequest = (data) => {
  return {
    type: actionTypes.UPLOAD_ENTITY_FILES_REQUEST,
    payload: data,
  };
};

export const uploadEntityFilesSuccess = (data) => {
  return {
    type: actionTypes.UPLOAD_ENTITY_FILES_SUCCESS,
    payload: data,
  };
};

export const uploadEntityFilesError = (data) => {
  return {
    type: actionTypes.UPLOAD_ENTITY_FILES_FAILURE,
    payload: data,
  };
};

export const setSnackbar = (data) => {
  return {
    type: actionTypes.SET_SNACKBAR,
    payload: data,
  };
};
