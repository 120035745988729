import React from "react";
import { useDispatch } from "react-redux";
import axios from "../services/AxiosService";
import {
  setIsLoading,
  setSnackbar,
} from "../store/reducers/users/actionCreators";
import KeycloakService from "../services/KeycloakService";
import { useSnackbar } from "notistack";

const AxiosInterceptors = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      dispatch(setIsLoading(false));

      if (error?.response?.config) {
        if (
          error.response.config.url.includes("files") ||
          error.response.config.url.includes("suggestion") ||
          error.response.config.url.includes("undefined") ||
          error.response.config.url.includes("null") ||
          error.response.config.url.includes("resource-mobility/by-employee") ||
          error.response.config.url.includes("entidades/account")
        )
          return error;

        if (
          error.response.config.url.includes("user-identification/employee")
        ) {
          if (error?.response?.status !== 404) {
            localStorage.setItem(
              "bi-notFound",
              JSON.stringify({ hasError: true })
            );
          }
          return enqueueSnackbar(error?.response?.data?.message, {
            variant: "warning",
            autoHideDuration: 10000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          });
        }
      }

      if (
        error?.response?.data &&
        error?.response?.data?.message?.includes("Institution with SSO id")
      )
        return error;

      if (error?.response?.status === 404) {
        enqueueSnackbar(error?.response?.data?.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        return error;
      }

      if (error?.response?.status === 422 || error?.response?.status === 400) {
        if (error?.response?.data?.details?.length > 0) {
          {
            var result = error?.response?.data?.details
              .map(function (msg) {
                return msg.message;
              })
              .join("\n\n");
            // let err = error?.response?.data?.details.message.join(",");
            // error?.response?.data?.details.forEach((msg) => {
            dispatch(
              setSnackbar({
                snackbarIsOpen: true,
                snackbarMessage: result,
                snackbarType: "error",
              })
            );
            // enqueueSnackbar(msg.message, {
            //   variant: "info",
            //   autoHideDuration: 8000,
            //   anchorOrigin: { vertical: "top", horizontal: "center" },
            // });
            // });
          }
        }

        enqueueSnackbar(error?.response?.data?.message, {
          variant: "warning",
          autoHideDuration: 16000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        return error;
      }

      if (error?.response?.status === 401) {
        return KeycloakService.doLogout();
      }

      let errorMessages = "";

      if (error?.response?.data?.details?.length > 0) {
        errorMessages = error?.response?.data?.details?.reduce(
          (msg, currentDetail, index) => {
            if (index === 0)
              return `
						${currentDetail.message}.\n`;
            return `${msg}\n
						${currentDetail.message}.\n`;
          }
        );
      }

      if (error?.response?.data?.message?.length > 0) {
        errorMessages += error.response.data.message;
      }

      if (errorMessages.length > 0) {
        enqueueSnackbar(errorMessages, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });

        // dispatch(
        //   setSnackbar({
        //     snackbarIsOpen: true,
        //     snackbarMessage: errorMessages,
        //     snackbarType: "error",
        //   })
        // );
      }
      return error;
    }
  );

  return <div></div>;
};

export default AxiosInterceptors;
