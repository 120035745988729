import * as actionTypes from "./actionTypes";

export const getEmployeeRequest = (data) => {
  return {
    type: actionTypes.GET_EMPLOYEE_REQUEST,
    payload: data,
  };
};
export const getEmployeeSectionRequest = (data) => {
  return {
    type: actionTypes.GET_EMPLOYEE_SECTION_REQUEST,
    payload: data,
  };
};
export const getEmployeeSection2Request = (data) => {
  return {
    type: actionTypes.GET_EMPLOYEE_SECTION2_REQUEST,
    payload: data,
  };
};
export const getEmployeeSection3Request = (data) => {
  return {
    type: actionTypes.GET_EMPLOYEE_SECTION3_REQUEST,
    payload: data,
  };
};
export const getEmployeeSection4Request = (data) => {
  return {
    type: actionTypes.GET_EMPLOYEE_SECTION4_REQUEST,
    payload: data,
  };
};
export const getEmployeeSection5Request = (data) => {
  return {
    type: actionTypes.GET_EMPLOYEE_SECTION5_REQUEST,
    payload: data,
  };
};
export const getEmployeeSection6Request = (data) => {
  return {
    type: actionTypes.GET_EMPLOYEE_SECTION6_REQUEST,
    payload: data,
  };
};

export const getEmployeeSection7Request = (data) => {
  return {
    type: actionTypes.GET_EMPLOYEE_SECTION7_REQUEST,
    payload: data,
  };
};
export const getEmployeeSection8Request = (data) => {
  return {
    type: actionTypes.GET_EMPLOYEE_SECTION8_REQUEST,
    payload: data,
  };
};
export const getAllTypesVolunteersRequest = (data) => {
  return {
    type: actionTypes.GETALL_TYPES_VOLUNTEERS_REQUEST,
    payload: data,
  };
};

export const getAllTypesVolunteersSuccess = (data) => {
  return {
    type: actionTypes.GETALL_TYPES_VOLUNTEERS_SUCCESS,
    payload: data,
  };
};

export const getAllTypesVolunteersError = (data) => {
  return {
    type: actionTypes.GETALL_TYPES_VOLUNTEERS_FAILURE,
    payload: data,
  };
};

export const getAllTypesAccomplishmentsRequest = (data) => {
  return {
    type: actionTypes.GETALL_TYPES_ACCOMPLISHMENTS_REQUEST,
    payload: data,
  };
};

export const getAllTypesAccomplishmentsSuccess = (data) => {
  return {
    type: actionTypes.GETALL_TYPES_ACCOMPLISHMENTS_SUCCESS,
    payload: data,
  };
};

export const getAllTypesAccomplishmentsError = (data) => {
  return {
    type: actionTypes.GETALL_TYPES_ACCOMPLISHMENTS_FAILURE,
    payload: data,
  };
};

export const getEmployeeSuccess = (data) => {
  return {
    type: actionTypes.GET_EMPLOYEE_SUCCESS,
    payload: data,
  };
};
export const getEmployeeSectionSuccess = (data) => {
  return {
    type: actionTypes.GET_EMPLOYEE_SECTION_SUCCESS,
    payload: data,
  };
};

export const getEmployeeSection2Success = (data) => {
  return {
    type: actionTypes.GET_EMPLOYEE_SECTION2_SUCCESS,
    payload: data,
  };
};
export const getEmployeeSection3Success = (data) => {
  return {
    type: actionTypes.GET_EMPLOYEE_SECTION3_SUCCESS,
    payload: data,
  };
};
export const getEmployeeSection4Success = (data) => {
  return {
    type: actionTypes.GET_EMPLOYEE_SECTION4_SUCCESS,
    payload: data,
  };
};
export const getEmployeeSection5Success = (data) => {
  return {
    type: actionTypes.GET_EMPLOYEE_SECTION5_SUCCESS,
    payload: data,
  };
};
export const getEmployeeSection6Success = (data) => {
  return {
    type: actionTypes.GET_EMPLOYEE_SECTION6_SUCCESS,
    payload: data,
  };
};

export const getEmployeeSection7Success = (data) => {
  return {
    type: actionTypes.GET_EMPLOYEE_SECTION7_SUCCESS,
    payload: data,
  };
};
export const getEmployeeSection8Success = (data) => {
  return {
    type: actionTypes.GET_EMPLOYEE_SECTION8_SUCCESS,
    payload: data,
  };
};

export const getEmployeeError = (data) => {
  return {
    type: actionTypes.GET_EMPLOYEE_FAILURE,
    payload: data,
  };
};

export const getEmployeeSectionError = (data) => {
  return {
    type: actionTypes.GET_EMPLOYEE_SECTION_FAILURE,
    payload: data,
  };
};

export const getInstitutionRequest = (data) => {
  return {
    type: actionTypes.GET_INSTITUTION_REQUEST,
    payload: data,
  };
};

export const getInstitutionSuccess = (data) => {
  return {
    type: actionTypes.GET_INSTITUTION_SUCCESS,
    payload: data,
  };
};

export const getInstitutionError = (data) => {
  return {
    type: actionTypes.GET_INSTITUTION_FAILURE,
    payload: data,
  };
};

export const getAllInstitutionsRequest = () => {
  return {
    type: actionTypes.GETALL_INSTITUTIONS_REQUEST,
  };
};

export const getAllInstitutionsSuccess = (data) => {
  return {
    type: actionTypes.GETALL_INSTITUTIONS_SUCCESS,
    payload: data,
  };
};

export const getAllInstitutionsError = (data) => {
  return {
    type: actionTypes.GETALL_INSTITUTIONS_FAILURE,
    payload: data,
  };
};

export const getAllInstitutionsPrivateRequest = () => {
  return {
    type: actionTypes.GETALL_INSTITUTIONS_PRIVATE_REQUEST,
  };
};

export const getAllInstitutionsPrivateSuccess = (data) => {
  return {
    type: actionTypes.GETALL_INSTITUTIONS_PRIVATE_SUCCESS,
    payload: data,
  };
};

export const getAllInstitutionsPrivateError = () => {
  return {
    type: actionTypes.GETALL_INSTITUTIONS_PRIVATE_FAILURE,
  };
};

export const postEmployeeRequest = (data) => {
  return {
    type: actionTypes.POST_EMPLOYEE_REQUEST,
    payload: data,
  };
};

export const postEmployeeSuccess = (data) => {
  return {
    type: actionTypes.POST_EMPLOYEE_SUCCESS,
    payload: data,
  };
};

export const postEmployeeError = (data) => {
  return {
    type: actionTypes.POST_EMPLOYEE_FAILURE,
    payload: data,
  };
};

export const editEmployeeRequest = (data) => {
  return {
    type: actionTypes.EDIT_EMPLOYEE_REQUEST,
    payload: data,
  };
};

export const editEmployeeSectionRequest = (data) => {
  return {
    type: actionTypes.EDIT_EMPLOYEE_SECTION_REQUEST,
    payload: data,
  };
};

export const editEmployeeSuccess = (data) => {
  return {
    type: actionTypes.EDIT_EMPLOYEE_SUCCESS,
    payload: data,
  };
};

export const editEmployeeSectionSuccess = (data) => {
  return {
    type: actionTypes.EDIT_EMPLOYEE_SECTION_SUCESS,
    payload: data,
  };
};

export const editEmployeeError = (data) => {
  return {
    type: actionTypes.EDIT_EMPLOYEE_FAILURE,
    payload: data,
  };
};

export const editEmployeeSectionError = (data) => {
  return {
    type: actionTypes.EDIT_EMPLOYEE_SECTION_FAILURE,
    payload: data,
  };
};

export const editInstitutionRequest = (data) => {
  return {
    type: actionTypes.EDIT_INSTITUTION_REQUEST,
    payload: data,
  };
};

export const editInstitutionSuccess = (data) => {
  return {
    type: actionTypes.EDIT_INSTITUTION_SUCCESS,
    payload: data,
  };
};

export const editInstitutionError = (data) => {
  return {
    type: actionTypes.EDIT_INSTITUTION_FAILURE,
    payload: data,
  };
};

export const getProvincesRequest = () => {
  return {
    type: actionTypes.GET_PROVINCES_REQUEST,
  };
};

export const getProvincesSuccess = (data) => {
  return {
    type: actionTypes.GET_PROVINCES_SUCCESS,
    payload: data,
  };
};

export const getProvincesError = (data) => {
  return {
    type: actionTypes.GET_PROVINCES_FAILURE,
    payload: data,
  };
};

export const getProvincesMunicipalitiesRequest = (data) => {
  return {
    type: actionTypes.GET_PROVINCES_MUNICIPALITIES_REQUEST,
    payload: data,
  };
};
export const getMunicipalitiesCommunesRequest = (data) => {
  return {
    type: actionTypes.GET_MUNICIPALITIES_COMMUNES_REQUEST,
    payload: data,
  };
};

export const getProvincesMunicipalitiesSuccess = (data) => {
  return {
    type: actionTypes.GET_PROVINCES_MUNICIPALITIES_SUCCESS,
    payload: data,
  };
};

export const getProvincesMunicipalitiesError = (data) => {
  return {
    type: actionTypes.GET_PROVINCES_MUNICIPALITIES_FAILURE,
    payload: data,
  };
};

export const getMunicipalitiesCommunesSuccess = (data) => {
  return {
    type: actionTypes.GET_MUNICIPALITIES_COMMUNES_SUCCESS,
    payload: data,
  };
};
export const getMunicipalitiesCommunesError = (data) => {
  return {
    type: actionTypes.GET_MUNICIPALITIES_COMMUNES_FAILURE,
    payload: data,
  };
};

export const setMunicipalities = () => {
  return {
    type: actionTypes.SET_MUNICIPALITIES,
  };
};

export const getMunicipalitiesRequest = () => {
  return {
    type: actionTypes.GET_MUNICIPALITIES_REQUEST,
  };
};

export const getMunicipalitiesSuccess = (data) => {
  return {
    type: actionTypes.GET_MUNICIPALITIES_SUCCESS,
    payload: data,
  };
};

export const getMunicipalitiesError = (data) => {
  return {
    type: actionTypes.GET_MUNICIPALITIES_FAILURE,
    payload: data,
  };
};

// export const setDisabilityTypes = () => {
//   return {
//     type: actionTypes.GET_DISABILITYTYPES,
//   };
// };

export const getDisabilityTypesRequest = () => {
  return {
    type: actionTypes.GET_DISABILITYTYPES_REQUEST,
  };
};

export const getDisabilityTypesSuccess = (data) => {
  return {
    type: actionTypes.GET_DISABILITYTYPES_SUCCESS,
    payload: data,
  };
};

export const getDisabilityTypesError = (data) => {
  return {
    type: actionTypes.GET_DISABILITYTYPES_FAILURE,
    payload: data,
  };
};

export const getNationalityRequest = () => {
  return {
    type: actionTypes.GET_NATIONALITY_REQUEST,
  };
};

export const getNationalitySuccess = (data) => {
  return {
    type: actionTypes.GET_NATIONALITY_SUCCESS,
    payload: data,
  };
};

export const getNationalityError = (data) => {
  return {
    type: actionTypes.GET_NATIONALITY_FAILURE,
    payload: data,
  };
};

export const getCountriesRequest = () => {
  return {
    type: actionTypes.GET_COUNTRIES_REQUEST,
  };
};

export const getCountriesSuccess = (data) => {
  return {
    type: actionTypes.GET_COUNTRIES_SUCCESS,
    payload: data,
  };
};

export const getCountriesError = (data) => {
  return {
    type: actionTypes.GET_COUNTRIES_FAILURE,
    payload: data,
  };
};
export const getIdiomsRequest = () => {
  return {
    type: actionTypes.GET_IDIOMS_REQUEST,
  };
};

export const getIdiomsSuccess = (data) => {
  return {
    type: actionTypes.GET_IDIOMS_SUCCESS,
    payload: data,
  };
};
export const getIdiomsError = (data) => {
  return {
    type: actionTypes.GET_IDIOMS_FAILURE,
    payload: data,
  };
};
export const getIdiomsByTypeRequest = (data) => {
  return {
    type: actionTypes.GET_IDIOMS_BY_TYPE_REQUEST,
    payload: data,
  };
};

export const getIdiomsByTypeSuccess = (data) => {
  return {
    type: actionTypes.GET_IDIOMS_BY_TYPE_SUCCESS,
    payload: data,
  };
};
export const getIdiomsByTypeError = (data) => {
  return {
    type: actionTypes.GET_IDIOMS_BY_TYPE_FAILURE,
    payload: data,
  };
};

export const getCourseByKnowledgeAreaRequest = (data) => {
  return {
    type: actionTypes.GET_COURSES_BY_KNOWLEDGE_AREA_REQUEST,
    payload: data,
  };
};
export const getCourseByKnowledgeAreaSuccess = (data) => {
  return {
    type: actionTypes.GET_COURSES_BY_KNOWLEDGE_AREA_SUCCESS,
    payload: data,
  };
};
export const getCourseByKnowledgeAreaError = (data) => {
  return {
    type: actionTypes.GET_COURSES_BY_KNOWLEDGE_AREA_FAILURE,
    payload: data,
  };
};

export const getCoursesRequest = () => {
  return {
    type: actionTypes.GET_COURSES_REQUEST,
  };
};

export const getCoursesSuccess = (data) => {
  return {
    type: actionTypes.GET_COURSES_SUCCESS,
    payload: data,
  };
};

export const getCoursesError = (data) => {
  return {
    type: actionTypes.GET_COURSES_FAILURE,
    payload: data,
  };
};

export const getCourseRequest = (data) => {
  return {
    type: actionTypes.GET_COURSE_REQUEST,
    payload: data,
  };
};

export const getCourseSuccess = (data) => {
  return {
    type: actionTypes.GET_COURSE_SUCCESS,
    payload: data,
  };
};

export const getCourseError = (data) => {
  return {
    type: actionTypes.GET_COURSE_FAILURE,
    payload: data,
  };
};

export const getKnowledgeAreaRequest = () => {
  return {
    type: actionTypes.GET_KNOWLEDGE_AREA_REQUEST,
  };
};

export const getKnowledgeAreaSuccess = (data) => {
  return {
    type: actionTypes.GET_KNOWLEDGE_AREA_SUCCESS,
    payload: data,
  };
};

export const getKnowledgeAreaError = (data) => {
  return {
    type: actionTypes.GET_KNOWLEDGE_AREA_FAILURE,
    payload: data,
  };
};

export const getTypeFormationRequest = () => {
  return {
    type: actionTypes.GET_TYPE_FORMATION_REQUEST,
  };
};

export const getTypeFormationSuccess = (data) => {
  return {
    type: actionTypes.GET_TYPE_FORMATION_SUCCESS,
    payload: data,
  };
};

export const getTypeFormationError = (data) => {
  return {
    type: actionTypes.GET_TYPE_FORMATION_FAILURE,
    payload: data,
  };
};

export const getSchoolingCycleRequest = () => {
  return {
    type: actionTypes.GET_SCHOOLINGCYCLE_REQUEST,
  };
};

export const getSchoolingCycleSuccess = (data) => {
  return {
    type: actionTypes.GET_SCHOOLINGCYCLE_SUCCESS,
    payload: data,
  };
};

export const getSchoolingCycleError = (data) => {
  return {
    type: actionTypes.GET_SCHOOLINGCYCLE_FAILURE,
    payload: data,
  };
};

// export const getProfilePictureRequest = () => {
//   return {
//     type: actionTypes.GET_PROFILEPICTURE_REQUEST,
//   };
// };

export const getProfilePictureSuccess = (data) => {
  return {
    type: actionTypes.GET_PROFILEPICTURE_SUCCESS,
    payload: data,
  };
};

export const getProfilePictureError = (data) => {
  return {
    type: actionTypes.GET_PROFILEPICTURE_FAILURE,
    payload: data,
  };
};

export const getMaritalStatusRequest = () => {
  return {
    type: actionTypes.GET_MARITALSTATUS_REQUEST,
  };
};

export const getMaritalStatusSuccess = (data) => {
  return {
    type: actionTypes.GET_MARITALSTATUS_SUCCESS,
    payload: data,
  };
};

export const getMaritalStatusError = (data) => {
  return {
    type: actionTypes.GET_MARITALSTATUS_FAILURE,
    payload: data,
  };
};

export const getProfessionsRequest = () => {
  return {
    type: actionTypes.GET_PROFESSION_REQUEST,
  };
};

export const getProfessionsSuccess = (data) => {
  return {
    type: actionTypes.GET_PROFESSION_SUCCESS,
    payload: data,
  };
};

export const getProfessionsError = (data) => {
  return {
    type: actionTypes.GET_PROFESSION_FAILURE,
    payload: data,
  };
};

export const getProfessionalOrdersRequest = () => {
  return {
    type: actionTypes.GET_PROFESSIONAL_ORDERS_REQUEST,
  };
};

export const getProfessionalOrdersSuccess = (data) => {
  return {
    type: actionTypes.GET_PROFESSIONAL_ORDERS_SUCCESS,
    payload: data,
  };
};

export const getProfessionalOrdersError = (data) => {
  return {
    type: actionTypes.GET_PROFESSIONAL_ORDERS_FAILURE,
    payload: data,
  };
};

export const getJobOffersRequest = (data) => {
  return {
    type: actionTypes.GET_JOB_OFFERS_REQUEST,
    payload: data,
  };
};

export const getJobOffersSuccess = (data) => {
  return {
    type: actionTypes.GET_JOB_OFFERS_SUCCESS,
    payload: data,
  };
};

export const getJobOffersError = (data) => {
  return {
    type: actionTypes.GET_JOB_OFFERS_FAILURE,
    payload: data,
  };
};

export const getEducationalInstitutionsRequest = () => {
  return {
    type: actionTypes.GET_EDUCATIONALINSTITUTIONS_REQUEST,
  };
};

export const getEducationalInstitutionsSuccess = (data) => {
  return {
    type: actionTypes.GET_EDUCATIONALINSTITUTIONS_SUCCESS,
    payload: data,
  };
};

export const getEducationalInstitutionsError = (data) => {
  return {
    type: actionTypes.GET_EDUCATIONALINSTITUTIONS_FAILURE,
    payload: data,
  };
};

export const getActivitySectorsRequest = () => {
  return {
    type: actionTypes.GET_ACTIVITYSECTOR_REQUEST,
  };
};

export const getActivitySectorsSuccess = (data) => {
  return {
    type: actionTypes.GET_ACTIVITYSECTOR_SUCCESS,
    payload: data,
  };
};

export const getActivitySectorsError = (data) => {
  return {
    type: actionTypes.GET_ACTIVITYSECTOR_FAILURE,
    payload: data,
  };
};

export const getInstitutionTypesRequest = () => {
  return {
    type: actionTypes.GET_INSTITUTIONTYPES_REQUEST,
  };
};

export const getInstitutionTypesSuccess = (data) => {
  return {
    type: actionTypes.GET_INSTITUTIONTYPES_SUCCESS,
    payload: data,
  };
};

export const getInstitutionTypesError = (data) => {
  return {
    type: actionTypes.GET_INSTITUTIONTYPES_FAILURE,
    payload: data,
  };
};

export const getInstitutionTypeRequest = () => {
  return {
    type: actionTypes.GET_INSTITUTIONTYPE_REQUEST,
  };
};

export const getInstitutionTypeSuccess = (data) => {
  return {
    type: actionTypes.GET_INSTITUTIONTYPE_SUCCESS,
    payload: data,
  };
};

export const getInstitutionTypeError = (data) => {
  return {
    type: actionTypes.GET_INSTITUTIONTYPE_FAILURE,
    payload: data,
  };
};

export const getInstitutionEmployeesRequest = (data) => {
  return {
    type: actionTypes.GET_INSTITUTIONEMPLOYEES_REQUEST,
    payload: data,
  };
};

export const getInstitutionEmployeesSuccess = (data) => {
  return {
    type: actionTypes.GET_INSTITUTIONEMPLOYEES_SUCCESS,
    payload: data,
  };
};

export const getInstitutionEmployeesError = (data) => {
  return {
    type: actionTypes.GET_INSTITUTIONEMPLOYEES_FAILURE,
    payload: data,
  };
};

export const getTotalInstitutionEmployeesRequest = (data) => {
  return {
    type: actionTypes.GET_TOTAL_INSTITUTIONEMPLOYEES_REQUEST,
    payload: data,
  };
};

export const getTotalInstitutionEmployeesSuccess = (data) => {
  return {
    type: actionTypes.GET_TOTAL_INSTITUTIONEMPLOYEES_SUCCESS,
    payload: data,
  };
};

export const getTotalInstitutionEmployeesError = (data) => {
  return {
    type: actionTypes.GET_TOTAL_INSTITUTIONEMPLOYEES_FAILURE,
    payload: data,
  };
};

export const searchInstitutionEmployeesRequest = (data) => {
  return {
    type: actionTypes.SEARCH_INSTITUTIONEMPLOYEES_REQUEST,
    payload: data,
  };
};

export const searchInstitutionEmployeesSuccess = (data) => {
  return {
    type: actionTypes.SEARCH_INSTITUTIONEMPLOYEES_SUCCESS,
    payload: data,
  };
};

export const searchInstitutionEmployeesError = (data) => {
  return {
    type: actionTypes.SEARCH_INSTITUTIONEMPLOYEES_FAILURE,
    payload: data,
  };
};

export const filterInstitutionEmployeesRequest = (data) => {
  return {
    type: actionTypes.FILTER_INSTITUTIONEMPLOYEES_REQUEST,
    payload: data,
  };
};

export const filterInstitutionEmployeesSuccess = (data) => {
  return {
    type: actionTypes.FILTER_INSTITUTIONEMPLOYEES_SUCCESS,
    payload: data,
  };
};

export const filterInstitutionEmployeesError = (data) => {
  return {
    type: actionTypes.FILTER_INSTITUTIONEMPLOYEES_FAILURE,
    payload: data,
  };
};

export const setSnackbar = (data) => {
  return {
    type: actionTypes.SET_SNACKBAR,
    payload: data,
  };
};

export const getPoliticalPositionsRequest = () => {
  return {
    type: actionTypes.GET_POLITICAL_POSITIONS_REQUEST,
  };
};

export const getPoliticalPositionsSuccess = (data) => {
  return {
    type: actionTypes.GET_POLITICAL_POSITIONS_SUCCESS,
    payload: data,
  };
};

export const getPoliticalPositionsError = (data) => {
  return {
    type: actionTypes.GET_POLITICAL_POSITIONS_FAILURE,
    payload: data,
  };
};

export const getPositionsRequest = (data) => {
  return {
    type: actionTypes.GET_POSITIONS_REQUEST,
    payload: data,
  };
};

export const getPositionsSuccess = (data) => {
  return {
    type: actionTypes.GET_POSITIONS_SUCCESS,
    payload: data,
  };
};

export const getPositionsError = (data) => {
  return {
    type: actionTypes.GET_POSITIONS_FAILURE,
    payload: data,
  };
};

export const getCategoriesRequest = (data) => {
  return {
    type: actionTypes.GET_CATEGORIES_REQUEST,
    payload: data,
  };
};

export const getCategoriesSuccess = (data) => {
  return {
    type: actionTypes.GET_CATEGORIES_SUCCESS,
    payload: data,
  };
};

export const getCategoriesError = (data) => {
  return {
    type: actionTypes.GET_CATEGORIES_FAILURE,
    payload: data,
  };
};

export const getSpecialRegimeTypesRequest = (data) => {
  return {
    type: actionTypes.GET_SPECIALREGIMETYPES_REQUEST,
    payload: data,
  };
};

export const getSpecialRegimeTypesSuccess = (data) => {
  return {
    type: actionTypes.GET_SPECIALREGIMETYPES_SUCCESS,
    payload: data,
  };
};

export const getSpecialRegimeTypesError = (data) => {
  return {
    type: actionTypes.GET_SPECIALREGIMETYPES_FAILURE,
    payload: data,
  };
};

export const getCareersRequest = (data) => {
  return {
    type: actionTypes.GET_CAREERS_REQUEST,
    payload: data,
  };
};

export const getCareersSuccess = (data) => {
  return {
    type: actionTypes.GET_CAREERS_SUCCESS,
    payload: data,
  };
};

export const getCareersError = (data) => {
  return {
    type: actionTypes.GET_CAREERS_FAILURE,
    payload: data,
  };
};

export const getEmployeesToAssignRequest = (data) => {
  return {
    type: actionTypes.GET_EMPLOYEESTOASSIGN_REQUEST,
    payload: data,
  };
};

export const getEmployeesToAssignSuccess = (data) => {
  return {
    type: actionTypes.GET_EMPLOYEESTOASSIGN_SUCCESS,
    payload: data,
  };
};

export const getEmployeesToAssignError = (data) => {
  return {
    type: actionTypes.GET_EMPLOYEESTOASSIGN_FAILURE,
    payload: data,
  };
};

export const getUnitRequest = (data) => {
  return {
    type: actionTypes.GET_UNIT_REQUEST,
    payload: data,
  };
};

export const getUnitSuccess = (data) => {
  return {
    type: actionTypes.GET_UNIT_SUCCESS,
    payload: data,
  };
};

export const getUnitError = (data) => {
  return {
    type: actionTypes.GET_UNIT_FAILURE,
    payload: data,
  };
};

export const postAssignEmployeeRequest = (data) => {
  return {
    type: actionTypes.POST_ASSIGNEMPLOYEE_REQUEST,
    payload: data,
  };
};

export const postAssignEmployeeSuccess = (data) => {
  return {
    type: actionTypes.POST_ASSIGNEMPLOYEE_SUCCESS,
    payload: data,
  };
};

export const postAssignEmployeeError = (data) => {
  return {
    type: actionTypes.POST_ASSIGNEMPLOYEE_FAILURE,
    payload: data,
  };
};

export const updateAssignedEmployeeRequest = (data) => {
  return {
    type: actionTypes.UPDATE_ASSIGNEDEMPLOYEE_REQUEST,
    payload: data,
  };
};

export const updateAssignedEmployeeSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_ASSIGNEDEMPLOYEE_SUCCESS,
    payload: data,
  };
};

export const updateAssignedEmployeeError = (data) => {
  return {
    type: actionTypes.UPDATE_ASSIGNEDEMPLOYEE_FAILURE,
    payload: data,
  };
};

export const deleteAssignedEmployeeRequest = (data) => {
  return {
    type: actionTypes.DELETE_ASSIGNEDEMPLOYEE_REQUEST,
    payload: data,
  };
};

export const deleteAssignedEmployeeSuccess = (data) => {
  return {
    type: actionTypes.DELETE_ASSIGNEDEMPLOYEE_SUCCESS,
    payload: data,
  };
};

export const deleteAssignedEmployeeError = (data) => {
  return {
    type: actionTypes.DELETE_ASSIGNEDEMPLOYEE_FAILURE,
    payload: data,
  };
};

export const putCeaseFunctionsRequest = (data) => {
  return {
    type: actionTypes.PUT_CEASEFUNCTIONS_REQUEST,
    payload: data,
  };
};

export const putCeaseFunctionsSuccess = (data) => {
  return {
    type: actionTypes.PUT_CEASEFUNCTIONS_SUCCESS,
    payload: data,
  };
};

export const putCeaseFunctionsError = (data) => {
  return {
    type: actionTypes.PUT_CEASEFUNCTIONS_FAILURE,
    payload: data,
  };
};

export const getContractualRegimensTypesRequest = (data) => {
  return {
    type: actionTypes.GET_CONTRACTUALREGIMENSTYPES_REQUEST,
    payload: data,
  };
};

export const getContractualRegimensTypesSuccess = (data) => {
  return {
    type: actionTypes.GET_CONTRACTUALREGIMENSTYPES_SUCCESS,
    payload: data,
  };
};

export const getContractualRegimensTypesError = (data) => {
  return {
    type: actionTypes.GET_CONTRACTUALREGIMENSTYPES_FAILURE,
    payload: data,
  };
};

export const getJuridicalBondsRequest = (data) => {
  return {
    type: actionTypes.GET_JURIDICALBONDS_REQUEST,
    payload: data,
  };
};

export const getJuridicalBondsSuccess = (data) => {
  return {
    type: actionTypes.GET_JURIDICALBONDS_SUCCESS,
    payload: data,
  };
};

export const getJuridicalBondsError = (data) => {
  return {
    type: actionTypes.GET_JURIDICALBONDS_FAILURE,
    payload: data,
  };
};

export const getJuridicalBondChangeRequest = (data) => {
  return {
    type: actionTypes.GET_JURIDICALBONDCHANGE_REQUEST,
    payload: data,
  };
};

export const getJuridicalBondChangeSuccess = (data) => {
  return {
    type: actionTypes.GET_JURIDICALBONDCHANGE_SUCCESS,
    payload: data,
  };
};

export const getJuridicalBondChangeError = (data) => {
  return {
    type: actionTypes.GET_JURIDICALBONDCHANGE_FAILURE,
    payload: data,
  };
};

export const getUnitEmployeesRequest = (data) => {
  return {
    type: actionTypes.GET_UNITEMPLOYEES_REQUEST,
    payload: data,
  };
};

export const getUnitEmployeesSuccess = (data) => {
  return {
    type: actionTypes.GET_UNITEMPLOYEES_SUCCESS,
    payload: data,
  };
};

export const getUnitEmployeesError = (data) => {
  return {
    type: actionTypes.GET_UNITEMPLOYEES_FAILURE,
    payload: data,
  };
};

export const getFamilyKinshipRequest = (data) => {
  return {
    type: actionTypes.GET_FAMILYKINSHIP_REQUEST,
    payload: data,
  };
};

export const getFamilyKinshipSuccess = (data) => {
  return {
    type: actionTypes.GET_FAMILYKINSHIP_SUCCESS,
    payload: data,
  };
};

export const getFamilyKinshipError = (data) => {
  return {
    type: actionTypes.GET_FAMILYKINSHIP_FAILURE,
    payload: data,
  };
};

export const getUserByBiRequest = (data) => {
  return {
    type: actionTypes.GET_USERBYBI_REQUEST,
    payload: data,
  };
};

export const getUserByBiSuccess = (data) => {
  return {
    type: actionTypes.GET_USERBYBI_SUCCESS,
    payload: data,
  };
};

export const getUserByBiError = (data) => {
  return {
    type: actionTypes.GET_USERBYBI_FAILURE,
    payload: data,
  };
};

export const setIsLoading = (data) => {
  return {
    type: actionTypes.SET_ISLOADING,
    payload: data,
  };
};

export const uploadLiteraryQualificationDocumentRequest = (data) => {
  return {
    type: actionTypes.UPLOAD_LITERARY_QUALIFICATION_DOCUMENT_REQUEST,
    payload: data,
  };
};
export const uploadLiteraryQualificationDocumentSuccess = (data) => {
  return {
    type: actionTypes.UPLOAD_LITERARY_QUALIFICATION_DOCUMENT_SUCCESS,
    payload: data,
  };
};
export const uploadLiteraryQualificationDocumentError = (data) => {
  return {
    type: actionTypes.UPLOAD_LITERARY_QUALIFICATION_DOCUMENT_FAILURE,
    payload: data,
  };
};

export const uploadTrainingDocumentRequest = (data) => {
  return {
    type: actionTypes.UPLOAD_TRAINING_DOCUMENT_REQUEST,
    payload: data,
  };
};
export const uploadTrainingDocumentSuccess = (data) => {
  return {
    type: actionTypes.UPLOAD_TRAINING_DOCUMENT_SUCCESS,
    payload: data,
  };
};
export const uploadTrainingDocumentError = (data) => {
  return {
    type: actionTypes.UPLOAD_TRAINING_DOCUMENT_FAILURE,
    payload: data,
  };
};

export const deleteDocumentRequest = (data) => {
  return {
    type: actionTypes.DELETE_DOCUMENT_REQUEST,
    payload: data,
  };
};
export const deleteDocumentSuccess = (data) => {
  return {
    type: actionTypes.DELETE_DOCUMENT_SUCCESS,
    payload: data,
  };
};
export const deleteDocumentError = (data) => {
  return {
    type: actionTypes.DELETE_DOCUMENT_FAILURE,
    payload: data,
  };
};

export const getDocumentSuccess = (data) => {
  return {
    type: actionTypes.GET_DOCUMENT_SUCCESS,
    payload: data,
  };
};
export const getDocumentRequest = (data) => {
  return {
    type: actionTypes.GET_DOCUMENT_REQUEST,
    payload: data,
  };
};
export const getDocumentError = (data) => {
  return {
    type: actionTypes.GET_DOCUMENT_FAILURE,
    payload: data,
  };
};
