import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { Search } from "@mui/icons-material";
import styles from "./alocacaoStyles.module.css";

const SearchBar = ({ query, setQuery }) => {
  return (
    <Box
      sx={{
        marginLeft: "1rem",
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
      }}
    >
      <Typography
        sx={{
          color: "#3B4144",
          fontWeight: "bold",
        }}
      >
        Deve pesquisar o quadro a ser alocado
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          border: "1px solid #dfdfdf !important",
          borderRadius: "4px",
        }}
      >
        <input
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Pesquisar por nome ou BI"
          className={styles.searchInput}
        />
        <Search
          style={{
            fontSize: "24px",
            color: "#cdc9c9",
            paddingRight: "10px",
          }}
        />
      </Box>
    </Box>
  );
};

export default SearchBar;
