import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Content = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("xl")]: {
    padding: "50px 150px",
  },
  [theme.breakpoints.down("xl")]: {
    padding: "50px 150px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "50px 110px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "10px 30px",
  },
}));
