import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./AdminTitleHeader.module.css";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
const AdminTitleHeader = ({
  title,
  isAlocation,
  entity,
  withAddButton,
  withImportButton,
  onImportButtonClick,
  addPath,
}) => {
  const history = useHistory();

  function removeFirstWord(str) {
    const indexOfSpace = str.indexOf(" ");

    if (indexOfSpace === -1) {
      return "";
    }

    return str.substring(indexOfSpace + 1);
  }

  return (
    <div className={styles.pageTitleSection}>
      {!isAlocation ? (
        <Breadcrumbs separator="››" aria-label="breadcrumb">
          <Link
            sx={{ borderBottom: "2px solid #546570" }}
            onClick={(e) => {
              e.preventDefault();
              history.push("/admin/quadros");
            }}
            underline="none"
            color="text.primary"
            href="/"
          >
            Home
          </Link>
          {!entity && (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => history.goBack()}
              underline="none"
              color="text.primary"
            >
              {removeFirstWord(title)}
              {/* .replace(/ de/g, '') */}
              {/* .replace(/([^s])s\b/, '$1') */}
            </Link>
          )}
          <Typography color="text.primary"> {title} </Typography>
        </Breadcrumbs>
      ) : (
        <Breadcrumbs separator="››" aria-label="breadcrumb">
          <Link
            underline="hover"
            color="inherit"
            onClick={(e) => {
              e.preventDefault();
              history.push("/admin/quadros");
            }}
            href="/"
          >
            Home
          </Link>
          <Link
            onClick={(e) => {
              e.preventDefault();
              history.push(
                `/admin/institution/${
                  history.location.pathname.split("/")[3]
                }/editar`
              );
            }}
            underline="hover"
            color="inherit"
            href="/"
          >
            Editar Entidade
          </Link>
          <Typography color="text.primary"> Alocação de Quadros </Typography>
        </Breadcrumbs>
      )}
      <div className={styles.titleSection}>
        <span className={styles.pageTitle}> {title} </span>
        <div className={styles.buttonsContainer}>
          {withImportButton && (
            <Button
              onClick={() => onImportButtonClick()}
              size="small"
              color="primary"
              variant="contained"
            >
              Importar
            </Button>
          )}
          {withAddButton && (
            <Button
              onClick={() => history.push(addPath)}
              size="small"
              color="primary"
              variant="contained"
            >
              Novo
            </Button>
          )}
        </div>
      </div>
      <hr className={styles.contentDivider} />
    </div>
  );
};

export default AdminTitleHeader;
