import styled from "styled-components";

export const ModalContainer = styled.div`
  z-index: 11;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  opacity: ${(props) => (props.show ? 1 : 0)};
  pointer-events: ${(props) => (props.show ? "visible" : "none")};
`;

export const ModalContent = styled.div`
  background-color: #fff;
  border-radius: 4px;
  width: 40%;
  transform: ${(props) =>
    props.show ? "translateY(0)" : "translateY(-200px)"};
  transition: all 0.3s ease-in-out;
`;

export const ModalHeader = styled.div`
  padding: 10px;
`;
export const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.01em;

  i {
    cursor: pointer;
  }
`;

export const ModalBody = styled.div`
  padding: 10px;
  /* border-top: 1px solid #eee;
  border-bottom: 1px solid #eee; */
`;

export const ModalFooter = styled.div`
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  button:nth-of-type(2) {
    background-color: #f32013;
    color: white;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    width: 7rem;
    border-radius: 4px;
  }

  button:nth-of-type(1) {
    background-color: #cdcdcd;
    color: white;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    width: 7rem;
    border-radius: 4px;
  }
`;
