import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--theme-primary);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  padding: 50px 0;
  /* margin-top: auto; */

  img {
    width: 100px;
    margin-bottom: 1rem;
    mix-blend-mode: luminosity;
    opacity: 0.5;
  }
  color: #868686;

  span {
    font-size: 10px !important;
    text-transform: uppercase;
    letter-spacing: 5px !important;
    margin: 2px;
    // font-weight: bold;
  }
`;
