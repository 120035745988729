import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import { Label, LabelContainer } from "./Label";
import { ErrorMessage } from "./ErrorMessage";

export default function TextArea({ name, label, row, isMandatory, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      <LabelContainer>
        <Label htmlFor={fieldName}>{label}</Label>
        {isMandatory && <span style={{ color: "red" }}>*</span>}
      </LabelContainer>
      <textarea
        id={fieldName}
        ref={inputRef}
        rows={row || 8}
        defaultValue={defaultValue}
        {...rest}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
}

// const styles = {
//   width: "88.655%",
// };
