import * as Yup from "yup";
import EducationalInstitutionSchema from "./EducationalInstitutionSchema";
import CourseSchema from "./CourseSchema";
import CountrySchema from "./CountrySchema";

const LiteraryQualificationSchema = Yup.object().shape({
  address: Yup.string().nullable().default(""),
  levelOfEducation: Yup.string().required("O Grau Academico é obrigatório"),
  scholarshipHolder: Yup.boolean(),
  completionYear: Yup.string().nullable().default(""),
  // average: Yup.string().test(
  //   "isAverageValid",
  //   "A média deve ser entre 10 e 20",
  //   (val) => {
  //     return val !== "" ? parseInt(val) >= 10 && parseInt(val) <= 20 : true;
  //   }
  // ),
  // educationalInstitution: EducationalInstitutionSchema.transform(
  //   (value, original) => {
  //     return value === null ? { id: "" } : value;
  //   }
  // ).concat(
  //   Yup.object().shape({
  //     id: Yup.string().test(
  //       "isValid",
  //       "A educação de ensino é obrigatória.",
  //       (val) => {
  //         return val === undefined || val !== "";
  //       }
  //     ),
  //   })
  // ),
  // course: Yup.object().shape({
  //   id: Yup.string().test("isValid", "O curso é obrigatório.", (val) => {
  //     return val === null || val !== "";
  //   }),
  // }),
  country: CountrySchema.transform((value, original) => {
    return value === null ? { id: "" } : value;
  }).concat(
    Yup.object()
      .shape({
        id: Yup.string()
          .test("isValid", "O país é obrigatório.", (val) => {
            return val === null || val !== "";
          })
          .nullable()
          .default(""),
      })
      .nullable()
  ),
  // anotherCourse: Yup.string().optional(),
  // otherEducationalInstitution: Yup.string().optional(),
});

// Illiterate Validation Schema
export const IlliterateSchema = Yup.object().shape({
  levelOfEducation: Yup.string().required("O nível de educação é necessário."),
});

export default LiteraryQualificationSchema;
