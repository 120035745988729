import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import { Label, LabelContainer } from "./Label";
import { ErrorMessage } from "./ErrorMessage";
import { Box } from "@mui/material";
import useAutoCompleteOthers from "../../admin/hooks/useAutoCompleteOthers";
import { Search } from "@mui/icons-material";
import styles from "./AutoComplete.module.css";

export default function AutoCompleteOthers({
  name,
  othersName,
  label,
  onChange = () => {},
  returnOther,
  isMandatory,
  ...rest
}) {
  const containerRef = useRef(null);
  const optionsBoxRef = useRef(null);
  const autoCompleteRef = useRef(null);
  const autoCompleteOthersRef = useRef(null);

  const {
    showSuggestions,
    showOthers,
    options,
    onSuggestionClick,
    onInputChange,
    onFocus,
  } = useAutoCompleteOthers({
    optionsCode: rest.optionscode,
    allOptionsCode: rest.alloptionscode,
    autoCompleteRef,
    optionsBoxRef,
  });
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const {
    fieldName: fieldNameOthers,
    defaultValue: defaultValueOthers,
    registerField: registerFieldOthers,
    error: errorOthers,
  } = useField(othersName);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: autoCompleteRef,
      getValue: (ref) => {
        if (showOthers || returnOther) return autoCompleteRef.current.value;
        if (!ref.current.id) {
          return ref.current.value;
        }
        return ref.current.id;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    registerFieldOthers({
      name: fieldNameOthers,
      ref: autoCompleteOthersRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldNameOthers, registerFieldOthers]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <LabelContainer>
        <Label htmlFor={fieldName}>{label}</Label>
        {isMandatory && <span style={{ color: "red" }}>*</span>}
      </LabelContainer>
      <Box
        ref={containerRef}
        sx={{
          display: "flex",
          alignItems: "center",
          border: "1px solid #dfdfdf !important",
          borderRadius: "4px",
        }}
      >
        <input
          ref={autoCompleteRef}
          defaultValue={
            rest?.uniquevalue ? rest.uniquevalue.name : defaultValue
          }
          onChange={(e) => {
            onInputChange(e.target.value);
          }}
          disabled={rest.disabled || showOthers || returnOther}
          className={styles.searchInput}
          onFocus={() => onFocus()}
          autoComplete="off"
          name={rest.field}
        />
        <Search
          style={{
            fontSize: "24px",
            color: "#cdc9c9",
            paddingRight: "10px",
          }}
        />
      </Box>
      <>
        {" "}
        <input
          ref={autoCompleteOthersRef}
          disabled={rest.disabled}
          name={rest.otherField}
          placeholder="Inserir outro"
          autoComplete="off"
          style={{
            marginTop: ".5rem",
            display: showOthers || returnOther ? "flex" : "none",
          }}
        />
      </>
      {showSuggestions && (
        <Box
          ref={optionsBoxRef}
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#fff",
            position: "absolute",
            border: "1px solid #eee",
            borderRadius: "4px",
            overflow: "hidden",
            width: containerRef?.current?.offsetWidth - 2,
            marginTop: "70px",
          }}
        >
          {showSuggestions &&
            options?.map((option, index) => {
              return (
                <Box
                  key={index}
                  p="0.5rem"
                  sx={{
                    cursor: "pointer",
                    backgroundColor: "lightgray",
                    borderRadius: "4px",
                    zIndex: 9,
                    borderBottom: "1px solid #eee",
                    backgroundColor: "#f9f9f9",
                  }}
                  onClick={() => {
                    autoCompleteRef.current.id = JSON.stringify(option);
                    autoCompleteRef.current.value = option.name;
                    onSuggestionClick(option);
                    return onChange(option);
                  }}
                >
                  {option.name}
                </Box>
              );
            })}
        </Box>
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {errorOthers && <ErrorMessage>{errorOthers}</ErrorMessage>}
    </div>
  );
}
