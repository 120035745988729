import * as Yup from "yup";

const UpdateAllocationSchema = Yup.object().shape({
  employeeId: Yup.string().required("O id do quadro é obrigatório."),
  unit: Yup.object().shape({
    name: Yup.string().required("A unidade organica é obrigatória."),
  }),
  // institutionOfOrigin: Yup.object().shape({
  //   id: Yup.string().required("A entidade é obrigatória."),
  // }),
  // position: Yup.object().shape({
  //   id: Yup.string().required("O cargo é obrigatório."),
  // }),
  // category: Yup.object().shape({
  //   id: Yup.string(),
  //   name: Yup.string().when("id", {
  //     is: (id) => id === null || id === undefined || id === "",
  //     then: Yup.string().required("Deve indicar uma categoria já existente"),
  //   }),
  // }),
  changeOfJuridicalBond: Yup.object().shape({
    id: Yup.string().required("A mudança da relação juridica é obrigatória."),
  }),
  contractualRegimen: Yup.object().shape({
    id: Yup.string().required("O regime de contrato é obrigatório."),
  }),
  juridicalBond: Yup.object().shape({
    id: Yup.string().required("A relação juridica é obrigatória."),
  }),
  // agentNumber: Yup.string()
  //   .required("O Nº Agente é obrigatório.")
  //   .test("isLengthValid", "O Nº de Agente tem que ter 8 dígitos.", (val) => {
  //     return val.length === 8;
  //   }),
  // ssNumber: Yup.string().required("O Nº de Seg. Social é obrigatório."),
  // cifNumber: Yup.string().required("O código de identificação é obrigatório."),
  consultant: Yup.boolean().required("Este campo é obrigatorio"),
  startedOn: Yup.string().test(
    "isLengthValid",
    "A data do inicio das funções é obrigatória.",
    (val) => {
      return val.length > 0;
    }
  ),
});

export default UpdateAllocationSchema;
