import styled from "styled-components";

export const Label = styled.label`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--theme-primary-label);
  padding: 10px;
  display: block;
`;

export const LabelContainer = styled.label`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;
