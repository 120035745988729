import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import { Label, LabelContainer } from './Label';
import { ErrorMessage } from './ErrorMessage';
import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import useAutoComplete from '../../admin/hooks/useAutoComplete';
import { Search } from '@material-ui/icons';
import styles from './AutoComplete.module.css';
import axios from '../../services/AxiosService';
import KeycloakService from '../../services/KeycloakService';

export default function AutoComplete({
  name,
  label,
  onChange = () => {},
  Select,
  returnOther,
  isMandatory,
  uniquevalue = null,
  ...rest
}) {
  const containerRef = useRef(null);
  const optionsBoxRef = useRef(null);
  const autoCompleteRef = useRef(null);

  const {
    showSuggestions,
    options,
    onSuggestionClick,
    onInputChange,
    onFocus,
    uniqueItem,
  } = useAutoComplete({
    optionsCode: rest.optionscode,
    allOptionsCode: rest.alloptionscode,
    autoCompleteRef,
    optionsBoxRef,
    uniqueValue: uniquevalue,
    disabledvalues: rest.disabledvalues,
    splitCode: rest.splitCode,
    setSplitValue: rest.setSplitValue,
  });

  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [showDialog, setShowDialog] = useState('');
  const [splitValues, setSplitedValues] = useState([]);
  const [splitOptions, setSplitOption] = useState([]);
  const [splitCurrentValue, setSplitCurrentValue] = useState(null);
  const [, updateState] = useState();
  const update = useCallback(() => updateState({}), []);

  useEffect(() => {
    if (uniqueItem && autoCompleteRef.current) {
      if (uniqueItem?.id) {
        autoCompleteRef.current.id = uniqueItem.id;
      }
      if (uniqueItem?.name) {
        autoCompleteRef.current.value = uniqueItem.name;
      }
    }
  }, [uniqueItem]);
  const onOptionsInputChange = ({ splitCode, lsv, i }) => {
    if (i > setSplitedValues.length) {
      if (lsv && autoCompleteRef.current) {
        if (lsv) {
          autoCompleteRef.current.id = lsv;
        }

        if (lsv && splitValues[i - 1]?.find((a) => a.id == lsv)?.name) {
          setSplitCurrentValue(
            splitValues[i - 1]?.find((a) => a.id == lsv)?.name
          );
          //autoCompleteRef.current.value = splitValues[i-1]?.find((a=> a.id == lsv))?.name
        }
      }
    } else
      axios
        .get(
          lsv
            ? process.env.REACT_APP_API_URL +
                `${splitCode}/${lsv}?size=10000&page=0`
            : process.env.REACT_APP_API_URL + `${splitCode}?size=10000&page=0`,
          {
            headers: {
              Authorization: `Bearer ${KeycloakService.getToken()}`,
            },
          }
        )
        .then((response) => {
          if (typeof response.data.content === 'object') {
            let sv = splitValues;
            sv[i] = response.data.content;
            setSplitedValues(sv);
            //alert(lsv)
            update();
            // if (response.data.content.length > 0) {
            //   setShowSuggestions(true);
            // }
          } else {
            let sv = splitValues;
            sv[i] = response.data;
            setSplitedValues(sv);
            // if (response.data.length > 0) {
            //   setShowSuggestions(true);
            // }
          }
        })
        .catch((e) => {
          console.log(e);
        });
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: autoCompleteRef,
      getValue: (ref) => {
        if (returnOther) return autoCompleteRef.current.value;
        if (!ref.current.id) {
          return ref.current.value;
        }
        return ref.current.id;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);
  useEffect(() => {
    if (rest.split) {
      setSplitedValues((sv) => {
        return rest.splitOptionsCodes?.map((e, i) => {
          return [];
        });
      });
      setSplitOption((sv) => {
        return rest.splitOptionsCodes?.map((e, i) => {
          return 0;
        });
      });
    }
    onOptionsInputChange({
      splitCode: rest?.splitOptionsCodes ? rest?.splitOptionsCodes[0] : null,
      i: 0,
    });
  }, [rest.splitOptionsCodes, setSplitedValues]);

  if (splitValues.length <= 0 && rest.split && !Select) return null;
  else
    return (
      <>
        <LabelContainer>
          <Label htmlFor={fieldName}>{label}</Label>
          {isMandatory && <span style={{ color: 'red' }}>*</span>}
        </LabelContainer>
        <Box
          ref={containerRef}
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #dfdfdf !important',
            borderRadius: '4px',
          }}
        >
          <input
            ref={autoCompleteRef}
            defaultValue={rest?.uniquevalue ? rest.uniquevalue : defaultValue?.name || defaultValue}
            onChange={(e) => {
              onInputChange(e.target.value);
            }}
            className={styles.searchInput}
            onFocus={() => onFocus()}
            onClick={() => {
              if (rest.split) setShowDialog(true);
            }}
            disabled={rest.disabled}
            autoComplete='off'
            placeholder={rest.placeholder}
            name={rest.field}
            {...rest}
          />
          <Search
            style={{
              fontSize: '24px',
              color: '#cdc9c9',
              paddingRight: '10px',
            }}
          />
        </Box>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {showSuggestions && (
          <Box
            ref={optionsBoxRef}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#fff',
              position: 'absolute',
              border: '1px solid #eee',
              borderRadius: '4px',
              overflow: 'hidden',
              width: containerRef?.current?.offsetWidth - 2,
            }}
          >
            {showSuggestions &&
              options?.map((option, index) => {
                return (
                  <Box
                    key={index}
                    py='9px'
                    px='20px'
                    sx={{
                      cursor: 'pointer',
                      backgroundColor: 'lightgray',
                      zIndex: 9,
                      borderBottom: '1px solid #eee',
                      backgroundColor: '#f9f9f9',
                    }}
                    onClick={() => {
                      autoCompleteRef.current.id = JSON.stringify(option);
                      autoCompleteRef.current.value = option.name;
                      onSuggestionClick(option);
                      // rest?.setSplitValue(option.id);
                      //alert(option.id)
                      return onChange(option);
                    }}
                  >
                    {option.name}
                  </Box>
                );
              })}
          </Box>
        )}
        <Modal
          open={showDialog}
          onClose={() => setShowDialog(false)}
          aria-labelledby='modal-cease-functions'
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 800,
              height: 'auto',
              overflowY: 'auto',
              bgcolor: '#eee',
              boxShadow: 24,
              p: 5,
              border: 0,
              borderRadius: 5,
            }}
          >
            <Typography
              variant='h3'
              sx={{
                fontSize: '18px !important',
                fontWeight: 'bold',
                textAlign: 'left',
                pb: 5,
                marginLeft: 'auto',
              }}
            >
              Adicionar categoria
            </Typography>
            <Grid container spacing={2}>
              {rest.splitOptionsCodes?.map((c, i) => {
                return (
                  <Grid md={6} xs={12} item>
                    {' '}
                    <Select
                      key={i}
                      name={name}
                      label={i > 0 ? 'Categoria' : 'Carreiras'}
                      options={splitValues[i]}
                      onChange={(e) =>
                        onOptionsInputChange({
                          splitCode: rest.splitOptionsCodes[i + 1],
                          i: i + 1,
                          lsv: e.target.value,
                        })
                      }
                      //setSplitValue={(val) =>{setSplitedValues([val,0]); alert(splitValues)}}
                      splitCode={
                        i > 0 ? '/' + c + '/' + splitValues[i - 1] : null
                      }
                      isMandatory={isMandatory}
                    />
                  </Grid>
                );
              })}
              <Grid xs={12} item>
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                      marginTop: 10,
                      gap: '1rem',
                    }}
                  >
                    <>
                      <Button
                        variant='outlined'
                        type='cancel'
                        onClick={() => setShowDialog(false)}
                      >
                        Cancelar
                      </Button>
                      <Button
                        type='button'
                        onClick={() => {
                          autoCompleteRef.current.value = splitCurrentValue;
                          setShowDialog(false);
                        }}
                        variant='contained'
                      >
                        Confirmar
                      </Button>
                    </>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </>
    );
}
