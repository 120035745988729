import React from "react";
import { Box, Button, useTheme } from "@mui/material";

const HorizontalMenu = ({ options, currentOption }) => {
  const theme = useTheme();

  return (
    <Box mx="4rem">
      {options?.map((option, index) => {
        return (
          <Button
            key={index}
            sx={{
              textTransform: "none",
              color: "#29333a",
              fontWeight: 600,
              fontSize: "13px",
              backgroundColor:
                currentOption === option.code ? theme.custom.lightGrey : "none",
              borderBottom:
                currentOption === option.code ? "2px solid #29333a" : "none",
              borderRadius: 0,
              padding: "0.7rem 1rem",
            }}
            onClick={option.onClick}
          >
            {option.label}
          </Button>
        );
      })}
    </Box>
  );
};

export default HorizontalMenu;
