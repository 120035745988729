import React, { useEffect, useState } from "react";
import { Avatar } from "@mui/material";
import defaultImage from "../../../assets/img/img-placeholder.png";
import KeycloakService from "../../../services/KeycloakService";
import axios from "../../../services/AxiosService";
import { styled } from "@mui/material/styles";

const ProfilePicture = ({ entity, entityId, withMargins }) => {
  const [image, setImage] = useState(null);

  const ResponsiveAvatar = styled(Avatar)(({ theme }) => ({
    [theme.breakpoints.up("xl")]: {
      width: 200,
      height: 200,
      marginTop: withMargins ? "-6.5rem" : 0,
      marginLeft: withMargins ? "1.8rem" : 0,
    },
    [theme.breakpoints.down("xl")]: {
      width: 200,
      height: 200,
      marginTop: withMargins ? "-6.5rem" : 0,
      marginLeft: withMargins ? "1.8rem" : 0,
    },
    [theme.breakpoints.down("lg")]: {
      width: 150,
      height: 150,
      marginTop: withMargins ? "-6.5rem" : 0,
      marginLeft: withMargins ? "1.8rem" : 0,
    },
  }));

  const getImage = (id) => {
    if (!id) return;
    axios
      .get(`/${entity}/${id}/pictures-profiles/files`, {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
        responseType: "arraybuffer",
      })
      .then((response) => {
        if (response.data) {
          let base64ImageString = Buffer.from(response.data, "binary").toString(
            "base64"
          );
          let srcValue = "data:image/png;base64," + base64ImageString;
          setImage(srcValue);
        }
      });
  };

  useEffect(() => {
    if (entityId) {
      getImage(entityId);
    }
  }, [entityId]);

  return <ResponsiveAvatar variant="rounded" src={image || defaultImage} />;
};

export default ProfilePicture;
