import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { setSnackbar } from "../../store/reducers/admin/actionCreators";
import Divider from "@mui/material/Divider";

import { useHistory, useLocation } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AppSnackbar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const isMatchingRoute = (pattern) =>
    new RegExp(pattern).test(location.pathname);

  const handleCloseAndReturnToList = () => {
  handleClose();
};

  const snackbarIsOpen = useSelector(
    (state) => state.adminReducer.snackbarIsOpen
  );
  const snackbarMessage = useSelector(
    (state) => state.adminReducer.snackbarMessage
  );
  const snackbarType = useSelector((state) => state.adminReducer.snackbarType);

  const usersSnackbarIsOpen = useSelector(
    (state) => state.usersReducer.snackbarIsOpen
  );
  const usersSnackbarMessage = useSelector(
    (state) => state.usersReducer.snackbarMessage
  );
  const usersSnackbarType = useSelector(
    (state) => state.usersReducer.snackbarType
  );

  const vertical = "top";
  const horizontal = "center";

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(
      setSnackbar({ snackbarIsOpen: false, snackbarMessage, snackbarType })
    );
  };

  return (
    // <Snackbar
    //   open={snackbarIsOpen || usersSnackbarIsOpen}
    //   autoHideDuration={3000}
    //   onClose={handleClose}
    //   anchorOrigin={{ vertical, horizontal }}
    // >
    //   <MuiAlert
    //     elevation={6}
    //     variant="filled"
    //     onClose={handleClose}
    //     severity={snackbarType || usersSnackbarType}
    //     sx={{
    //       "& .MuiAlert-root": {
    //         display: "flex",
    //         alignItems: "center !important",
    //       },
    //     }}
    //   >
    //     {snackbarMessage || usersSnackbarMessage}
    //   </MuiAlert>
    // </Snackbar>
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={snackbarIsOpen || usersSnackbarIsOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={snackbarIsOpen || usersSnackbarIsOpen}>
        <Box sx={style}>
          <h2 id="transition-modal-title">Alerta</h2>
          <Divider />
          <Typography id="transition-modal-description" sx={{ mt: 2 }}>
            {snackbarMessage || usersSnackbarMessage}
          </Typography>
          <Divider sx={{ marginTop: "10px", color: "#069" }} />
          <Button
            sx={{ marginTop: "10px" }}
            variant="contained"
            onClick={handleCloseAndReturnToList}
            autoFocus
          >
            Fechar
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
};

export default AppSnackbar;
