import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { format, parse, parseISO } from 'date-fns';
import { Label, LabelContainer } from './Label';
import { ErrorMessage } from './ErrorMessage';
import styled from 'styled-components';

export default function Input({ name, label, isMandatory, style, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  const formatDate = (date) => {
    if (!date) return '';
    const noHoursDate = date.split(' ')[0];
    const year = noHoursDate.split('-')[2];
    const month = noHoursDate.split('-')[1];
    const day = noHoursDate.split('-')[0];
    const parsedDate = parseISO(`${year}-${month}-${day}`);
    return format(parsedDate, 'yyyy-MM-dd');
  };

  return (
    <div>
      <LabelContainer>
        <Label htmlFor={fieldName}>{label}</Label>
        {isMandatory && <span style={{ color: 'red' }}>*</span>}
      </LabelContainer>
      {rest?.isPasswordField ? (
        <PasswordInput
          id={fieldName}
          ref={inputRef}
          defaultValue={
            rest.definedvalue
              ? rest.definedvalue
              : rest.type == 'date'
              ? formatDate(defaultValue)
              : defaultValue
          }
          {...rest}
        />
      ) : (
        <input
          id={fieldName}
          ref={inputRef}
          defaultValue={
            rest.definedvalue
              ? rest.definedvalue
              : rest.type == 'date'
              ? formatDate(defaultValue)
              : defaultValue
          }
          style={style}
          {...rest}
        />
      )}

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
}

const PasswordInput = styled.input`
  border: none !important;
  outline: none;
`;
