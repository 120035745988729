import { combineReducers } from "redux";
import authReducer from "./reducers/auth/authReducer";
import usersReducer from "./reducers/users/usersReducer";
import adminReducer from "./reducers/admin/adminReducer";

const rootReducer = combineReducers({
  authReducer,
  usersReducer,
  adminReducer,
});

export default rootReducer;
