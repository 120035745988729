import React, { useEffect, useState } from 'react';
import { Box, useTheme, Modal, Tooltip } from '@mui/material';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AdminTitleHeader from '../../../components/AdminTitleHeader/AdminTitleHeader';
import useAlocacaoQuadros from '../../../hooks/useAlocacaoQuadros';
import CommonModal from '../../../../components/Modal/CommonModal/CommonModal';
import TableComponent from '../../../../components/Table/TableComponent/TableComponent';
import AppPagination from '../../../../components/Table/Pagination/Pagination';
import { getEntitiesRequest } from '../../../../store/reducers/admin/actionCreators';
import HorizontalMenu from '../../../components/HorizonalMenu/HorizontalMenu';
import CommonFormDialog from '../../../../components/Form/CommonFormDialog/CommonFormDialog';
import {
  deleteAssignedEmployeeRequest,
  getUnitEmployeesRequest,
} from '../../../../store/reducers/users/actionCreators';

const AlocacaoQuadros = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();

  const [displayColumns, setDisplayColumns] = useState([
    {
      field: 'category?.name',
      sortKeyword: 'category.name',
      label: 'Categoria',
      direction: 'asc',
    },
    {
      field: 'employee?.name',
      sortKeyword: 'employee.name',
      label: 'Quadro',
      direction: 'asc',
    },
    {
      field: 'contractualRegimen?.name',
      sortKeyword: 'contractualRegimen.name',
      label: 'Regime de Contratação',
      direction: 'asc',
    },
    {
      field: 'position?.name',
      sortKeyword: 'position.name',
      label: 'Cargo',
      direction: 'asc',
    },
    {
      field: 'agentNumber',
      sortKeyword: 'agentNumber',
      label: 'Número de Agente',
      direction: 'asc',
    },
    {
      field: 'startedOn',
      sortKeyword: 'startedOn',
      label: 'Início Funções',
      direction: 'asc',
    },
  ]);
  const [showModal, setShowModal] = useState('');
  const [showDialog, setShowDialog] = useState('');
  const [dialogMetada, setDialogMetada] = useState(null);
  const [dialogValidation, setDialogValidation] = useState(null);
  const [dialogData, setDialogData] = useState(null);
  const [deleteObject, setDeleteObject] = useState([]);
  const [horizontalMenuOption, setHorizontalMenuOption] =
    useState('alocacao_quadros');

  const {
    unit,
    unitEmployees,
    setCurrentEmployee,
    emptyEmployee,
    setId,
    ceaseFunctionsFieldsMetaData,
    ceaseFunctionsDialogSchemaValidation,
    handleCeaseFunctions,
    unitEmployeesTotalPages,
    unitEmployeesTotalElements,
    setPageIndex,
  } = useAlocacaoQuadros(
    match.params.institutionId,
    match.params.unitId,
    match.params.employeeId,
    history.location.pathname
  );
  let isAdmin = history.location.pathname.includes('admin') ? true : false;

  const onDeleteConfirm = (id) => {
    dispatch(
      deleteAssignedEmployeeRequest({
        id,
        showNotification: true,
        onSuccessMessage: 'Alocação removida com sucesso',
        callback: () => {
          return dispatch(
            getUnitEmployeesRequest({ unitId: match.params.unitId })
          );
        },
      })
    );
    setShowModal(false);
  };

  const onDeletePendent = (object) => {
    setShowModal(true);
    setDeleteObject(object);
  };

  const actions = [
    {
      label: 'Visualizar',
    },
    {
      label: 'Editar',
    },
    { label: 'Remover' },
    {
      label: 'Mobilidade',
    },
    {
      label: 'Exoneração',
    },
    {
      label: 'Nomeação',
    },
    {
      label: 'Progressão na carreira',
    },
    {
      label: 'Mudança de relação jurídica',
    },
    {
      label: 'Extinção da relação Jurídica',
    },
  ];

  const onActionChange = (val, item) => {
    switch (val) {
      case 'Visualizar':
        return isAdmin
          ? history.push(
              `/admin/instituicoes/${match.params.institutionId}/orgaos/${match.params.unitId}/ver/${item.employee.id}`
            )
          : history.push(
              `/instituicoes/${match.params.institutionId}/orgaos/${match.params.unitId}/ver/${item.employee.id}`
            );
      case 'Editar': {
        isAdmin
          ? history.push(
              `/admin/instituicoes/${match.params.institutionId}/orgaos/${match.params.unitId}/editar/${item.employee.id}`
            )
          : history.push(
              `/instituicoes/${match.params.institutionId}/orgaos/${match.params.unitId}/editar/${item.employee.id}`
            );
        return setCurrentEmployee(item);
      }
      case 'Mobilidade': {
        isAdmin
          ? history.push(
              //`/admin/instituicoes/${match.params.institutionId}/orgaos/${match.params.unitId}/mobilidade/${item.employee.id}`
              //`/admin/instituicoes/${match.params.institutionId}/orgaos/${match.params.unitId}/mobility/${item.employee.id}`
              `/admin/instituicoes/${match.params.institutionId}/orgaos/${match.params.unitId}/mobilidade/${item.employee.id}`
            )
          : history.push(
              //`/instituicoes/${match.params.institutionId}/orgaos/${match.params.unitId}/mobilidade/${item.employee.id}`
              //`/instituicoes/${match.params.institutionId}/orgaos/${match.params.unitId}/mobility/${item.employee.id}`
              `/instituicoes/${match.params.institutionId}/orgaos/${match.params.unitId}/mobilidade/${item.employee.id}`
            );
        return setCurrentEmployee(item);
      }
      case 'Exoneração': {
        isAdmin
          ? history.push(
              `/admin/instituicoes/${match.params.institutionId}/orgaos/exoneracao/${match.params.unitId}/${item.employee.id}`
            )
          : history.push(
              `/instituicoes/${match.params.institutionId}/orgaos/exoneracao/${match.params.unitId}/${item.employee.id}`
            );
        return setCurrentEmployee(item);
      }
      case 'Nomeação': {
        isAdmin
          ? history.push(
              `/admin/instituicoes/${match.params.institutionId}/orgaos/nomeacao/${match.params.unitId}/${item.employee.id}`
            )
          : history.push(
              `/instituicoes/${match.params.institutionId}/orgaos/nomeacao/${match.params.unitId}/${item.employee.id}`
            );
        return setCurrentEmployee(item);
      }

      case 'Progressão na carreira': {
        isAdmin
          ? history.push(
              `/admin/instituicoes/${match.params.institutionId}/orgaos/progresso/${match.params.unitId}/${item.employee.id}`
            )
          : history.push(
              `/instituicoes/${match.params.institutionId}/orgaos/progresso/${match.params.unitId}/${item.employee.id}`
            );
        return setCurrentEmployee(item);
      }

      case 'Mudança de relação jurídica': {
        isAdmin
          ? history.push(
              `/admin/instituicoes/${match.params.institutionId}/orgaos/destacamento/${match.params.unitId}/${item.employee.id}`
            )
          : history.push(
              `/instituicoes/${match.params.institutionId}/orgaos/destacamento/${match.params.unitId}/${item.employee.id}`
            );
        return setCurrentEmployee(item);
      }
      case 'Extinção da relação Jurídica': {
        setDialogMetada(ceaseFunctionsFieldsMetaData(item.employee.name));
        setDialogValidation(ceaseFunctionsDialogSchemaValidation);
        setCurrentEmployee(item);
        return setShowDialog(true);
      }
      case 'Remover':
        return onDeletePendent(item);
      default:
        return;
    }
  };

  const horizontalButtons = [
    {
      code: 'alocacao_quadros',
      label: 'Alocação de Quadros',
      onClick: () => setHorizontalMenuOption('alocacao_quadros'),
    },
  ];

  const margin = history.location.pathname.includes('admin') ? '' : '4rem 7rem';

  const ellipsisText = {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', margin }}>
      <AdminTitleHeader isAlocation={true} title={'Alocação de Quadros'} />
      <Box
        sx={{
          backgroundColor: '#fff',
          border: '1px solid #dfdfdf',
          borderBottom: '2px solid #dfdfdf',
          padding: '1rem 3rem',
          display: 'flex',
        }}
      >
        <Box sx={{ width: '50%', display: 'flex', gap: '2rem' }}>
          <Box
            sx={{
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              alignItems: 'flex-end',
              color: theme.palette.primary.dark,
              fontWeight: 'bold',
            }}
          >
            <span>Entidade</span>
            <span>Data de constituição</span>
          </Box>
          <Box
            sx={{
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              alignItems: 'flex-start',
              color: '#3B4144',
            }}
          >
            <Tooltip title={unit?.institution?.name} placement='top'>
              <span style={ellipsisText}>{unit?.institution?.name}</span>
            </Tooltip>
            <Tooltip
              title={unit?.institution?.constitutionDate}
              placement='top-start'
            >
              <span style={ellipsisText}>
                {moment(unit?.institution?.constitutionDate).format(
                  'DD-MM-yyyy'
                )}
              </span>
            </Tooltip>
          </Box>
        </Box>
        <Box sx={{ width: '50%', display: 'flex', gap: '2rem' }}>
          <Box
            sx={{
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              alignItems: 'flex-end',
              color: theme.palette.primary.dark,
              fontWeight: 'bold',
            }}
          >
            <span>Orgão</span>
            <span>Pelouro</span>
          </Box>
          <Box
            sx={{
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              alignItems: 'flex-start',
              color: '#3B4144',
            }}
          >
            <Tooltip title={unit?.name} placement='top'>
              <span style={ellipsisText}>{unit?.name}</span>
            </Tooltip>
            <Tooltip title={unit?.expertiseName} placement='top'>
              <span style={ellipsisText}>{unit?.expertiseName}</span>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <HorizontalMenu
        currentOption={horizontalMenuOption}
        options={horizontalButtons}
      />
      <div>
        <div>
          <TableComponent
            displayColumns={displayColumns}
            items={unitEmployees}
            actions={actions}
            onActionChange={onActionChange}
            onClickHeaderButton={() => {
              setId(null);
              setCurrentEmployee(emptyEmployee);
              history.location.pathname.includes('admin')
                ? history.push(
                    `/admin/instituicoes/${match.params.institutionId}/orgaos/${match.params.unitId}/alocar`
                  )
                : history.push(
                    `/instituicoes/${match.params.institutionId}/orgaos/${match.params.unitId}/alocar`
                  );
            }}
            withHeaderButton
          />
        </div>
        <div style={{ marginTop: '1rem' }}>
          <AppPagination
            totalPages={unitEmployeesTotalPages}
            totalElements={unitEmployeesTotalElements}
            onPageChange={(value) => setPageIndex(value)}
          />
        </div>
        <CommonModal
          show={showModal}
          title='Remover Quadro Alocado'
          body={`Tem a certeza que pretende remover a alocação do quadro ${deleteObject?.employee?.name}?`}
          onClose={() => setShowModal(false)}
          onConfirm={() => onDeleteConfirm(deleteObject?.id)}
          onCancel={() => setShowModal(false)}
        />
      </div>
      <Modal
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-labelledby='modal-cease-functions'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 800,
            overflowY: 'auto',
            bgcolor: '#eee',
            boxShadow: 24,
            p: 0,
            border: 0,
            borderRadius: 5,
          }}
        >
          <CommonFormDialog
            tab1={dialogMetada}
            schemaValidation={dialogValidation}
            data={dialogData}
            submitFunction={(obj) => {
              setShowDialog(false);
              return handleCeaseFunctions(obj);
            }}
            onCancel={() => setShowDialog(false)}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default AlocacaoQuadros;
