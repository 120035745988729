import styled from "styled-components";

export const Image = styled.img`
  width: 93px;
  height: 57px;
`;

export const IconsContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  img {
    width: 20px !important;
    height: 20px !important;
    margin: 0 !important;
    border-radius: 0;
    cursor: pointer;
  }

  select::-ms-expand {
    display: none;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
  }
`;
