import * as Yup from "yup";

const KnowledgeAreaSchema = Yup.object().shape({
  id: Yup.string().optional().nullable(),
  name: Yup.string().when("id", {
    is: (id) => id === null || id === undefined,
    then: Yup.string().required("O nome é obrigatório"),
  }),
});

export default KnowledgeAreaSchema;
