import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import { Container, FileContainer } from "./styles";
import { ErrorMessage } from "../ErrorMessage";
import { Label } from "../Label";
import { Button } from "@mui/material";
import FileUploadIcon from '@mui/icons-material/FileUpload';

const FilePicker = ({ name, label, ...rest }) => {
  const fileInputRef = useRef();
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: fileInputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  const loadFile = () => {
    fileInputRef.current.click();
  };

  const onFileLoad = (event) => {
    const files = event.target.files;
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        rest.onFileChange(files[0], reader.result);
      }
    };
    reader.readAsDataURL(files[0]);
  };

  return (
    <Container style={rest.styles}>
      <Label>{label}</Label>
      <FileContainer>
      <Button
                title={"Selecione um Ficheiro"}
                className={""}
                variant={"primary"}
                onClick={loadFile}
                size="large"
              >
                {rest.defaultValue || "Selecione um Ficheiro"}
                <FileUploadIcon/>
              </Button>
        <input
          ref={fileInputRef}
          type="file"
          accept=".pdf"
          onChange={(event) => onFileLoad(event)}
          disabled={rest.disabled}
        />
      </FileContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
};

export default FilePicker;
