import { createStore, applyMiddleware, compose } from "redux";

import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./rootReducer";
import rootSaga from "./rootSagas";

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = compose(applyMiddleware(thunk, sagaMiddleware));
export const store = createStore(rootReducer, composeEnhancers);

sagaMiddleware.run(rootSaga);

export default store;
