import React from "react";
import ReactDOM from "react-dom";
import KeycloakService from "./services/KeycloakService";
import "./index.css";
import App from "./App";
import Resetin from "./Resetin";
import reportWebVitals from "./reportWebVitals";
const urlReset = window.location.pathname

const renderApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      { urlReset !== "/resetpin" ? <App /> : <Resetin/>}
    </React.StrictMode>,
    document.getElementById("root")
  );
};

urlReset !== "/resetpin" ? KeycloakService.initKeycloak(renderApp) : renderApp();

reportWebVitals();
