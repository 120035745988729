import * as Yup from "yup";
import CountrySchema from "../CountrySchema";
import ProvinceSchema from "../ProvinceSchema";
import NationalitySchema from "../NationalitySchema";
import NaturalnessSchema from "../NaturalnessSchema";

const PublicAgentSchema = Yup.object().shape({
  bi: Yup.string()
    .required("O bi é obrigatório.")
    .test(
      "isLengthValid",
      "O BI deve ter 9 dígitos 2 letras e mais 3 dígitos",
      (val) => {
        const pattern = new RegExp(/^\d{9}[a-zA-Z]{2}\d{3}$/, "gm");
        return val.length === 14 && pattern.test(val);
      }
    ),
  name: Yup.string().required("O nome é obrigatório."),
  // typeEmployee: Yup.string().required("O tipo de quadro é obrigatório."),
  birthDate: Yup.string().required("A data de nascimento é obrigatória."),
  genre: Yup.string()
    .required("O genero é obrigatório.")
    .test("isValid", "O genero é obrigatório.", (val) => {
      return val !== "null";
    }),
  maritalStatus: Yup.string()
    .required("O Estado Civil é obrigatório.")
    .test("isValid", "O Estado Civil é obrigatório.", (val) => {
      return val !== "null";
    }),
  nationality: NationalitySchema.transform((value, original) => {
    return value === null ? { id: "" } : value;
  }).concat(
    Yup.object().shape({
      id: Yup.string().test(
        "isValid",
        "A nacionalidade é obrigatória.",
        (val) => {
          return val === null || val !== "";
        }
      ),
    })
  ),
  provinceOfBirth: Yup.string().required("A província é obrigatório."),
  municipalityOfBirth: Yup.string().required("O munícipio é obrigatório."),
  phone: Yup.string().required("O telefone é obrigatório."),
  email: Yup.string().email("Email não é valido."),
  countryResidence: CountrySchema.transform((value, original) => {
    return value === null ? { id: "" } : value;
  }).concat(
    Yup.object().shape({
      id: Yup.string().required("O país é obrigatório."),
    })
  ),
  // agentNumber: Yup.string().test(
  //   "isLengthValid",
  //   "O Nº de Agente tem que ter 8 dígitos.",
  //   (val) => {
  //     return val.length === 0 ? true : val.length === 8;
  //   }
  // ),
  // numberSS: Yup.string().test(
  //   "isLengthValid",
  //   "O Nº de Segurança Social deve ter 8 dígitos",
  //   (val) => {
  //     return val.length === 0 ? true : val.length === 8;
  //   }
  // ),
});

export default PublicAgentSchema;
