import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import { Grid } from "@mui/material";
import { Label } from "../Label";

export default function Checkbox({ name, label, selectedValues, ...rest }) {

  const inputRef = useRef();
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return { id: ref.current.value, checked: ref.current.checked };
      },
      clearValue: ref => {
        ref.current.value = { id: ref.current.value, checked: false };
      },
      setValue: (ref, value) => {
        ref.current.value = { id: ref.current.value, checked: value }
      },
    })
  }, [fieldName, registerField]);

  useEffect(() => {
    // console.log('zzzzzzzzzzzz', name);
    // console.log('zzzzzzzzzzzz', defaultValue);
    // console.log('zzzzzzzzzzzz', selectedValues);

    if (selectedValues && defaultValue) {
      selectedValues.map(selection => {
        if (selection.id == defaultValue.id) {
          inputRef.current.checked = true;
        }
      });
    }

  }, [selectedValues, defaultValue]);

  return (
    <Grid container justifyContent='flex-start' alignItems='center'>
      <Grid xs={6} item><Label htmlFor={fieldName}>{label}</Label></Grid>
      <Grid xs={6} item>
        <input
          style={inputSize}
          id={fieldName}
          ref={inputRef}
          defaultValue={defaultValue}
          type="checkbox"
          {...rest}
        />
      </Grid>
    </Grid>
  );
};

const inputSize = {
  height: 15,
  width: 15
}