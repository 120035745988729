import styled from "styled-components";

export const HeaderContainer = styled.div`
  background-color: var(--theme-primary);
  height: 70px;
  display: flex;
  padding: 0px 2rem;
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 99;
  text-transform: uppercase;
  box-shadow: 0 0 5px #0000006b !important;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;

  img {
    cursor: pointer;
    width: 43px;
    height: 49px;
  }
`;

export const Logo = styled.div`
  width: 70px;
  height: 80px;
`;

export const LinksContainer = styled.ul`
  display: flex;
  align-items: center;
  list-style-type: none;
  gap: 0.5rem;
  margin-left: auto;
  /* margin-right: 1rem; */

  @media all and (max-width: 768px) {
    display: none;
  }
`;

export const Links = styled.li`
  display: flex;
  align-items: flex-end;
  padding: 0.2rem 0.8rem;

  img {
    cursor: pointer;
  }

  a {
    text-decoration: none;
    color: ${(props) => (props.active ? "var(--theme-link-selected)" : "#fff")};

    :hover {
      color: var(--theme-link-selected);
    }
  }
`;

export const LinksButton = styled.li`
  display: flex;
  align-items: flex-end;
  padding: 0.2rem 0.8rem;

  img {
    cursor: pointer;
  }

  button {
    text-decoration: none;
    color: ${(props) => (props.active ? "var(--theme-link-selected)" : "#fff")};
    border: 1px solid #fff;
    padding: 4px;
    font-size: 12px;
    :hover {
      color: var(--theme-link-selected);
    }
  }
`;

export const NavLink = styled.div``;

export const HamburguerContainer = styled.div`
  display: ${(props) => (props.visible ? "flex" : "none")};
  flex-flow: column nowrap;
  cursor: pointer;
  background-color: #000;
  z-index: 99;
  padding: 0.2rem;
  border-radius: 4px;
  margin-top: 1rem;

  li {
    padding: 0.2rem;
    list-style-type: none;
    :hover {
      background-color: #81d8f8;
    }

    a {
      text-transform: uppercase;
      text-decoration: none;
      color: white;
    }
  }
`;

export const BurguerIconContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: ${(props) =>
    props.alignCenter ? "center" : "space-between"};
  align-items: center;
  cursor: pointer;
  margin-left: auto;

  @media all and (min-width: 768px) {
    display: none;
  }
`;
