//
import { Avatar } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import imgLogo from "../../../assets/img/equadros_logo.png";
import KeycloakService from "../../../services/KeycloakService";
import styles from "./AdminTopBar.module.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import axios from "../../../services/AxiosService";
import SideMenuJson from "../SideMenu/SideMenu.json";

const drawerWidth = "100%";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const AdminTopBar = () => {
  const [showUserInfoList, setShowUserInfoList] = useState(false);
  const [username, setUsername] = useState("");
  const userInfoListRef = useRef();
  const history = useHistory();

  const [sidenavMenu, setSidenavMenu] = useState(SideMenuJson);

  const updateSidenavMenuView = (targetMenu) => {
    sidenavMenu.forEach((menu, index) => {
      if (menu == targetMenu) menu.displayChildren = !menu.displayChildren;
    });

    setSidenavMenu([...sidenavMenu]);
  };

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logout = () => {
    history.push("/");
    KeycloakService.doLogout();
  };

  const updateRoute = (url) => {
    handleDrawerClose();
    window.scrollTo({ top: 0, behavior: "smooth" });
    return history.push(url);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (value === "Perfil") {
      window.open(
        `${process.env.REACT_APP_KEYCLOAK_URL}realms/Equadros/account/#/personal-info`,
        "_blank"
      );
    } else if (value === "Sair") {
      logout();
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        userInfoListRef.current &&
        !userInfoListRef.current.contains(event.target)
      ) {
        setShowUserInfoList(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [userInfoListRef]);

  useEffect(async () => {
    const userInfo = await KeycloakService.getUserInfo();
    setUsername(userInfo.preferred_username);
  }, [KeycloakService]);

  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List sx={{ padding: "10px" }}>
          {sidenavMenu.map((menu, index) => {
            return (
              <div className="menuSection" key={index}>
                <div className="sideMenuSectionTitleContainer">
                  <span className="sideMenuSectionTitle">
                    {" "}
                    {menu.mainTitle}{" "}
                  </span>
                  <span className="sideMenuSectionTitle">
                    {menu.parentTitle}
                    <i
                      onClick={() => updateSidenavMenuView(menu)}
                      style={{
                        cursor: "pointer",
                        fontSize: "20px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {menu.displayChildren ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowRightIcon />
                      )}
                    </i>
                  </span>
                </div>

                <div className="sideMenuSectionContainer">
                  {menu.displayChildren &&
                    menu.children.map((child, index) => {
                      return (
                        <div
                          className="sideMenuSectionItemContainer"
                          key={index}
                          onClick={() => {
                            updateRoute(child.url);
                          }}
                        >
                          <span className="sideMenuSectionItemName">
                            {child.title}
                          </span>
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
        </List>
      </Drawer>

      <div className={styles.headerContainer}>
        <div className={styles.headerContent}>
          <div className="menumob">
            <span onClick={handleDrawerOpen}>
              <MenuIcon sx={{ fontSize: 40 }} />
            </span>
          </div>
          <Avatar
            src={imgLogo}
            variant="squared"
            alt="App logo"
            sx={{ width: "59px", height: "70px", marginRight: "auto" }}
          />
          <DrawerHeader />
          <div className={styles.userInfo}>
            {/* <p className={styles.entitySwitcherText}>{username}</p> */}
            <Select
              value={"Username"}
              onChange={handleChange}
              sx={{
                "& .MuiSelect-select": {
                  color: "#fff !important",
                },
                "& .MuiSvgIcon-root": {
                  fontSize: "20px !important",
                  fill: "#56ccf2 !important",
                },
              }}
            >
              <MenuItem sx={{ display: "none" }} value={"Username"}>
                {username}
              </MenuItem>
              <MenuItem value={"Perfil"}>Perfil</MenuItem>
              <MenuItem value={"Sair"}>Sair</MenuItem>
            </Select>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminTopBar;
