import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Grid, Tab } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Form } from "@unform/web";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import axios from "../../../services/AxiosService";
import KeycloakService from "../../../services/KeycloakService";
import Checkbox from "../../Form/Checkbox";
import ImagePicker from "../../Form/ImagePicker/ImagePicker";
import FilePicker from "../../Form/FilePicker/FilePicker";
import Input from "../../Form/Input";
import MultiSelect from "../../Form/MultiSelect/MultiSelect";
import Select from "../../Form/Select";
import ComboBox from "../../Form/ComboBox";
import AddOnInput from "../AddOnInput";
import styles from "./CommonFormDialog.module.css";
import b64toBlob from "../../../utils/Base64ToBlob";
import moment from "moment";
import DatePicker from "../DatePicker";
import TextArea from "../TextArea";
import AutoComplete from "../AutoComplete";
import CloseIcon from "@mui/icons-material/Close";
import AutoCompleteOthers from "../AutoCompleteOthers";
import { IlliterateSchema } from "../../../schemas/LiteraryQualificationSchema";

const ModalContainer = styled(Grid)(({ theme }) => ({
  height: "85vh",
  [theme.breakpoints.up("xxl")]: {
    height: "70vh",
  },
  [theme.breakpoints.down("xxl")]: {
    height: "80vh",
  },
  [theme.breakpoints.down("xl")]: {
    height: "85vh",
  },
  [theme.breakpoints.down("lg")]: {
    height: "90vh",
  },
}));

const runEval = (dataStr) => {
  try {
    return eval(dataStr);
  } catch (err) { }
  return null;
};

const GridContainer = styled(Grid)(({ theme }) => ({
  overflow: "auto",
  width: "90%",
  margin: "auto",
}));

// TODO: I NEED TO WORK ON ARRAY FIELD SUPPORT
const CommonFormDialog = ({
  tab1,
  tab2,
  tab3,
  data,
  schemaValidation,
  submitFunction,
  imgUrlBase = null,
  fileUrlBase = null,
  onCancel,
  document,
}) => {
  const uploadInputRef = useRef(null);
  const formRef = useRef();

  const initializeDefaultData = () => {
    return {
      address: "",
      anotherCourse: null,
      otherEducationalInstitution: null,
      educationalInstitution: null,
      course: {
        knowledgeArea: null,
      },
      country: null,
      levelOfEducation: "",
      documents: [],
      completionYear: null,
      image: "",
    };
  };

  const [dataState, setDataState] = useState(initializeDefaultData());
  const [image, setImage] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [file, setFile] = useState("");
  const [fileFile, setFileFile] = useState(null);
  const [modifiedData, setModifiedData] = useState({});
  const [rerender, setRerender] = useState(false);
  const [docData, setDocData] = useState(document?.data);
  const [docsEvents, setDE] = useState([]);
  const [loadedDocs, setLD] = useState([]);

  console.log("My data state: ", tab1);

  useEffect(() => {
    localStorage.removeItem("dialog-typeIdioms");
    localStorage.removeItem("levelOfEducation");
    localStorage.removeItem("literaryQualifications");

    return () => {
      setDataState({});
      setModifiedData({});
      setDocData(document?.data);
      localStorage.removeItem("dialog-typeIdioms");
      localStorage.removeItem("levelOfEducation");
      localStorage.removeItem("literaryQualifications");
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem("dialog-typeIdioms")) {
      const idiomDataParsed = JSON.parse(
        localStorage.getItem("dialog-typeIdioms")
      );

      return setDataState({
        ...data,
        idiom: idiomDataParsed.idiom,
      });
    }
    if (localStorage.getItem("levelOfEducation")) {
      const literaryDataParsed = JSON.parse(
        localStorage.getItem("levelOfEducation")
      );
      console.log(literaryDataParsed);
      return setDataState({
        ...data,
        levelOfEducation: literaryDataParsed.levelOfEducation,
      });
    }
    if (localStorage.getItem("levelOfEducation")) {
      const literaryDataParsed = JSON.parse(
        localStorage.getItem("levelOfEducation")
      );
      console.log(literaryDataParsed);
      return setDataState({
        ...data,
        levelOfEducation: literaryDataParsed.levelOfEducation,
      });
    }
    setDataState(data);
  }, [data, tab1]);

  useEffect(() => {
    if (imgUrlBase) getImage(dataState.id);
    if (fileUrlBase) getFile(dataState.id);
  }, [dataState]);

  const updateDateStateFromForm = () => {
    const dataForm = formRef.current.getData();
    const newDataState = { ...dataState };

    console.log({ dataForm });

    for (const [key, val] of Object.entries(dataForm)) {
      // console.log(key, val)
      newDataState[key] = val;
    }
    const updatedData = { ...modifiedData, ...newDataState };
    setModifiedData(updatedData);

    console.log({ updatedData });
    return updatedData;
  };

  const fixAutoComplete = (tab, dataToBeReturned) => {
    tab.formMeta.forEach((meta) => {
      if (meta.type == "auto-complete") {
        const parentKey = meta.fieldId.split(".")[0];
        if (!dataToBeReturned[parentKey]) return dataToBeReturned;

        const idFromAutoComplete = data[parentKey]?.id;
        const nameFromAutoComplete = eval("dataToBeReturned." + meta.field);

        if (idFromAutoComplete !== "null" && idFromAutoComplete !== undefined) {
          if (dataToBeReturned[parentKey].name === data[parentKey].name) {
            dataToBeReturned[parentKey] = data[parentKey];
            return dataToBeReturned;
          }
        }

        if (nameFromAutoComplete !== "null") {
          if (!isNaN(nameFromAutoComplete)) {
            dataToBeReturned[parentKey] = { id: nameFromAutoComplete };
          }
          try {
            const obj = JSON.parse(nameFromAutoComplete);
            return (dataToBeReturned[parentKey] = obj);
          } catch (e) {
            return dataToBeReturned;
          }
        }

        if (idFromAutoComplete === "null" && nameFromAutoComplete === "null") {
          dataToBeReturned[parentKey] = null;
          return dataToBeReturned;
        }
      }
    });
    return dataToBeReturned;
  };

  const fixAutoCompleteOthers = (tab, dataToBeReturned) => {
    tab.formMeta.forEach((meta) => {
      if (meta.type == "auto-complete-others") {
        const parentKey = meta.fieldId.split(".")[0];
        const otherParentKey = meta.otherFieldId.split(".")[0];

        if (dataToBeReturned[otherParentKey]?.name?.length > 0) {
          if (
            dataToBeReturned[otherParentKey]?.name === meta?.uniquevalue?.name
          ) {
            dataToBeReturned[otherParentKey] = {
              id: meta.uniquevalue.id,
              name: meta.uniquevalue.name,
            };
          }

          dataToBeReturned[parentKey] = null;
          return dataToBeReturned;
        }

        if (dataToBeReturned[parentKey]?.name === meta?.uniquevalue?.name) {
          dataToBeReturned[otherParentKey] = {
            id: meta?.uniquevalue?.id,
            name: meta?.uniquevalue?.name,
          };
          dataToBeReturned[parentKey] = null;
          return dataToBeReturned;
        }

        dataToBeReturned[otherParentKey] = null;

        if (!dataToBeReturned[parentKey]) return dataToBeReturned;

        const idFromAutoComplete = data[parentKey]?.id;
        const nameFromAutoComplete = eval("dataToBeReturned." + meta.field);
        if (idFromAutoComplete !== "null" && idFromAutoComplete !== undefined) {
          if (dataToBeReturned[parentKey].name === data[parentKey].name) {
            dataToBeReturned[parentKey] = data[parentKey];
            return dataToBeReturned;
          }
        }

        if (nameFromAutoComplete !== "null") {
          if (!isNaN(nameFromAutoComplete)) {
            dataToBeReturned[parentKey] = { id: nameFromAutoComplete };
          }
          try {
            const obj = JSON.parse(nameFromAutoComplete);
            return (dataToBeReturned[parentKey] = obj);
          } catch (e) {
            return dataToBeReturned;
          }
        }

        if (idFromAutoComplete === "null" && nameFromAutoComplete === "null") {
          dataToBeReturned[parentKey] = null;
          return dataToBeReturned;
        }
      }
    });
    return dataToBeReturned;
  };

  const fixSelect = (tab, dataToBeReturned) => {
    tab.formMeta.forEach((meta) => {
      if (meta.type == "select" && meta.options) {
        const parentKey = meta.fieldId.split(".")[0];
        if (!dataToBeReturned[parentKey]) return;

        let idFromSelect = null;

        try {
          idFromSelect = eval("dataToBeReturned." + meta.fieldId);
        } catch (err) {
          idFromSelect = null;
        }

        if (
          idFromSelect === "null" ||
          meta.disabled ||
          idFromSelect === "outros"
        ) {
          dataToBeReturned[parentKey] = null;
          return;
        }

        meta.options.forEach((option) => {
          // console.log(meta.fieldId);

          if (option.id && option.id == idFromSelect)
            dataToBeReturned[parentKey] = option;
          else if (option.cod && option.cod == idFromSelect)
            dataToBeReturned[parentKey] = option.cod;
        });
      }
    });
  };

  const fixSelectBeforeReturningDataToSubmitFunction = (dataToBeReturned) => {
    fixSelect(tab1, dataToBeReturned);
    fixAutoComplete(tab1, dataToBeReturned);
    fixAutoCompleteOthers(tab1, dataToBeReturned);
    if (tab2) {
      fixSelect(tab2, dataToBeReturned);
      fixAutoComplete(tab2, dataToBeReturned);
      fixAutoCompleteOthers(tab2, dataToBeReturned);
    }
    if (tab3) {
      fixSelect(tab3, dataToBeReturned);
      fixAutoComplete(tab3, dataToBeReturned);
      fixAutoCompleteOthers(tab3, dataToBeReturned);
    }
    // console.log({dataToBeReturned});
    return dataToBeReturned;
  };

  const fixMultiSelect = (tab, dataToBeReturned) => {
    tab.formMeta.forEach((meta) => {
      if (meta.type == "multiselect" && meta.options) {
        const parentKey = meta.fieldId.split(".")[0];
        if (!dataToBeReturned[parentKey]) return;

        const selectedOptions = [];

        meta.options.forEach((option) => {
          dataToBeReturned[parentKey].forEach((selection, i) => {
            if (isNaN(selection.id)) return;
            if (option.id && option.id == selection.id && selection.checked) {
              selectedOptions.push(option);
            }
          });
        });

        dataToBeReturned[parentKey] = selectedOptions;
      }
    });
  };

  const fixMultiSelectBeforeReturningDataToSubmitFunction = (
    dataToBeReturned
  ) => {
    fixMultiSelect(tab1, dataToBeReturned);
    if (tab2) fixMultiSelect(tab2, dataToBeReturned);
    if (tab3) fixMultiSelect(tab3, dataToBeReturned);
    // console.log({dataToBeReturned});
    return dataToBeReturned;
  };

  const fixNoneInputableField = (tab, dataToBeReturned) => {
    tab.formMeta.forEach((meta) => {
      if (meta.type == "none" && meta.options) {
        const parentKey = meta.field;
        const addingOnKey = meta.addingOn;
        if (!dataToBeReturned[addingOnKey]) return;

        const selectedOptions = [];
        meta.options.forEach((option) => {
          dataToBeReturned[addingOnKey].forEach((selection, i) => {
            if (!isNaN(selection.id)) return;
            const selectionId = selection.id.split(".")[1];
            if (option.id && option.id == selectionId && selection.checked) {
              selectedOptions.push(option);
            }
          });
        });
        dataToBeReturned[parentKey] = selectedOptions;
      }
    });
  };

  const fixNoneInputableFieldBeforeReturningDataToSubmitFunction = (
    dataToBeReturned
  ) => {
    fixNoneInputableField(tab1, dataToBeReturned);
    if (tab2) fixNoneInputableField(tab2, dataToBeReturned);
    if (tab3) fixNoneInputableField(tab3, dataToBeReturned);
    // console.log({dataToBeReturned});
    return dataToBeReturned;
  };

  const fixDate = (tab, dataToBeReturned) => {
    tab.formMeta.forEach((meta) => {
      if (meta.type == "date") {
        const parentKey = meta.field;

        if (!dataToBeReturned[parentKey]) return dataToBeReturned;

        const unFormattedDate = moment(dataToBeReturned[parentKey]).format(
          //"DD-MM-yyyy hh:mm:ss"
          "yyyy-MM-DD"
        );

        console.log("unFormattedDate", unFormattedDate);
        return (dataToBeReturned[parentKey] = unFormattedDate);
      }
    });
    return dataToBeReturned;
  };

  const fixDateBeforeReturningDataToSubmitFunction = (dataToBeReturned) => {
    fixDate(tab1, dataToBeReturned);
    if (tab2) fixDate(tab2, dataToBeReturned);
    if (tab3) fixDate(tab3, dataToBeReturned);
    return dataToBeReturned;
  };

  const fixFile = (tab, dataToBeReturned) => {
    tab.formMeta.forEach((meta) => {
      if (meta.type === "file") {
        const parentKey = meta.field;

        if (!dataToBeReturned[parentKey]) return dataToBeReturned;

        if (fileFile) {
          return (dataToBeReturned[parentKey] = fileFile);
        }
      }
    });
    return dataToBeReturned;
  };

  const fixFileBeforeReturningDataToSubmitFunction = (dataToBeReturned) => {
    fixFile(tab1, dataToBeReturned);
    if (tab2) fixFile(tab2, dataToBeReturned);
    if (tab3) fixFile(tab3, dataToBeReturned);
    return dataToBeReturned;
  };

  const handleSubmit = (formData, { reset }) => {
    console.log("formData", formData);
    var d = updateDateStateFromForm();
    d = fixSelectBeforeReturningDataToSubmitFunction(d);
    d = fixNoneInputableFieldBeforeReturningDataToSubmitFunction(d);
    d = fixMultiSelectBeforeReturningDataToSubmitFunction(d);
    d = fixDateBeforeReturningDataToSubmitFunction(d);
    d = fixFileBeforeReturningDataToSubmitFunction(d);

    const submitData = {
      ...d,
      image: imageFile,
      id: dataState?.id,
    };

    console.log("submitData", submitData);

    const dynamicSchema =
      selectedValue === "ILLITERATE" ? IlliterateSchema : schemaValidation;

    dynamicSchema
      .validate(submitData, {
        abortEarly: false,
      })
      .then((submitData) => {
        console.log("schema is valid", submitData);
        localStorage.removeItem("dialog-typeIdioms");
        localStorage.removeItem("levelOfEducation");
        localStorage.removeItem("literaryQualifications");
        submitFunction(submitData, (id = document?.id) => {
          docsEvents.map((event) =>
            document?.action(event, id, document?.name)
          );
        });
        reset();
        setDataState({});
        formRef?.current?.setErrors({});
      })
      .catch((error) => {
        console.log("schema is not valid", error);
        if (error instanceof Yup.ValidationError) {
          const errorMessages = {};
          error.inner.forEach((error) => {
            console.log(error.path, error.message, "error");
            errorMessages[error.path] = error.message;
          });
          formRef.current.setErrors(errorMessages);
        }
      });
  };

  const getImage = (id) => {
    if (!id) return;
    axios
      .get(`/${imgUrlBase}/${id}/pictures-profiles/files`, {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
        responseType: "arraybuffer",
      })
      .then((response) => {
        if (response.data) {
          let base64ImageString = Buffer.from(response.data, "binary").toString(
            "base64"
          );
          let srcValue = "data:image/png;base64," + base64ImageString;
          setImage(srcValue);
          //
          var file = b64toBlob(srcValue);
          setImageFile(file);
        }
      });
  };

  const getFile = (id) => {
    return;
    if (!id) return;
    axios
      .get(`/${fileUrlBase}/${id}`, {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
        responseType: "arraybuffer",
      })
      .then((response) => {
        if (response.data) {
          let base64ImageString = Buffer.from(response.data, "binary").toString(
            "base64"
          );
          let srcValue = "data:application/pdf;base64," + base64ImageString;
          setFile(srcValue);
          //
          var file = b64toBlob(srcValue);
          setFileFile(file);
        }
      });
  };

  const [value, setValue] = useState("1");
  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // console.log(newValue)
    updateDateStateFromForm();
  };

  const onImportButtonClick = () => {
    setLD(loadedDocs);
    uploadInputRef.current.click();
    setRerender(!rerender);
    console.log(uploadInputRef.current.value);
  };

  const handleDelete = (id, i, type) => {
    document.deleteDoc(id, document?.update);
    if (type == "loaded")
      setLD((data) => {
        data.splice(i, 1);
        return data;
      });
    else
      setDocData((data) => {
        data.splice(i, 1);
        return data;
      });
    setRerender(!rerender);
  };

  const onSelectChange = (value, meta, tab) => {
    if (meta.field === "idiom.type") {
      localStorage.setItem(
        "dialog-typeIdioms",
        JSON.stringify(formRef.current.getData())
      );
    }
    if (meta.field === "levelOfEducation") {
      localStorage.setItem(
        "levelOfEducation",
        JSON.stringify(formRef.current.getData())
      );
    }

    // Check if the value of the select element is "ILLITERATE"
    if (value === "ILLITERATE") {
      // Hide all other input fields except for the select element
      tab.formMeta.forEach((field) => {
        if (field.type !== "select") {
          field.hide = true;
        }
      });
      setSelectedValue("ILLITERATE");
    } else {
      // Show all input fields if the select element value is not "ILLITERATE"
      tab.formMeta.forEach((field) => {
        field.hide = false;
      });
      setSelectedValue("LITERATE");
    }

    return meta.onChange(value, fixSelect(tab, formRef.current.getData()));
  };

  const addDE = (e) => {
    if (e.target.files?.length > 0) {
      setDE((de) => {
        de.push(e);
        return de;
      });
      setLD((ld) => {
        for (let index = 0; index < e.target.files.length; index++) {
          ld.push(e.target.files[index]);
        }
        return ld;
      });
    }
    setLD(loadedDocs);
    setRerender(!rerender);
  };

  const Doc = ({ index, doc, type }) => (
    <div
      key={index}
      style={{
        display: "flex",
        justifyContent: "space-between",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "13px",
        lineHeight: "20px",
        /* identical to box height */
        color: "#838383",
        padding: "1em 0 1em 0",
        marginRight: "2em",
      }}
    >
      <div style={{ cursor: "pointer" }} onClick={() => document.view(doc.id)}>
        {doc?.name}
      </div>
      {/* <span style={{flexGrow:"2"}}></span>  */}
      <CloseIcon
        sx={{ cursor: "pointer" }}
        onClick={() => handleDelete(doc?.id, index, type)}
      />
    </div>
  );

  const renderForm = (meta, i, tab) => {
    return (
      <div className={styles.fieldGroup} key={i}>
        {!["image", "checkbox", "file"].includes(meta.type) && (
          <div className={styles.value}>
            {![
              "select",
              "selectSearchable",
              "multiselect",
              "checkbox",
              "addableText",
              "none",
              "date",
              "textarea",
              "file",
              "auto-complete",
              "auto-complete-others",
            ].includes(meta.type) &&
              !meta?.hide && (
                <Input
                  name={meta.field}
                  key={i}
                  type={meta.type}
                  label={meta.label}
                  definedvalue={meta?.value}
                  style={{ width: "88.655%" }}
                  disabled={meta.disabled}
                  isMandatory={meta.isMandatory}
                />
              )}
            {meta.type == "auto-complete" && !meta?.hide && (
              <AutoComplete
                key={i}
                name={meta.field}
                othersName={meta.otherField}
                type={meta.type}
                label={meta.label}
                optionscode={meta.optionscode}
                alloptionscode={meta.alloptionscode}
                disabled={meta.disabled}
                onChange={(item) =>
                  meta?.onchange
                    ? meta.onchange(item)
                    : fixAutoComplete(tab, formRef.current.getData())
                }
                isMandatory={meta.isMandatory}
              />
            )}
            {meta.type == "auto-complete-others" && !meta?.hide && (
              <AutoCompleteOthers
                key={i}
                name={meta.field}
                othersName={meta.otherField}
                type={meta.type}
                label={meta.label}
                optionscode={meta.optionscode}
                alloptionscode={meta.alloptionscode}
                disabled={meta.disabled}
                uniquevalue={meta.uniquevalue}
                onChange={(item) =>
                  meta?.onchange
                    ? meta.onchange(item)
                    : fixAutoComplete(tab, formRef.current.getData())
                }
                returnOther={meta.returnOther}
                isMandatory={meta.isMandatory}
              />
            )}
            {meta.type == "date" && (
              <DatePicker
                name={meta.field}
                key={i}
                type={meta.type}
                label={meta.label}
                withHours={meta.withHours}
                dateFormat={meta.dateFormat}
                style={{
                  width: "94.4%",
                }}
                disabled={meta.disabled}
                isMandatory={meta.isMandatory}
              />
            )}
            {meta.type == "addableText" && (
              <AddOnInput
                name={meta.field}
                key={i}
                type={meta.type}
                label={meta.label}
                onAdd={(value) => (meta.onAdd ? meta.onAdd(value) : null)}
                disabled={meta.disabled}
                isMandatory={meta.isMandatory}
              />
            )}
            {meta.type == "select" && (
              <Select
                name={meta.fieldId.endsWith(".id") ? meta.fieldId : meta.field}
                key={i}
                options={meta.options}
                subType={meta.hasSubType}
                onChange={(e) =>
                  meta.onChange
                    ? onSelectChange(e.target.value, meta, tab)
                    : null
                }
                label={meta.label}
                style={{
                  background: "white",
                  width: "96%",
                }}
                disabled={meta.disabled}
                hide={meta.hide}
                isMandatory={meta.isMandatory}
              />
            )}
            {meta.type == "selectSearchable" && (
              <ComboBox
                name={meta.field}
                key={i}
                options={meta.options}
                searchEntity={meta.searchEntity}
                onChange={(e) =>
                  meta?.onChange ? meta?.onChange(e.target.value) : null
                }
                label={meta.label}
                style={{
                  background: "white",
                  width: "96%",
                }}
                disabled={meta.disabled}
                isMandatory={meta.isMandatory}
              />
            )}
            {meta.type == "multiselect" && (
              <MultiSelect
                name={meta.fieldId}
                key={i}
                options={meta.options}
                label={meta.label}
                selectedValues={dataState[meta.field.split(".")[0]]}
                disabled={meta.disabled}
                isMandatory={meta.isMandatory}
              />
            )}
          </div>
        )}
        {meta.type == "checkbox" && (
          <div className={styles.value}>
            <Checkbox
              name={meta.field}
              key={i}
              label={meta.label}
              disabled={meta.disabled}
              isMandatory={meta.isMandatory}
            />
          </div>
        )}
        {meta.type == "textarea" && (
          <TextArea
            name={meta.field}
            key={i}
            type={meta.type}
            label={meta.label}
            row={meta.row}
            style={{ width: "88.655%" }}
            disabled={meta.disabled}
            isMandatory={meta.isMandatory}
          />
        )}
        {meta.type == "image" && (
          <div
            className={meta.class == "mask-circle" ? styles.maskCircle : null}
          >
            <div className={styles.label}>{meta.label}</div>
            <div className={styles.darkOverlay}>Alterar</div>
            <ImagePicker
              name={meta.field}
              key={i}
              defaultValue={image}
              onImageChange={(imageFile, image) => {
                console.log({ imageFile, image });
                setImageFile(imageFile);
                setImage(image);
              }}
              disabled={meta.disabled}
            />
          </div>
        )}
        {meta.type == "file" && (
          <div className={null}>
            <div className={styles.label}>{meta.label}</div>
            <div className={styles.darkOverlay}>Alterar</div>
            <FilePicker
              name={meta.field}
              key={i}
              defaultValue={file}
              onFileChange={(fileFile, file) => {
                console.log({ fileFile, file });
                setFileFile(fileFile);
                setFile(file);
              }}
              disabled={meta.disabled}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <ModalContainer>
      <Form ref={formRef} onSubmit={handleSubmit} initialData={dataState}>
        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TabList
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              centered
              style={{ marginLeft: "auto" }}
            >
              <Tab label={tab1.title} value="1" />
              {tab2 && <Tab label={tab2.title} value="2" />}
              {tab3 && <Tab label={tab3.title} value="3" />}
            </TabList>
            <Box ml="auto" mr="16px">
              <CloseIcon style={{ cursor: "pointer" }} onClick={onCancel} />
            </Box>
          </Box>
          <TabPanel value="1">
            <GridContainer>
              {tab1?.formMeta?.map((meta, i) => {
                return renderForm(meta, i, tab1);
              })}
            </GridContainer>
          </TabPanel>
          {tab2 && (
            <TabPanel value="2">
              <GridContainer>
                {tab2.formMeta.map((meta, i) => {
                  return renderForm(meta, i, tab1);
                })}
              </GridContainer>
            </TabPanel>
          )}
          {tab3 && (
            <TabPanel value="3">
              <GridContainer>
                {tab3.formMeta.map((meta, i) => {
                  return renderForm(meta, i, tab3);
                })}
              </GridContainer>
            </TabPanel>
          )}
          <Grid px={8}>
            {docData?.length > 0 ? (
              <div>
                {docData?.map((a, i) => (
                  <Doc index={i} doc={a} />
                ))}
                {loadedDocs?.map((a, i) => (
                  <Doc index={i} doc={a} type="loaded" />
                ))}
              </div>
            ) : (
              <div>
                {loadedDocs?.map((a, i) => (
                  <Doc index={i} doc={a} type="loaded" />
                ))}
              </div>
            )}
          </Grid>
        </TabContext>
        {(document?.name === "literaryQualifications" ||
          document?.name === "trainings") &&
          selectedValue !== "ILLITERATE" ? (
          <Box p={5}>
            <Button
              size="small"
              className={styles.UploadButton}
              variant="outlined"
              onClick={onImportButtonClick}
            >
              {" "}
              Carregar Comprovativo
            </Button>
            <input
              type="file"
              hidden
              multiple
              ref={uploadInputRef}
              id="upload"
              name="upload"
              accept=".pdf"
              onChange={(event) => addDE(event)}
            />
          </Box>
        ) : (
          <></>
        )}
        <Box sx={{ borderTop: 1, borderColor: "divider" }}>
          <div className={styles.buttonContainer}>
            <Button
              variant="outlined"
              type="cancel"
              onClick={(e) => {
                localStorage.removeItem("dialog-typeIdioms");
                localStorage.removeItem("levelOfEducation");
                localStorage.removeItem("literaryQualifications");
                onCancel();
              }}
            >
              Cancelar
            </Button>
            <Button type="submit" variant="contained">
              Guardar
            </Button>
          </div>
        </Box>
      </Form>
    </ModalContainer>
  );
};

export default CommonFormDialog;
