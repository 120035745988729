import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmployeeRequest,
  getInstitutionRequest,
} from "../../store/reducers/users/actionCreators";
import AdminTopMenu from "../../admin/components/AdminTopBar/AdminTopBar";
import TopMenu from "../../components/Navigation/TopMenu/TopMenu";
import KeycloakService from "../../services/KeycloakService";
import PrintIcon from "@mui/icons-material/Print";
import GppGoodIcon from "@mui/icons-material/GppGood";
import axios from "../../services/AxiosService";

function Header() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const activePage = location.pathname.split("/").reverse()[0];
  // const [load, setLoad] = useState(false)

  const employeeData = useSelector((state) => state.usersReducer.employee);

  useEffect(async () => {
    if (KeycloakService.hasRole(["user-quadro"])) {
      const { sub } = await KeycloakService.getUserInfo();
      dispatch(getEmployeeRequest({ sub }));
    }
  }, []);

  const printCurriculum = async () => {
    try {
      await axios
        .get(`/documents/cv/${employeeData.id}`, {
          responseType: "blob",
          headers: { Authorization: `Bearer ${KeycloakService.getToken()}` },
        })
        .then((response) => {
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(async () => {
    if (!employeeData.id) return;
    setMenuButtonsQuadros([
      {
        event: printCurriculum,
        label: "Imprimir CV",
        icon: <PrintIcon />,
      },
      {
        event: goToProfileEdit,
        label: "Alterar Pin",
        icon: <GppGoodIcon />,
      },
      {
        event: () =>
          history.push(`/quadros/alterar_telefone/${employeeData.id}`),
        label: "Alterar Telefone",
        icon: <GppGoodIcon />,
      },
    ]);
  }, [employeeData]);

  const [menuItemsEntidades, setMenuItemsEntidades] = useState([
    {
      link: "/entidades/dashboard",
      label: "Dashboard",
      urlActive: "dashboard",
    },
    {
      link: "/entidades/account",
      label: "Entidade",
      urlActive: "account",
    },
    {
      link: "/entidades/quadros",
      label: "Quadros",
      urlActive: "quadros",
    },
    // {
    //   link: "/entidades/noticias",
    //   label: "Notícias",
    //   urlActive: "noticias",
    // },
    // {
    //   link: "/entidades/empregos",
    //   label: "Ofertas de Emprego",
    //   urlActive: "empregos",
    // },
    // {
    //   link: "/entidades/eventos",
    //   label: "Eventos",
    //   urlActive: "eventos",
    // },
  ]);

  const [menuItemsQuadros, setMenuItemsQuadros] = useState([
    {
      link: "/quadros/dashboard",
      label: "Dashboard",
      urlActive: "dashboard",
    },
    {
      link: "/quadros/account",
      label: "Dados Cadastrais",
      urlActive: "account",
    },
    // {
    //   link: "/quadros/noticias",
    //   label: "Notícias",
    //   urlActive: "noticias",
    // },
    // {
    //   link: "/quadros/empregos",
    //   label: "Ofertas de Emprego",
    //   urlActive: "empregos",
    // },
    // {
    //   link: "/quadros/eventos",
    //   label: "Eventos",
    //   urlActive: "eventos",
    // },
  ]);

  const goToProfileEdit = () => {
    history.push("/quadros/alterar_pin");
  };

  const [menuButtonsQuadros, setMenuButtonsQuadros] = useState([
    {
      event: printCurriculum,
      label: "Imprimir CV",
      icon: <PrintIcon />,
    },
    {
      event: goToProfileEdit,
      label: "Alterar Pin",
      icon: <GppGoodIcon />,
    },
    {
      event: () => history.push(`/quadros/alterar_telefone/${employeeData.id}`),
      label: "Alterar Telefone",
      icon: <GppGoodIcon />,
    },
  ]);

  const [menuButtonsEntidades, setMenuButtonsEntidades] = useState([
    // {
    //   event:  printCurriculum,
    //   label: "Ver Curriculum",
    //   icon: <PrintIcon/>
    // },
    // {
    //   event:  goToProfileEdit,
    //   label: "Editar Pin",
    //   icon: <GppGoodIcon/>
    // },
  ]);

  if (activePage === "login" || activePage === "cadastrar") {
    return "";
  } else {
    if (KeycloakService.hasRole(["user-quadro"])) {
      return (
        <TopMenu
          menuItems={menuItemsQuadros}
          menuButtons={menuButtonsQuadros}
        />
      );
    } else if (KeycloakService.hasRole(["user-entidade"])) {
      return (
        <TopMenu
          menuItems={menuItemsEntidades}
          menuButtons={menuButtonsEntidades}
        />
      );
    } else if (KeycloakService.hasRole(["admin"])) {
      return <AdminTopMenu />;
    } else {
      return <TopMenu />;
    }
  }
}

export default Header;
