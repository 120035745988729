import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const ImageContainer = styled.div`
  // width: 320px;
  width: 140px;
  height: 128px;
  position: relative;
  overflow: hidden;

  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 0px;

  img:nth-child(1) {
    height: 100%;
    // position: relative;
    // left: -25%;

  }

  img:nth-child(2) {
    position: absolute;
    bottom: 0px;
    right: 0px;
    background-color: black;
    padding: 0.4rem;
    cursor: pointer;
    height: 15px;
  }

  input {
    display: none;
  }
`;
