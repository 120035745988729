import { Box, Grid, useTheme } from "@mui/material";
import React, { useState } from "react";
import ProfilePicture from "../../../../components/Card/PersonalDataCard/ProfilePicture";
import { mapCodToEmployeeDocTypes } from "../../../../helpers/EmployeeDocType";
import { mapCodToMaritalStatus } from "../../../../helpers/MaritalStatus";
import { mapCodToGenders } from "../../../../utils/genders";
import axios from "./../../../../services/AxiosService";
import KeycloakService from "./../../../../services/KeycloakService";
import QRCode from "react-qr-code";

const PersonalDataCard = ({
  entityId,
  entity,
  name,
  column1,
  column2,
  data,
  buttons,
  readOnly,
  children
}) => {
  const [loader, setLoader] = useState(false)
  const formatDate = (date) => {
    if (!date) return "";
    const noHoursDate = date.split(" ")[0];

    const year = noHoursDate.split("-")[0];
    const month = noHoursDate.split("-")[1];
    const day = noHoursDate.split("-")[2];

    return `${day}-${month}-${year}`;
  };

  const runEval = (dataStr, meta) => {
    try {
      if (dataStr.includes("genre")) {
        return mapCodToGenders(eval(dataStr));
      }

      if (dataStr.includes("maritalStatus")) {
        return mapCodToMaritalStatus(eval(dataStr));
      }

      if (dataStr.includes("foreignNationalityIdCard")) {
        return mapCodToEmployeeDocTypes(eval(dataStr));
      }

      if (meta.type === "date" && dataStr) {
        return formatDate(eval(dataStr));
      }

      return eval(dataStr);
    } catch (err) { }
    return null;
  };
  const theme = useTheme();

  const printCurriculum = async () => {
    setLoader(true)
    try {
      await axios.get(`/documents/cv/${data?.id}`, {
        responseType: "blob",
        headers: { Authorization: `Bearer ${KeycloakService.getToken()}`, },
      }).then(response => {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        setLoader(false)
        window.open(fileURL);
      }).catch(error => {
        setLoader(false)
        console.log(error);
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box>
      <Grid
        direction="row"
        container
        // className={`${styles.gridContainer} ${styles.card}`}
        justify-content="center"
        style={{ backgroundColor: "#fff", height: "100%", width: "100%" }}
      >
        <Grid md={4.5} sx={{ padding: "30px" }} item align="start" style={{ backgroundColor: "#2B404E", color: "#fff", }}>
          <Box sx={{ marginLeft: "48px" }}>
            <ProfilePicture entity={entity} entityId={entityId} />
          </Box>

          <Grid item>
            <h2 style={{
              borderBottom: "1px solid #fff",
              paddingBottom: "4px",
              fontSize: "20px",
              fontWeight: "normal"
            }}>
              Sobre mim
            </h2>
            {column1.map((meta, i) => {
              return (
                <Grid container direction="row" justifyContent="space-between" alignItems="center" mb="5px" key={i}>
                  <Grid
                    item
                    md={6}
                    sx={{
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "9px",
                      lineHeight: "20px",
                      color: "#f2f2f2",
                    }}
                  >
                    {meta.label}:
                  </Grid>
                  <Grid
                    item
                    md={6}
                    color="#fff"
                    border={
                      runEval("data." + [meta.field], meta) !== null && !readOnly
                        ? "1px solid #dfdfdf"
                        : ""
                    }
                  >
                    {runEval("data." + [meta.field], meta)}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <Grid item style={{ marginTop: "42px" }}>
            <h2 style={{
              borderBottom: "1px solid #fff",
              paddingBottom: "4px",
              fontSize: "20px",
              fontWeight: "normal"
            }}>
              Contactos
            </h2>
            {column2.map((meta, i) => {
              return (
                <Grid container direction="row" justifyContent="space-between" alignItems="center" mb="5px" key={i}>
                  <Grid
                    item
                    md={6}
                    sx={{
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "9px",
                      lineHeight: "20px",
                      color: "#f2f2f2",
                    }}
                  >
                    {meta.label}:
                  </Grid>
                  <Grid
                    item
                    md={6}
                    color="#fff"
                    border={
                      runEval("data." + [meta.field], meta) !== null && !readOnly
                        ? "1px solid #dfdfdf"
                        : ""
                    }
                  >
                    {runEval("data." + [meta.field], meta)}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>

        </Grid>

        <Grid md={6.5} item spacing={2} style={{ backgroundColor: "#2B404E" }}>
          <Box sx={{ borderRadius: "0 0 56px 130px", backgroundColor: "#fff", padding: "20px 20px", width: "100%" }}>

          </Box>
          <Box sx={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }} style={{ backgroundColor: "#2B404E", color: "#fff", width: "100%", padding: "4px 24px 28px", marginTop: "-4px", borderRadius: "0px 48px 48px 0px", }}>
            <Box sx={{ flexGrow: 1 }}>
              <h1 style={{
                fontSize: "32px",
                fontWeight: "bold"
              }}>{name}</h1>
              <span onClick={printCurriculum}
                style={{
                  cursor: "pointer",
                  backgroundColor: "transparent",
                  border: "1px solid #5EBEE1",
                  padding: "8px 16px",
                  borderRadius: "4px",
                  color: "#5EBEE1",
                }}> {loader ? "Aguarde..." : "Imprimir CV"}
              </span>
            </Box>
            <Box style={{ backgroundColor: "#fff", padding: "2px", marginTop: "25px", borderRadius: "4px", width: "100px", display: "flex", justifyContent: 'flex-end' }}>
              <QRCode
                style={{ height: "100px" }}
                value={`${process.env.REACT_APP_API_URL}/documents/cv/${data?.id}`}
              />
            </Box>
          </Box>
          <Box sx={{ borderRadius: "48px 0 0 0", backgroundColor: "#fff", paddingBottom: "32px" }}>
            {children}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PersonalDataCard;
