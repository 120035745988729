import React, { useRef, useState } from "react";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { Box, Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import DatePicker from "../../../components/Form/DatePicker";
import Select from "../../../components/Form/Select";
import KeycloakService from "../../../services/KeycloakService";
import axios from "../../../services/AxiosService";
import AdminTitleHeader from "../../components/AdminTitleHeader/AdminTitleHeader";
import useAlocacaoQuadros from "../../hooks/useAlocacaoQuadros";
import { postAssignEmployeeRequest } from "../../../store/reducers/users/actionCreators";
import { useDispatch } from "react-redux";
import CreateAllocationSchema from "../../../schemas/CreateAllocationSchema";
import moment from "moment";
import styles from "./Quadro.module.css";

const AlocarQuadro = ({ match }) => {
  const formRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();

  const [units, setUnits] = useState([]);

  const { fieldsMetaData, fixSelect, currentEmployee } = useAlocacaoQuadros(match.params);

  const fixDate = (formMeta, dataToBeReturned) => {
    formMeta.forEach((meta) => {
      if (meta.type == "date") {
        const parentKey = meta.field;

        if (!dataToBeReturned[parentKey]) return dataToBeReturned;

        const unFormattedDate = moment(dataToBeReturned[parentKey]).format(
          "yyyy-MM-DD"
        );

        console.log("unFormattedDate", unFormattedDate);
        return (dataToBeReturned[parentKey] = unFormattedDate);
      }
    });
    return dataToBeReturned;
  };

  const handleEmployeeAllocationSubmit = (formData, { reset }) => {
    const withFixedValues = fixDate(fieldsMetaData, formData);

    CreateAllocationSchema.validate(
      { ...withFixedValues, employeeId: match.params.id },
      {
        abortEarly: false,
      }
    )
      .then((submitData) => {
        dispatch(
          postAssignEmployeeRequest({
            employeeId: submitData.employeeId,
            positionId: submitData.position.name,
            unitId: submitData.unit.name,
            startedOn: submitData.startedOn,
            showNotification: true,
            onSuccessMessage: "Quadro alocado com sucesso.",
          })
        );
        formRef.current.setErrors({});
      })
      .catch((error) => {
        console.log("schema is not valid:");
        if (error instanceof Yup.ValidationError) {
          const errorMessages = {};
          error.inner.forEach((error) => {
            console.log(error.message);
            errorMessages[error.path] = error.message;
          });
          formRef.current.setErrors(errorMessages);
        }
      });
  };

  const onInstitutionChange = (institutionId) => {
    console.log(institutionId);
    axios
      .get(`/institutions/${institutionId}/organizational-structure`, {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      })
      .then((response) => {
        if (response.data) {
          console.log(response.data);
          setUnits(response.data);
        }
      });
  };

  const unitMetadata = {
    field: "unit.name",
    label: "Unidade Organica",
    type: "select",
    options: units,
    fieldId: "unit.id",
  };

  return (
    <div className={styles.alocationcontainer}>
      <AdminTitleHeader title={`Alocar Quadro`} />
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: "2rem 1.5rem",
          display: "flex",
        }}
      >
        <Box
          sx={{
            flexBasis: "100%",
            borderRight: "1px solid #cecece",
          }}
        >
          <Form
            ref={formRef}
            onSubmit={handleEmployeeAllocationSubmit}
            initialData={currentEmployee}
            style={{ borderRadius: "unset", boxShadow: "none", padding: "0" }}
          >
            <Box sx={{ display: "flex", flexFlow: "row wrap", gap: "1rem" }}>
              <Box sx={{ width: { xs: "100%", sm: "50%", md: "45%" } }}>
                <Select
                  name={fieldsMetaData[3].field}
                  options={fieldsMetaData[3].options}
                  label={fieldsMetaData[3].label}
                  disabled={fieldsMetaData[3].disabled}
                />
              </Box>
              <Box sx={{ width: { xs: "100%", sm: "50%", md: "45%" } }}>
                <DatePicker
                  name={fieldsMetaData[12].field}
                  type={fieldsMetaData[12].type}
                  label={fieldsMetaData[12].label}
                  placeholder={fieldsMetaData[12].placeholder}
                  dateFormat={fieldsMetaData[12].dateFormat}
                />
              </Box>
              <Box sx={{ width: { xs: "100%", sm: "50%", md: "45%" } }}>
                <Select
                  name={fieldsMetaData[11].field}
                  options={fieldsMetaData[11].options}
                  label={fieldsMetaData[11].label}
                  disabled={fieldsMetaData[11].disabled}
                  onChange={(e) => {
                    onInstitutionChange(e.target.value);
                  }}
                />
              </Box>
              <Box sx={{ width: { xs: "100%", sm: "50%", md: "45%" } }}>
                <Select
                  name={unitMetadata.field}
                  options={unitMetadata.options}
                  label={unitMetadata.label}
                  disabled={unitMetadata.disabled}
                />
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  marginTop: 10,
                  gap: "1rem",
                }}
              >
                <>
                  <Button
                    variant="outlined"
                    type="cancel"
                    onClick={(e) => {
                      e.preventDefault();
                      history.goBack();
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button type="submit" variant="contained">
                    Guardar
                  </Button>
                </>
              </Box>
            </Box>
          </Form>
        </Box>
      </Box>
    </div>
  );
};

export default AlocarQuadro;
