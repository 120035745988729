import { useField } from "@unform/core";
import React, { useEffect, useRef, useState } from "react";
import { Label, LabelContainer } from "./Label";
import { ErrorMessage } from "./ErrorMessage";
import KeycloakService from "../../services/KeycloakService";
import axios from "../../services/AxiosService";
import { Autocomplete, styled, TextField } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const formatItems = (items) => {
  return items.map((item) => {
    return { id: item.id, label: item.name };
  });
};

const AutoComplete = styled(Autocomplete)(() => ({
  width: "95.85%",
  [`input`]: {
    border: "1px solid transparent !important",
    padding: "0px 16px !important",
  },
  [`& .MuiAutocomplete-inputRoot`]: {
    height: "44px",
  },
  [`& .MuiSvgIcon-root`]: {
    fontSize: "24px",
    color: "#000",
  },
}));

export default function ComboBox({
  name,
  label,
  isMandatory,
  onChange = null,
  ...rest
}) {
  const comboBoxRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [items, setItems] = useState(rest.options);
  const [options, setOptions] = useState(formatItems(rest.options));

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: comboBoxRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    setItems(rest.options);
    setOptions(formatItems(rest.options));
  }, [rest.options]);

  useEffect(() => {
    comboBoxRef.current.value = rest.defaultValue
      ? rest.defaultValue.id
      : defaultValue;
  }, [defaultValue, rest.defaultValue, rest.options]);

  const searchEntity = (query) => {
    if (query.length === 0) return setOptions(rest.options);
    axios
      .get(`/${rest.searchEntity}/search/${query}`, {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      })
      .then((response) => {
        if (response.data) {
          setItems(response.data.content);
          return setOptions(
            response.data.content.map((country) => {
              return { id: country.id, label: country.name };
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <LabelContainer>
        <Label htmlFor={fieldName}>{label}</Label>
        {isMandatory && <span style={{ color: "red" }}>*</span>}
      </LabelContainer>
      <AutoComplete
        ref={comboBoxRef}
        disablePortal
        autoSelect
        id={fieldName}
        options={options}
        onChange={(e, value) =>
          (comboBoxRef.current.value = items.filter(
            (obj) => obj.id === value.id
          )[0])
        }
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(e) => searchEntity(e.target.value)}
          />
        )}
        popupIcon={<KeyboardArrowDownIcon />}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
}
