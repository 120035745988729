export const genders = [
  { name: "MASCULINO" },
  { name: "FEMININO" },
  { name: "OUTRO" },
];

export const mapCodToGenders = (cod) => {
  switch (cod) {
    case "FEMALE":
      return "Feminino";
    case "MALE":
      return "Masculino";
    default:
      return "";
  }
};
