const CeaseMotives = [
  { name: "AVALIAÇÃO DE DESEMPENHO NEGATIVA DURANTE O PERÍODO DE PROBAÇÃO", cod: "NEGATIVE_PERFORMANCE_EVALUATION_DURING_PROBATION_PERIOD" },
  { name: "EXONERAÇÃO", cod: "EXONERATED" },
  { name: "DEMISSÃO", cod: "FIRED" },
  { name: "TERMO DO PRAZO DO CONTRATO", cod: "END_OF_CONTRACT" },
  { name: "MORTE", cod: "DEATH" },
  { name: "REALIZAÇÃO DO SEU OBJECTO", cod: "FULFILLMENT_OF_CONTRACT_OBJECT" },
  { name: "REFORMA", cod: "RETIREMENT" },
  { name: "DENÚNCIA", cod: "DENUNCIATION" },
  { name: "ACORDO MÚTUO", cod: "MUTUAL_AGREEMENT" },
  { name: "RESCISÃO", cod: "RECOVERED" }


  // { name: "Resignação", cod: "RESIGNATION" },
  // { name: "Reforma", cod: "RETIREMENT" },
  // { name: "Despedido", cod: "FIRED" },
  // { name: "Fim de contracto", cod: "END_OF_CONTRACT" },
  // { name: "Transferido", cod: "TRANSFERRED" },
  // { name: "Promoção", cod: "PROMOTION" },
  // { name: "Extinção da unidade", cod: "UNIT_EXTINCTION" },

  
  // { name: "Outro", cod: "OTHER" },
];

export const mapCodToStrands = (cod) => {
  switch (cod) {
    case "DEATH":
      return "MORTE";

    case "MUTUAL_AGREEMENT":
      return "ACORDO MÚTUO";

    case "FIRED":
      return "DEMISSÃO";

    case "EXONERATED":
      return "EXONERAÇÃO";

    case "NEGATIVE_PERFORMANCE_EVALUATION_DURING_PROBATION_PERIOD":
      return "AVALIAÇÃO DE DESEMPENHO NEGATIVA DURANTE O PERÍODO DE PROBAÇÃO";

   // case "DISCHARGE_DUE_TO_RETIREMENT_REASONS":
     // return "Desvinculação por motivos de aposentação";

    case "END_OF_CONTRACT":
      return "TERMO DO PRAZO DO CONTRATO";

    case "FULFILLMENT_OF_CONTRACT_OBJECT":
      return "REALIZAÇÃO DO SEU OBJECTO";

    case "DENUNCIATION":
      return "DENÚNCIA";

    case "RECOVERED":
      return "RESCISÃO";
    default:
      return "";
    // case "RESIGNATION":
    //   return "Resignação";
     case "RETIREMENT":
      return "REFORMA";
    // case "FIRED ":
    //   return "Despedido";
    // case "END_OF_CONTRACT ":
    //   return "Fim de contracto";
    // case "TRANSFERRED ":
    //   return "Transferido";
    // case "PROMOTION ":
    //   return "Promoção";
    // case "UNIT_EXTINCTION ":
    //   return "Extinção da unidade";
    // case "OTHER ":
    //   return "Outro";
    // default:
    //   return "";
  }
};

export default CeaseMotives;
