import React from 'react'
import "./style.css";

export default function Step(props) {
  return (
    <div className="p-4 flex justify-center">
      <div className="gap-2 mt-12 flex-col z-20 flex justify-between items-center">
        <div
          className={`w-10 h-10 shadow-md rounded-full flex justify-center items-center font-bold ${
            props.step == 1
              ? 'allbefore onebefore bg-myredr text-green' :
            props.step > 1 ?'bg-myred text-white' : 'bg-white text-green'
          } `}
        >
          1
        </div>
        {/* <div className={"h-12"}> <span className={`bstepper border ${props.step > 1 ? ' border-myred' : 'border-mygreen'}`}></span> </div> */}
        <div
          className={`w-10 h-10 shadow-md rounded-full flex justify-center items-center font-bold ${
            props.step == 2
              ? 'allbefore twobefore bg-myredr text-green' :
              props.step > 2 ? 'bg-myred text-white' : 'bg-white text-green'
          } `}
        >
          2
        </div>
        {/* <div className={"h-12"}> <span className={`bstepper2 border ${props.step > 2 ? ' border-myred' : 'border-mygreen'}`}></span> </div> */}
        {/* <div
          className={`w-10 h-10 shadow-md rounded-full flex justify-center items-center font-bold ${
            props.step == 3
              ? 'allbefore threebefore bg-myred text-white' :
              props.step > 3 ? 'bg-green text-white' : 'bg-white text-green'
          } `}
        >
          3
        </div> */}
        
      </div>
    </div>
  )
}
