import { createTheme, ThemeProvider } from '@mui/material';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import AppSnackbar from './components/Notification/AppSnackbar';
import Footer from './pages/Footer/Footer';
import Header from './pages/header/header';
import LinearProgressBar from './components/Generic/LinearProgressBar';
import Routes from './routes/routes';
import store from './store/index';
import theme from './theme';
import AxiosInterceptors from './helpers/AxiosInterceptors';
import styled from 'styled-components';
import { SnackbarProvider } from 'notistack';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

function App() {
  const customTheme = createTheme(theme);

  return (
    <Provider store={store}>
      <Router>
        <ThemeProvider theme={customTheme}>
          <ToastContainer
            position='top-center'
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='colored'
          />
          <SnackbarProvider maxSnack={10}>
            <LinearProgressBar>
              <Container>
                <Header />
                <Routes />
                <Footer />
              </Container>

              <AppSnackbar />
              <AxiosInterceptors />
            </LinearProgressBar>
          </SnackbarProvider>
        </ThemeProvider>
      </Router>
    </Provider>
  );
}

export default App;
