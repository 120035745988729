import React from "react";
import KeycloakService from "../services/KeycloakService";
import NotAllowed from "../pages/NotAllowed";

const RenderOnRole = ({ roles, children }) => {
  // if (!KeycloakService.hasRole(roles)) return <NotAllowed />;
  if (!KeycloakService.hasRole(roles)) return <div></div>;
  else return children;
};

export default RenderOnRole;
