import React, { useEffect, useState } from "react";
import axios from "../../services/AxiosService";
import KeycloakService from "../../services/KeycloakService";

const useAutoComplete = ({
  optionsCode,
  allOptionsCode,
  autoCompleteRef,
  optionsBoxRef,
  uniqueValue,
  disabledvalues = [],
}) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [uniqueItem, setUniqueItem] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        autoCompleteRef.current &&
        !autoCompleteRef.current.contains(event.target) &&
        optionsBoxRef.current &&
        !optionsBoxRef.current.contains(event.target)
      ) {
        setShowSuggestions(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [autoCompleteRef]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + `${optionsCode}/${uniqueValue}`, {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      })
      .then((response) => {
        if (typeof response.data.content === "object") {
          if (response.data.content.length > 0)
            return setUniqueItem(response.data.content[0]);
          return null;
        } else {
          if (response.data.length > 0) return setUniqueItem(response.data[0]);
          return null;
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [uniqueValue]);

  const onInputChange = (query) => {
    if (query.length === 0) {
      return setOptions([]);
    }
    /// indicar categoria já existente
    axios
      .get(process.env.REACT_APP_API_URL + `${optionsCode}/${query}`, {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      })
      .then((response) => {
        if (typeof response.data.content === "object") {
          setOptions(
            response.data.content.filter(
              (option) => !disabledvalues.includes(option.name.toLowerCase())
            )
          );
          if (response.data.content.length > 0) {
            setShowSuggestions(true);
          }
        } else {
          setOptions(
            response.data.filter(
              (option) => !disabledvalues.includes(option.name.toLowerCase())
            )
          );
          if (response.data.length > 0) {
            setShowSuggestions(true);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onSuggestionClick = () => {
    setShowSuggestions(false);
  };

  const onFocus = () => {
    setShowSuggestions(true);
  };

  return {
    showSuggestions,
    options,
    onSuggestionClick,
    onInputChange,
    onFocus,
    uniqueItem,
  };
};

export default useAutoComplete;
