import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import QuadroCV from '../admin/pages/Quadros/QuadroCV/QuadroCV';
import Dashboard from '../pages/dashboard/dashboard';
import AdminDashboard from "../pages/dashboard/AdminDashboard";

import AlocarQuadro from '../admin/pages/Quadros/AlocarQuadro';
import CessarQuadro from '../admin/pages/Quadros/CessarQuadro';
import AlocacaoQuadros from '../admin/pages/Instituicoes/AlocacaoQuadros/AlocacaoQuadros';
import AlocacaoQuadrosForm from '../admin/pages/Instituicoes/AlocacaoQuadros/AlocacaoQuadrosForm';
import AlterarPin from '../pages/quadros/AlterarPin/AlterarPin';
import AlterarTelefone from '../admin/pages/Quadros/AlterarTelefoneQuadro';
import ExoneracaoQuadros from '../admin/pages/Instituicoes/AlocacaoQuadros/ExoneracaoQuadros';
import MobilidadeQuadros from '../admin/pages/Instituicoes/AlocacaoQuadros/MobilidadeQuadros';
import ActividadesQuadro from '../admin/pages/Quadros/ActividadesQuadro';
import NomeacaoQuadros from '../admin/pages/Instituicoes/AlocacaoQuadros/NomeacaoQuadros';
import ProgressaoQuadros from '../admin/pages/Instituicoes/AlocacaoQuadros/ProgressaoQuadros';
import DestacamentoQuadros from '../admin/pages/Instituicoes/AlocacaoQuadros/DestacamentoQuadros';

const Noticias = React.lazy(() => import('../pages/quadros/noticias/noticias'));
const Noticia = React.lazy(() => import('../pages/quadros/noticia/noticia'));
const Empregos = React.lazy(() => import('../pages/quadros/empregos/empregos'));
const QuadroVerEmprego = React.lazy(() =>
  import('../pages/quadros/empregos/verEmprego/VerEmprego')
);
const Eventos = React.lazy(() => import('../pages/quadros/eventos/Eventos'));
const Evento = React.lazy(() => import('../pages/quadros/evento/Evento'));
const Account = React.lazy(() => import('../pages/quadros/account/account'));
const EntidadeAccount = React.lazy(() =>
  import('../pages/entidades/account/Account')
);
const EntidadeQuadros = React.lazy(() =>
  import('../pages/entidades/quadros/Quadros')
);
const EntidadeQuadro = React.lazy(() =>
  import('../pages/entidades/quadro/Quadro')
);
const AddQuadro = React.lazy(() =>
  import('../pages/entidades/quadro/AddQuadro')
);
const EntidadeNoticias = React.lazy(() =>
  import('../pages/entidades/noticias/Noticias')
);
const EntidadeEmpregos = React.lazy(() =>
  import('../pages/entidades/empregos/Empregos')
);
const EntidadeEventos = React.lazy(() =>
  import('../pages/entidades/eventos/Eventos')
);

const EntidadeSetNoticia = React.lazy(() =>
  import('../pages/entidades/noticias/setNoticia/SetNoticia')
);

const EntidadeSetEmprego = React.lazy(() =>
  import('../pages/entidades/empregos/setEmprego/SetEmprego')
);

const EntidadeSetEvento = React.lazy(() =>
  import('../pages/entidades/eventos/setEvento/SetEvento')
);

//
const AdminHomepage = React.lazy(() => import('../admin/Admin'));

const Welcome = React.lazy(() =>
  import('../pages/authentication/Authentication')
);

const Routes = () => {
  return (
    <Suspense fallback={<div></div>}>
      <Switch>
        <Route exact path='/'>
          <Redirect to='/welcome' />
        </Route>
        <Route exact path='/welcome' component={Welcome} />
        <Route
          exact
          path="/admin/dash"
          component={AdminDashboard}
          allowedUsers="admin"
        />

        <Route
          exact
          path='/quadros/noticias'
          component={Noticias}
          allowedUsers='quadro'
        />
        <Route
          exact
          path='/quadros/noticias/ver/:id'
          component={Noticia}
          allowedUsers='quadro'
        />
        <Route
          exact
          path='/quadros/empregos'
          component={Empregos}
          allowedUsers='quadro'
        />
        <Route
          exact
          path='/quadros/empregos/:id'
          component={QuadroVerEmprego}
          allowedUsers='quadro'
        />
        <Route
          exact
          path='/quadros/eventos'
          component={Eventos}
          allowedUsers='quadro'
        />
        <Route
          exact
          path='/quadros/eventos/:id'
          component={Evento}
          allowedUsers='quadro'
        />
        <Route
          exact
          path='/quadros/account'
          component={Account}
          allowedUsers='quadro'
        />
        <Route
          exact
          path='/quadros/alterar_pin'
          component={AlterarPin}
          allowedUsers='quadro'
        />
        <Route
          exact
          path='/quadros/alterar_telefone/:id'
          component={AlterarTelefone}
          allowedUsers='quadro'
        />

        {/* <Route
          exact
          path="/entidades/homepage"
          component={EntidadeHomepage}
          allowedUsers="entidade"
        /> */}
        <Route
          exact
          path='/entidades/account'
          component={EntidadeAccount}
          allowedUsers='entidade'
        />
        <Route
          exact
          path='/entidades/quadros'
          component={EntidadeQuadros}
          allowedUsers='entidade'
        />
        <Route
          exact
          path='/entidades/quadros/:id'
          component={EntidadeQuadro}
          allowedUsers='entidade'
        />
        <Route
          path='/entidades/quadros/:id/alocar'
          component={AlocarQuadro}
          allowedUsers='entidade'
          exact
        />
        <Route
          path='/entidades/quadros/:id/adicionar'
          component={AddQuadro}
          allowedUsers='entidade'
          exact
        />

        <Route
          path='/entidades/quadros/:id/cessar'
          component={CessarQuadro}
          allowedUsers='entidade'
          exact
        />
        <Route
          exact
          path='/entidades/noticias'
          component={EntidadeNoticias}
          allowedUsers='entidade'
        />

        <Route
          path={'/entidades/noticias/:type/:id'}
          component={EntidadeSetNoticia}
          allowedUsers='entidade'
          exact
        />

        <Route
          path={'/entidades/noticias/:type'}
          component={EntidadeSetNoticia}
          allowedUsers='entidade'
          exact
        />

        <Route
          exact
          path='/entidades/empregos'
          component={EntidadeEmpregos}
          allowedUsers='entidade'
        />

        <Route
          exact
          path='/entidades/empregos/:type/:id'
          component={EntidadeSetEmprego}
          allowedUsers='entidade'
        />

        <Route
          path={'/entidades/empregos/:type/:id'}
          component={EntidadeSetEmprego}
          allowedUsers='entidade'
          exact
        />

        <Route
          path={'/entidades/empregos/:type'}
          component={EntidadeSetEmprego}
          allowedUsers='entidade'
          exact
        />

        <Route
          exact
          path='/entidades/eventos'
          component={EntidadeEventos}
          allowedUsers='entidade'
        />
        <Route
          exact
          path='/entidades/eventos/:type/:id'
          component={EntidadeSetEvento}
          allowedUsers='entidade'
        />

        {/* <Route
          path={"/entidades/eventos/:type/:id"}
          component={EntidadeSetEvento}
          allowedUsers="entidade"
          exact
        /> */}

        <Route
          path={'/entidades/eventos/:type'}
          component={EntidadeSetEvento}
          allowedUsers='entidade'
          exact
        />

        <Route
          path='/admin/quadros/:employeeId/curriculum'
          component={QuadroCV}
          allowedUsers='admin'
          exact
        />

        <Route
          path='/quadro/:employeeId/curriculum'
          component={QuadroCV}
          allowedUsers='quadro'
          exact
        />
        <Route
          path='/entidades/quadros/:employeeId/curriculum'
          component={QuadroCV}
          allowedUsers='entidade'
          exact
        />
        <Route
          path='/entidades/dashboard'
          component={Dashboard}
          allowedUsers='entidade'
          exact
        />

        <Route
          path='/quadros/dashboard'
          component={Dashboard}
          allowedUsers='quadro'
          exact
        />

        <Route
          path='/instituicoes/:institutionId/orgaos/:unitId'
          component={AlocacaoQuadros}
          allowedUsers='entidades'
          exact
        />

        <Route
          path='/instituicoes/:institutionId/orgaos/:unitId/:operation'
          component={AlocacaoQuadrosForm}
          allowedUsers='entidades'
          exact
        />
        <Route
          path='/admin/instituicoes/:institutionId/orgaos/exoneracao/:unitId/:employeeId'
          component={ExoneracaoQuadros}
          allowedUsers='admin'
          exact
        />
        <Route
          path='/admin/instituicoes/:institutionId/orgaos/:unitId/mobilidade/:employeeId'
          component={MobilidadeQuadros}
          allowedUsers='admin'
          exact
        />
        <Route
          path='/admin/instituicoes/:institutionId/orgaos/nomeacao/:unitId/:employeeId'
          component={NomeacaoQuadros}
          allowedUsers='admin'
          exact
        />

        <Route
          path='/instituicoes/:institutionId/orgaos/progresso/:unitId/:employeeId'
          component={ProgressaoQuadros}
          allowedUsers='entidades'
          exact
        />

        <Route
          path='/instituicoes/:institutionId/orgaos/exoneracao/:unitId/:employeeId'
          component={ExoneracaoQuadros}
          allowedUsers='entidades'
          exact
        />

        <Route
          path='/instituicoes/:institutionId/orgaos/nomeacao/:unitId/:employeeId'
          component={NomeacaoQuadros}
          allowedUsers='entidades'
          exact
        />

        <Route
          path='/instituicoes/:institutionId/orgaos/destacamento/:unitId/:employeeId'
          component={DestacamentoQuadros}
          allowedUsers='entidades'
          exact
        />

        <Route
          path='/instituicoes/:institutionId/orgaos/:unitId/mobilidade/:employeeId'
          component={MobilidadeQuadros}
          allowedUsers='entidades'
          exact
        />

        <Route
          path='/instituicoes/:institutionId/orgaos/:unitId/:operation/:employeeId'
          component={AlocacaoQuadrosForm}
          allowedUsers='entidades'
          exact
        />

        <Route
          path='/admin/instituicoes/:institutionId/orgaos/progresso/:unitId/:employeeId'
          component={ProgressaoQuadros}
          allowedUsers='admin'
          exact
        />

        <Route
          path='/admin/instituicoes/:institutionId/orgaos/destacamento/:unitId/:employeeId'
          component={DestacamentoQuadros}
          allowedUsers='admin'
          exact
        />

        <Route
          path='/quadros/:id/actividades'
          component={ActividadesQuadro}
          allowedUsers='admin'
          exact
        />

        <Route path='/admin' component={AdminHomepage} allowedUsers='admin' />
      </Switch>
    </Suspense>
  );
};

export default Routes;
