import { takeLatest, all, put, call } from "redux-saga/effects";
import {
  GET_EMPLOYEE_REQUEST,
  GET_EMPLOYEE_SECTION_REQUEST,
  GET_EMPLOYEE_SECTION2_REQUEST,
  GET_EMPLOYEE_SECTION3_REQUEST,
  GET_EMPLOYEE_SECTION4_REQUEST,
  GET_EMPLOYEE_SECTION5_REQUEST,
  GET_EMPLOYEE_SECTION6_REQUEST,
  GET_EMPLOYEE_SECTION7_REQUEST,
  GET_EMPLOYEE_SECTION8_REQUEST,
  GET_INSTITUTION_REQUEST,
  EDIT_EMPLOYEE_REQUEST,
  EDIT_EMPLOYEE_SECTION_REQUEST,
  EDIT_INSTITUTION_REQUEST,
  GET_PROVINCES_REQUEST,
  GET_NATIONALITY_REQUEST,
  GET_SCHOOLINGCYCLE_REQUEST,
  GET_COUNTRIES_REQUEST,
  GET_IDIOMS_REQUEST,
  GET_IDIOMS_BY_TYPE_REQUEST,
  GET_COURSES_BY_KNOWLEDGE_AREA_REQUEST,
  GET_MARITALSTATUS_REQUEST,
  GET_EDUCATIONALINSTITUTIONS_REQUEST,
  GET_ACTIVITYSECTOR_REQUEST,
  GET_INSTITUTIONTYPES_REQUEST,
  GET_INSTITUTIONTYPE_REQUEST,
  GET_INSTITUTIONEMPLOYEES_REQUEST,
  GET_KNOWLEDGE_AREA_REQUEST,
  GET_COURSES_REQUEST,
  GET_TYPE_FORMATION_REQUEST,
  GET_PROFESSION_REQUEST,
  GETALL_INSTITUTIONS_REQUEST,
  GETALL_INSTITUTIONS_PRIVATE_REQUEST,
  GETALL_TYPES_VOLUNTEERS_REQUEST,
  GETALL_TYPES_ACCOMPLISHMENTS_REQUEST,
  GET_JOB_OFFERS_REQUEST,
  GET_MUNICIPALITIES_REQUEST,
  GET_DISABILITYTYPES_REQUEST,
  GET_PROVINCES_MUNICIPALITIES_REQUEST,
  GET_MUNICIPALITIES_COMMUNES_REQUEST,
  GET_PROFESSIONAL_ORDERS_REQUEST,
  GET_POLITICAL_POSITIONS_REQUEST,
  GET_POSITIONS_REQUEST,
  GET_CATEGORIES_REQUEST,
  GET_REGIMETYPES_REQUEST,
  GET_CAREERS_REQUEST,
  GET_EMPLOYEESTOASSIGN_REQUEST,
  GET_UNIT_REQUEST,
  POST_ASSIGNEMPLOYEE_REQUEST,
  GET_SPECIALREGIMETYPES_REQUEST,
  GET_CONTRACTUALREGIMENSTYPES_REQUEST,
  GET_JURIDICALBONDS_REQUEST,
  GET_JURIDICALBONDCHANGE_REQUEST,
  GET_UNITEMPLOYEES_REQUEST,
  UPDATE_ASSIGNEDEMPLOYEE_REQUEST,
  GET_FAMILYKINSHIP_REQUEST,
  DELETE_ASSIGNEDEMPLOYEE_REQUEST,
  PUT_CEASEFUNCTIONS_REQUEST,
  GET_USERBYBI_REQUEST,
  POST_EMPLOYEE_REQUEST,
  GET_COURSE_REQUEST,
  UPLOAD_LITERARY_QUALIFICATION_DOCUMENT_REQUEST,
  DELETE_DOCUMENT_REQUEST,
  GET_DOCUMENT_REQUEST,
  UPLOAD_TRAINING_DOCUMENT_REQUEST,
} from "./actionTypes";
import {
  getCountriesSuccess,
  getCountriesError,
  getIdiomsSuccess,
  getIdiomsByTypeSuccess,
  getCourseByKnowledgeAreaSuccess,
  getIdiomsError,
  getIdiomsByTypeError,
  getCourseByKnowledgeAreaError,
  getEmployeeError,
  getEmployeeSuccess,
  getEmployeeSectionSuccess,
  getEmployeeSection2Success,
  getEmployeeSection3Success,
  getEmployeeSection4Success,
  getEmployeeSection5Success,
  getEmployeeSection6Success,
  getEmployeeSection7Success,
  getEmployeeSection8Success,
  getEmployeeSectionError,
  getNationalitySuccess,
  getProvincesError,
  getProvincesSuccess,
  getSchoolingCycleSuccess,
  getProfilePictureError,
  getProfilePictureSuccess,
  getMaritalStatusSuccess,
  getMaritalStatusError,
  getEducationalInstitutionsSuccess,
  getEducationalInstitutionsError,
  getActivitySectorsSuccess,
  getActivitySectorsError,
  getInstitutionError,
  getInstitutionSuccess,
  getInstitutionTypesSuccess,
  getInstitutionTypesError,
  getInstitutionTypeSuccess,
  getInstitutionTypeError,
  editInstitutionError,
  editEmployeeError,
  editEmployeeSectionError,
  getInstitutionEmployeesSuccess,
  getInstitutionEmployeesError,
  setSnackbar,
  getCoursesSuccess,
  getCoursesError,
  getKnowledgeAreaSuccess,
  getKnowledgeAreaError,
  getTypeFormationSuccess,
  getTypeFormationError,
  getAllInstitutionsSuccess,
  getAllInstitutionsPrivateSuccess,
  getAllInstitutionsError,
  getAllInstitutionsPrivateError,
  getAllTypesVolunteersSuccess,
  getAllTypesVolunteersError,
  getAllTypesAccomplishmentsSuccess,
  getAllTypesAccomplishmentsError,
  getProfessionsSuccess,
  getProfessionsError,
  getJobOffersSuccess,
  getJobOffersError,
  getMunicipalitiesSuccess,
  getMunicipalitiesError,
  getDisabilityTypesSuccess,
  getDisabilityTypesError,
  getProvincesMunicipalitiesSuccess,
  getProvincesMunicipalitiesError,
  getMunicipalitiesCommunesSuccess,
  getMunicipalitiesCommunesError,
  getProfessionalOrdersSuccess,
  getProfessionalOrdersError,
  getPoliticalPositionsSuccess,
  getPoliticalPositionsError,
  getPositionsSuccess,
  getPositionsError,
  getCategoriesSuccess,
  getCategoriesError,
  getRegimeTypesSuccess,
  getRegimeTypesError,
  getCareersError,
  getCareersSuccess,
  getEmployeesToAssignSuccess,
  getEmployeesToAssignError,
  getUnitSuccess,
  getUnitError,
  postAssignEmployeeSuccess,
  postAssignEmployeeError,
  getSpecialRegimeTypesSuccess,
  getSpecialRegimeTypesError,
  getContractualRegimensTypesSuccess,
  getContractualRegimensTypesError,
  getJuridicalBondsSuccess,
  getJuridicalBondsError,
  getJuridicalBondChangeSuccess,
  getJuridicalBondChangeError,
  getUnitEmployeesSuccess,
  getUnitEmployeesError,
  updateAssignedEmployeeSuccess,
  updateAssignedEmployeeError,
  getFamilyKinshipSuccess,
  getFamilyKinshipError,
  deleteAssignedEmployeeSuccess,
  deleteAssignedEmployeeError,
  putCeaseFunctionsSuccess,
  putCeaseFunctionsError,
  getUserByBiSuccess,
  getUserByBiError,
  editEmployeeSuccess,
  postEmployeeError,
  postEmployeeSuccess,
  getCourseSuccess,
  getCourseError,
  uploadLiteraryQualificationDocumentSuccess,
  uploadLiteraryQualificationDocumentError,
  getDocumentError,
  getDocumentSuccess,
  uploadTrainingDocumentError,
  uploadTrainingDocumentSuccess,
} from "./actionCreators";
import KeycloakService from "../../../services/KeycloakService";
import axios from "../../../services/AxiosService";
import { RepeatOneSharp } from "@material-ui/icons";

const dataActivity = JSON.parse(localStorage.getItem("userData"));

export function* getEmployeeProfilePicture(employeeId) {
  try {
    const response = yield call(
      axios.get,
      `/employees/${employeeId}/pictures-profiles/files`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
          Accept: "image/*",
        },
      },
      { responseType: "blob" }
    );

    yield put(getProfilePictureSuccess(response.data));
  } catch (error) {
    yield put(getProfilePictureError(error));
  }
}

export function* getEmployeeFiles(employeeId) {
  try {
    yield call(axios.get, `/employees/${employeeId}/defaults/files`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
    });
  } catch (error) {
    yield put(getProfilePictureError(error));
  }
}

export function* addEmployeeProfilePicture(file, employeeId) {
  try {
    const formData = new FormData();
    formData.append("file", file);
    yield call(
      axios.post,
      `/employees/${employeeId}/pictures-profiles/files`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      },
      { responseType: "blob" }
    );
  } catch (error) {}
}

export function* addEntityFile({ entityId, file, type }) {
  console.log({ entityId, file, type });
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = yield call(
      axios.post,
      `/documents/${type}/${entityId}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log(response);
    // if(response.config.status)
  } catch (error) {
    console.log(error);
  }
}

export function* addEmployeeSectionFile(dataWithFiles, dataWithIds, type) {
  console.log("in");
  yield all(
    dataWithFiles.map((element, index) => {
      console.log("here");
      let file;
      if (type == "literary-qualification") file = element.fileUpload;
      else if (type == "professional-experience")
        file = element.professionalExperieceFile;

      if (file) {
        console.log("again");
        put(addEntityFile(dataWithIds[index].id, file, type));
        // call(addEntityFile, { entityId: dataWithIds[index].id, file, type });
        // call([addEntityFile], dataWithIds[index].id, file, type);
        // call({context: addEntityFile,  dataWithIds[index].id, file, type});
      }
    })
  );
}

export function* getEmployee(action) {
  try {
    const response = yield call(axios.get, `/employees/${action.payload.sub}`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
    });
    yield put(getEmployeeSuccess(response.data || {}));
  } catch (error) {
    yield put(getEmployeeError(error));
  }
}

export function* getEmployeeSection(action) {
  try {
    const response = yield call(
      axios.get,
      `/employees/${action.payload.data}/${action.payload.sectionType}`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    yield put(getEmployeeSectionSuccess(response.data));
  } catch (error) {
    yield put(getEmployeeSectionError(error));
  }
}
export function* getEmployeeSection2(action) {
  try {
    const response = yield call(
      axios.get,
      `/employees/${action.payload.data}/${action.payload.sectionType}`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    yield put(getEmployeeSection2Success(response.data));
  } catch (error) {
    yield put(getEmployeeSectionError(error));
  }
}
export function* getEmployeeSection3(action) {
  try {
    const response = yield call(
      axios.get,
      `/employees/${action.payload.data}/${action.payload.sectionType}`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    yield put(getEmployeeSection3Success(response.data));
  } catch (error) {
    yield put(getEmployeeSectionError(error));
  }
}
export function* getEmployeeSection4(action) {
  try {
    const response = yield call(
      axios.get,
      `/employees/${action.payload.data}/${action.payload.sectionType}`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    yield put(getEmployeeSection4Success(response.data));
  } catch (error) {
    yield put(getEmployeeSectionError(error));
  }
}
export function* getEmployeeSection5(action) {
  try {
    const response = yield call(
      axios.get,
      `/employees/${action.payload.data}/${action.payload.sectionType}`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    yield put(getEmployeeSection5Success(response.data));
  } catch (error) {
    yield put(getEmployeeSectionError(error));
  }
}
export function* getEmployeeSection6(action) {
  try {
    const response = yield call(
      axios.get,
      `/employees/${action.payload.data}/${action.payload.sectionType}`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    yield put(getEmployeeSection6Success(response.data));
  } catch (error) {
    yield put(getEmployeeSectionError(error));
  }
}
export function* getEmployeeSection7(action) {
  try {
    const response = yield call(
      axios.get,
      `/employees/${action.payload.data}/${action.payload.sectionType}`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    yield put(
      getEmployeeSection7Success(
        response.data !== "" ? response.data : { childre: [] }
      )
    );
  } catch (error) {
    yield put(getEmployeeSectionError(error));
  }
}
export function* getEmployeeSection8(action) {
  try {
    const response = yield call(
      axios.get,
      `/employees/${action.payload.data}/${action.payload.sectionType}`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    yield put(getEmployeeSection8Success(response.data));
  } catch (error) {
    yield put(getEmployeeSectionError(error));
  }
}

export function* getInstitutionProfilePicture(institutionId) {
  try {
    const response = yield call(
      axios.get,
      `/institutions/${institutionId}/pictures-profiles/files`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    yield put(getProfilePictureSuccess(response.data));
  } catch (error) {
    yield put(getProfilePictureError(error));
  }
}

export function* addInstitutionProfilePicture(file, institutionId) {
  try {
    const formData = new FormData();
    formData.append("file", file);
    yield call(
      axios.post,
      `/institutions/${institutionId}/pictures-profiles/files`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      },
      { responseType: "blob" }
    );
  } catch (error) {}
}

export function* getInstitutionEmployees(action) {
  try {
    const response = yield call(
      axios.get,
      `/employees?page=${action.payload.pageIndex}&size=${action.payload.pageSize}&sort=name`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    yield put(getInstitutionEmployeesSuccess(response.data));
  } catch (error) {
    yield put(getInstitutionEmployeesError(error));
  }
}

export function* getInstitution(action) {
  try {
    const { res1, res2, res3 } = yield all({
      res1: call(axios.get, `/institutions/${action.payload.sub}/centrals`, {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      res2: call(axios.get, `/institutions/${action.payload.sub}/privates`, {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      res3: call(axios.get, `/institutions/${action.payload.sub}/locals`, {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
    });

    // const response = yield call(
    //   axios.get,
    //   `/institutions/${action.payload.sub}/centrals`,
    //   {
    //     headers: {
    //       Authorization: `Bearer ${KeycloakService.getToken()}`,
    //     },
    //   }
    // );
    const response = res1.data ? res1 : res2.data ? res2 : res3;

    yield put(getInstitutionSuccess(response.data || {}));
  } catch (error) {
    yield put(getInstitutionError(error));
  }
}

export function* getAllInstitutions() {
  try {
    const response = yield call(
      axios.get,
      /* `/institutions?localization=CENTRAL&status=ACTIVE&size=1000`, */
      `/institutions?all="true"&size=1000`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    yield put(getAllInstitutionsSuccess(response.data.content));
  } catch (error) {
    yield put(getAllInstitutionsError(error));
  }
}

export function* getAllInstitutionsPrivate() {
  try {
    const response = yield call(
      axios.get,
      /* `/institutions?localization=PRIVATE&status=ACTIVE&size=1000`, */
      `/institutions?all="true"&size=1000`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    yield put(getAllInstitutionsPrivateSuccess(response.data.content));
  } catch (error) {
    yield put(getAllInstitutionsPrivateError(error));
  }
}

export function* getAllTypesVolunteers() {
  try {
    const response = yield call(
      axios.get,
      `/volunteer-types?status=ACTIVE&size=1000`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    yield put(getAllTypesVolunteersSuccess(response.data.content));
  } catch (error) {
    yield put(getAllTypesVolunteersError(error));
  }
}

export function* getAllTypesAccomplishments() {
  try {
    const response = yield call(
      axios.get,
      `/accomplishment-types?status=ACTIVE&size=1000`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    yield put(getAllTypesAccomplishmentsSuccess(response.data.content));
  } catch (error) {
    yield put(getAllTypesAccomplishmentsError(error));
  }
}

export function* editEmployee(action) {
  const logsContaId = localStorage.getItem("logsContaId");
  const saveData = {
    ...action.payload.data,
    logsContaId: logsContaId,
  };
  try {
    let response;
    if (action.payload.data.institutionId)
      response = yield call(
        axios.post,
        `/employees/basic-info/${action.payload.data.institutionId}`,
        saveData,
        {
          headers: {
            Authorization: `Bearer ${KeycloakService.getToken()}`,
          },
        }
      );
    else
      response = yield call(
        axios.put,
        `/employees/${action.payload.data.id}/basic-info`,
        action.payload.data,
        {
          headers: {
            Authorization: `Bearer ${KeycloakService.getToken()}`,
          },
        }
      );
    if (!response.data) return yield put(editEmployeeError(response));
    yield addEmployeeProfilePicture(
      action.payload.data.image,
      response.data.id
    );
    if (action.payload.showNotification) {
      yield put(
        setSnackbar({
          snackbarIsOpen: true,
          snackbarMessage: `${action.payload.onEditSuccessMessage}`,
          snackbarType: "success",
        })
      );
    }
    yield put(editEmployeeSuccess(response.data));
    // yield getEmployee({ payload: { email: response.data.email } });
  } catch (error) {
    yield put(editEmployeeError(error));
  }
}

export function* registerEmployee(action) {
  try {
    let response;
    response = yield call(
      axios.post,
      `/employees/public/public-official`,
      action.payload.data,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    if (!response.data) return yield put(postEmployeeError(response));
    yield addEmployeeProfilePicture(
      action.payload.data.image,
      response.data.id
    );
    if (action.payload.showNotification) {
      yield put(
        setSnackbar({
          snackbarIsOpen: true,
          snackbarMessage: `${action.payload.onEditSuccessMessage}`,
          snackbarType: "success",
        })
      );
    }
    yield put(postEmployeeSuccess(response.data));
  } catch (error) {
    yield put(postEmployeeError(error));
  }
}
export function* editEmployeeSection(action) {
  console.log("action", action);
  try {
    const response = yield call(
      axios.put,
      `/employees/${action.payload.id}/${action.payload.sectionType}`,
      action.payload.data,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    if (!response.data) return yield put(editEmployeeSectionError(response));
    if (action.payload.showNotification) {
      yield put(
        setSnackbar({
          snackbarIsOpen: true,
          snackbarMessage: `${action.payload.onEditSuccessMessage}`,
          snackbarType: "success",
        })
      );
    }

    yield addEmployeeSectionFile(
      action.payload.data,
      response.data,
      action.payload.fileType
    );
    yield response.data.find((a) => a.createdAt != null)
      ? action.payload.docs(response.data.find((a) => a.createdAt != null).id)
      : action.payload.docs();

    if (action.payload.sectionType == "idioms")
      yield getEmployeeSection3({
        data: action.payload.id,
        sectionType: action.payload.sectionType,
      });
  } catch (error) {
    yield put(editEmployeeSectionError(error));
  }
}

export function* editInstitution(action) {
  try {
    const logsContaId = localStorage.getItem("logsContaId");
    const saveData = {
      ...action.payload.data,
      logsContaId: logsContaId,
    };
    const response = yield call(
      axios.put,
      `/institutions/${action.payload.data.id}/centrals`,
      saveData,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    if (!response.data) return yield put(editInstitutionError(response));
    yield addInstitutionProfilePicture(action.payload.image, response.data.id);
    if (action.payload.showNotification) {
      yield put(
        setSnackbar({
          snackbarIsOpen: true,
          snackbarMessage: `${action.payload.onEditSuccessMessage}`,
          snackbarType: "success",
        })
      );
    }
    yield getInstitution({ payload: { email: action.payload.data.email } });
  } catch (error) {
    yield put(editInstitutionError(error));
  }
}

export function* getProvinces() {
  try {
    const response = yield call(axios.get, `/provinces`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
    });
    yield put(getProvincesSuccess(response.data.content));
  } catch (error) {
    yield put(getProvincesError(error));
  }
}

export function* getMunicipalitiesByProvince(action) {
  try {
    const response = yield call(
      axios.get,
      `/municipalities?provinceId=${action.payload.provinceId}`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    yield put(getProvincesMunicipalitiesSuccess(response.data.content));
  } catch (error) {
    yield put(getProvincesMunicipalitiesError(error));
  }
}

export function* getCommunesByMunicipality(action) {
  try {
    const response = yield call(
      axios.get,
      `/communes?municipalityId=${action.payload.municipeId}`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    yield put(getMunicipalitiesCommunesSuccess(response.data.content));
  } catch (error) {
    yield put(getMunicipalitiesCommunesError(error));
  }
}

export function* getMunicipalities() {
  try {
    const response = yield call(axios.get, `/municipalities`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
    });
    yield put(getMunicipalitiesSuccess(response.data.content));
  } catch (error) {
    yield put(getMunicipalitiesError(error));
  }
}

export function* getDisabilityTypes() {
  try {
    const response = yield call(axios.get, `/employees/disability-types`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
    });
    yield put(getDisabilityTypesSuccess(response.data));
  } catch (error) {
    yield put(getDisabilityTypesError(error));
  }
}

export function* getJobOffers(action) {
  const dslQuery = action.payload.queryDslFilters.reduce(
    (finalStr, field, index) => {
      if (index === 0) {
        return finalStr + `?${field.label}=${field.value}`;
      }
      return finalStr + `&${field.label}=${field.value}`;
    },
    ""
  );
  try {
    const response = yield call(axios.get, `/job-offers${dslQuery}`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
    });
    yield put(
      getJobOffersSuccess({
        data: response.data.content,
        totalPages: response.data.totalPages,
        totalElements: response.data.totalElements,
      })
    );
  } catch (error) {
    yield put(getJobOffersError(error));
  }
}

export function* getNationality() {
  try {
    const response = yield call(axios.get, `/nationalities`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
    });
    yield put(getNationalitySuccess(response.data.content));
  } catch (error) {
    yield put(getProvincesError(error));
  }
}

export function* getCountries() {
  try {
    const response = yield call(axios.get, `/countries`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
    });
    yield put(getCountriesSuccess(response.data.content));
  } catch (error) {
    yield put(getCountriesError(error));
  }
}

export function* getIdioms() {
  try {
    const response = yield call(axios.get, `/idioms`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
    });
    yield put(getIdiomsSuccess(response.data.content));
  } catch (error) {
    yield put(getIdiomsError(error));
  }
}

export function* getIdiomsByType(action) {
  try {
    const response = yield call(
      axios.get,
      `/idioms?type=${action.payload}&size=1000`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    yield put(getIdiomsByTypeSuccess(response.data.content));
  } catch (error) {
    yield put(getIdiomsByTypeError(error));
  }
}

export function* getCourseByKnowledgeAreaRequest(action) {
  try {
    const response = yield call(
      axios.get,
      `/courses/knowledge-area/${action.payload}?size=1000`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    yield put(getCourseByKnowledgeAreaSuccess(response.data));
  } catch (error) {
    yield put(getCourseByKnowledgeAreaError(error));
  }
}

export function* getSchoolingCycles() {
  try {
    const response = yield call(axios.get, `/school-cycles`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
    });
    yield put(getSchoolingCycleSuccess(response.data.content));
  } catch (error) {
    yield put(getProvincesError(error));
  }
}

export function* getMaritalStatus() {
  try {
    const response = yield call(axios.get, `/employees/marital-status`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
    });
    yield put(getMaritalStatusSuccess(response.data));
  } catch (error) {
    yield put(getMaritalStatusError(error));
  }
}

export function* getProfessions() {
  try {
    const response = yield call(axios.get, `/professions`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
    });
    yield put(getProfessionsSuccess(response.data.content));
  } catch (error) {
    yield put(getProfessionsError(error));
  }
}

export function* getProfessionalOrders() {
  try {
    const response = yield call(axios.get, `/professional-orders`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
    });
    yield put(getProfessionalOrdersSuccess(response.data.content));
  } catch (error) {
    yield put(getProfessionalOrdersError(error));
  }
}

export function* getEducationalInstitutions() {
  try {
    const response = yield call(axios.get, `/educational-institutions`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
    });
    yield put(getEducationalInstitutionsSuccess(response.data));
  } catch (error) {
    yield put(getEducationalInstitutionsError(error));
  }
}

export function* getActivitySectors() {
  try {
    const response = yield call(axios.get, `/sector-activities`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
    });
    yield put(getActivitySectorsSuccess(response.data.content));
  } catch (error) {
    yield put(getActivitySectorsError(error));
  }
}

export function* getCourses() {
  try {
    const response = yield call(axios.get, `/courses`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
    });
    yield put(getCoursesSuccess(response.data.content));
  } catch (error) {
    yield put(getCoursesError(error));
  }
}

export function* getCourse(action) {
  try {
    const response = yield call(axios.get, `/courses/${action.payload.id}`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
    });
    yield put(getCourseSuccess(response.data));
  } catch (error) {
    yield put(getCourseError(error));
  }
}

export function* getKnowledgeAreas() {
  try {
    const response = yield call(axios.get, `/knowledge-area`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
    });
    yield put(getKnowledgeAreaSuccess(response.data.content));
  } catch (error) {
    yield put(getKnowledgeAreaError(error));
  }
}

export function* getTypeFormations() {
  try {
    const response = yield call(axios.get, `/type-formation`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
    });
    yield put(getTypeFormationSuccess(response.data.content));
  } catch (error) {
    yield put(getTypeFormationError(error));
  }
}

export function* getInstitutionTypes() {
  try {
    const response = yield call(axios.get, `/institutions/types-institutions`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
    });

    yield put(getInstitutionTypesSuccess(response.data));
  } catch (error) {
    yield put(getInstitutionTypesError(error));
  }
}

export function* getInstitutionType() {
  try {
    const response = yield call(axios.get, `/institution/types`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
    });

    yield put(getInstitutionTypeSuccess(response.data.content));
  } catch (error) {
    yield put(getInstitutionTypeError(error));
  }
}

export function* getPoliticalPositions() {
  try {
    const response = yield call(axios.get, `/political-positions`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
    });
    yield put(getPoliticalPositionsSuccess(response.data.content));
  } catch (error) {
    console.log("BANANA - ", error);
    yield put(getPoliticalPositionsError(error));
  }
}

export function* getPositions() {
  try {
    const response = yield call(axios.get, `/positions?size=1000`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
    });
    yield put(getPositionsSuccess(response.data.content));
  } catch (error) {
    yield put(getPositionsError(error));
  }
}

export function* getCategories() {
  try {
    const response = yield call(axios.get, `/categories`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
    });
    yield put(getCategoriesSuccess(response.data.content));
  } catch (error) {
    yield put(getCategoriesError(error));
  }
}

export function* getSpecialRegimeTypes() {
  try {
    const response = yield call(axios.get, `/regime-types`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
    });
    yield put(getSpecialRegimeTypesSuccess(response.data.content));
  } catch (error) {
    yield put(getSpecialRegimeTypesError(error));
  }
}

export function* getCareers() {
  try {
    const response = yield call(axios.get, `/careers`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
    });
    yield put(getCareersSuccess(response.data.content));
  } catch (error) {
    yield put(getCareersError(error));
  }
}

export function* getEmployeesToAssign(action) {
  try {
    const response = yield call(
      axios.get,
      `/resource-mobility/to-assign/${action.payload.id}`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    yield put(getEmployeesToAssignSuccess(response.data.content));
  } catch (error) {
    yield put(getEmployeesToAssignError(error));
  }
}

export function* getUnit(action) {
  try {
    const response = yield call(
      axios.get,
      `/institutions/${action.payload.institutionId}/organizational-structure/${action.payload.unitId}`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    yield put(getUnitSuccess(response.data));
  } catch (error) {
    yield put(getUnitError(error));
  }
}

export function* uploadHabilitationFile(action) {
  try {
    const formData = new FormData();
    formData.append("file", action.payload.file);
    const response = yield call(
      axios.post,
      `/documents/literary-qualification/${action.payload.qualificationId}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    yield put(uploadLiteraryQualificationDocumentSuccess(response.data));
    //if(!response.data) return yield put(uploadLiteraryQualificationDocumentError(response));
    yield action.payload.run();

    if (action.payload.showNotification) {
      yield put(
        setSnackbar({
          snackbarIsOpen: true,
          snackbarMessage: `${action.payload.onSuccessMessage}`,
          snackbarType: "success",
        })
      );
    }
  } catch (error) {
    yield put(uploadLiteraryQualificationDocumentError(error));
  }
}

export function* uploadTrainingFile(action) {
  try {
    const formData = new FormData();
    formData.append("file", action.payload.file);
    const response = yield call(
      axios.post,
      `/documents/training/${action.payload.trainingId}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    yield put(uploadTrainingDocumentSuccess(response.data));
    //if(!response.data) return yield put(uploadLiteraryQualificationDocumentError(response));
    yield action.payload.run();

    if (action.payload.showNotification) {
      yield put(
        setSnackbar({
          snackbarIsOpen: true,
          snackbarMessage: `${action.payload.onSuccessMessage}`,
          snackbarType: "success",
        })
      );
    }
  } catch (error) {
    yield put(uploadTrainingDocumentError(error));
  }
}

export function* getDocument(action) {
  try {
    const response = yield call(
      axios
        .get(
          `/documents/${action.payload.docId}`,
          {
            responseType: "blob",
            headers: {
              Authorization: `Bearer ${KeycloakService.getToken()}`,
            },
          } // !!!
        )
        .then((response) => {
          window.open(URL.createObjectURL(response.data));
        })
    );

    yield put(getDocumentSuccess(response.data));
    if (action.payload.showNotification) {
      yield put(
        setSnackbar({
          snackbarIsOpen: true,
          snackbarMessage: `${action.payload.onSuccessMessage}`,
          snackbarType: "success",
        })
      );
    }
  } catch (error) {
    yield put(getDocumentError(error));
  }
}
export function* deleteDocument(action) {
  try {
    const response = yield call(
      axios.delete,
      `/documents/${action.payload.docId}`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    yield put(deleteAssignedEmployeeSuccess(response.data));

    if (action.payload.showNotification) {
      yield put(
        setSnackbar({
          snackbarIsOpen: true,
          snackbarMessage: `${action.payload.onSuccessMessage}`,
          snackbarType: "success",
        })
      );
    }
    yield action.payload.run();
  } catch (error) {
    yield put(postAssignEmployeeError(error));
  }
}

export function* postAssignEmployee(action) {
  try {
    const response = yield call(
      axios.post,
      `/resource-mobility/assign-employee`,
      action.payload,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    yield put(postAssignEmployeeSuccess(response.data.content));
    if (action.payload.showNotification) {
      yield put(
        setSnackbar({
          snackbarIsOpen: true,
          snackbarMessage: `${action.payload.onSuccessMessage}`,
          snackbarType: "success",
        })
      );
    }
  } catch (error) {
    yield put(postAssignEmployeeError(error));
  }
}

export function* updateAssignedEmployee(action) {
  try {
    const response = yield call(
      axios.put,
      `/resource-mobility/${action.payload.id}`,
      action.payload,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    yield put(updateAssignedEmployeeSuccess(response.data.content));
    if (action.payload.showNotification) {
      yield put(
        setSnackbar({
          snackbarIsOpen: true,
          snackbarMessage: `${action.payload.onSuccessMessage}`,
          snackbarType: "success",
        })
      );
    }
  } catch (error) {
    yield put(updateAssignedEmployeeError(error));
  }
}

export function* deleteAssignedEmployee(action) {
  try {
    const response = yield call(
      axios.delete,
      `/resource-mobility/${action.payload.id}`,
      // action.payload,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    yield put(deleteAssignedEmployeeSuccess(response.data.content));
    yield action.payload.callback();
    if (action.payload.showNotification) {
      yield put(
        setSnackbar({
          snackbarIsOpen: true,
          snackbarMessage: `${action.payload.onSuccessMessage}`,
          snackbarType: "success",
        })
      );
    }
  } catch (error) {
    yield put(deleteAssignedEmployeeError(error));
  }
}

export function* putCeaseFunctions(action) {
  try {
    const response = yield call(
      axios.put,
      `/resource-mobility/cease-functions`,
      action.payload,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    yield put(putCeaseFunctionsSuccess(response.data.content));
    if (action.payload.showNotification) {
      yield put(
        setSnackbar({
          snackbarIsOpen: true,
          snackbarMessage: `${action.payload.onSuccessMessage}`,
          snackbarType: "success",
        })
      );
    }
  } catch (error) {
    yield put(putCeaseFunctionsError(error));
  }
}

export function* getContractualRegimeTypes() {
  try {
    const response = yield call(axios.get, `/contractual-regimens`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
    });
    yield put(getContractualRegimensTypesSuccess(response.data.content));
  } catch (error) {
    yield put(getContractualRegimensTypesError(error));
  }
}

export function* getJuridicalBondChange() {
  try {
    const response = yield call(axios.get, `/juridical-bond-change`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
    });
    yield put(getJuridicalBondChangeSuccess(response.data.content));
  } catch (error) {
    yield put(getJuridicalBondChangeError(error));
  }
}

export function* getJuridicalBonds() {
  try {
    const response = yield call(axios.get, `/juridical-bonds`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
    });
    yield put(getJuridicalBondsSuccess(response.data.content));
  } catch (error) {
    yield put(getJuridicalBondsError(error));
  }
}

export function* getUnitEmployees(action) {
  const dslQuery = action.payload.queryDslFilters
    ? action.payload.queryDslFilters.reduce((finalStr, field, index) => {
        if (index === 0) {
          return finalStr + `?${field.label}=${field.value}`;
        }
        return finalStr + `&${field.label}=${field.value}`;
      }, "")
    : "";

  try {
    const response = yield call(
      axios.get,
      // `/resource-mobility?unitId=${action.payload.unitId}${dslQuery}`,
      `/resource-mobility${dslQuery}`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    yield put(
      getUnitEmployeesSuccess({
        data: response.data.content,
        totalPages: response.data.totalPages,
        totalElements: response.data.totalElements,
      })
    );
  } catch (error) {
    yield put(getUnitEmployeesError(error));
  }
}

export function* getFamilyKinship(action) {
  try {
    const response = yield call(axios.get, `/family-kinship`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
    });
    yield put(getFamilyKinshipSuccess(response.data.content));
  } catch (error) {
    yield put(getFamilyKinshipError(error));
  }
}

export function* getUserByBi(action) {
  try {
    const response = yield call(
      axios.get,
      `/user-identification/employee/${action.payload.bi}`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    yield put(getUserByBiSuccess(response.data));
  } catch (error) {
    yield put(getUserByBiError(error));
  }
}

export default function* usersSagas() {
  yield all([
    takeLatest(GET_EMPLOYEE_REQUEST, getEmployee),
    takeLatest(GET_EMPLOYEE_SECTION_REQUEST, getEmployeeSection),
    takeLatest(GET_EMPLOYEE_SECTION2_REQUEST, getEmployeeSection2),
    takeLatest(GET_EMPLOYEE_SECTION3_REQUEST, getEmployeeSection3),
    takeLatest(GET_EMPLOYEE_SECTION4_REQUEST, getEmployeeSection4),
    takeLatest(GET_EMPLOYEE_SECTION5_REQUEST, getEmployeeSection5),
    takeLatest(GET_EMPLOYEE_SECTION6_REQUEST, getEmployeeSection6),
    takeLatest(GET_EMPLOYEE_SECTION7_REQUEST, getEmployeeSection7),
    takeLatest(GET_EMPLOYEE_SECTION8_REQUEST, getEmployeeSection8),
    takeLatest(GET_INSTITUTION_REQUEST, getInstitution),
    takeLatest(GETALL_INSTITUTIONS_REQUEST, getAllInstitutions),
    takeLatest(GETALL_INSTITUTIONS_PRIVATE_REQUEST, getAllInstitutionsPrivate),
    takeLatest(GETALL_TYPES_VOLUNTEERS_REQUEST, getAllTypesVolunteers),
    takeLatest(
      GETALL_TYPES_ACCOMPLISHMENTS_REQUEST,
      getAllTypesAccomplishments
    ),
    takeLatest(POST_EMPLOYEE_REQUEST, registerEmployee),
    takeLatest(EDIT_EMPLOYEE_REQUEST, editEmployee),
    takeLatest(EDIT_EMPLOYEE_SECTION_REQUEST, editEmployeeSection),
    takeLatest(EDIT_INSTITUTION_REQUEST, editInstitution),
    takeLatest(GET_PROVINCES_REQUEST, getProvinces),
    takeLatest(GET_MUNICIPALITIES_REQUEST, getMunicipalities),
    takeLatest(
      GET_PROVINCES_MUNICIPALITIES_REQUEST,
      getMunicipalitiesByProvince
    ),
    takeLatest(GET_MUNICIPALITIES_COMMUNES_REQUEST, getCommunesByMunicipality),
    takeLatest(GET_NATIONALITY_REQUEST, getNationality),
    takeLatest(GET_COUNTRIES_REQUEST, getCountries),
    takeLatest(GET_IDIOMS_REQUEST, getIdioms),
    takeLatest(GET_IDIOMS_BY_TYPE_REQUEST, getIdiomsByType),
    takeLatest(
      GET_COURSES_BY_KNOWLEDGE_AREA_REQUEST,
      getCourseByKnowledgeAreaRequest
    ),
    takeLatest(GET_SCHOOLINGCYCLE_REQUEST, getSchoolingCycles),
    takeLatest(GET_PROFESSION_REQUEST, getProfessions),
    takeLatest(GET_PROFESSIONAL_ORDERS_REQUEST, getProfessionalOrders),
    takeLatest(GET_JOB_OFFERS_REQUEST, getJobOffers),
    takeLatest(GET_MARITALSTATUS_REQUEST, getMaritalStatus),
    takeLatest(GET_EDUCATIONALINSTITUTIONS_REQUEST, getEducationalInstitutions),
    takeLatest(GET_ACTIVITYSECTOR_REQUEST, getActivitySectors),
    takeLatest(GET_KNOWLEDGE_AREA_REQUEST, getKnowledgeAreas),
    takeLatest(GET_COURSES_REQUEST, getCourses),
    takeLatest(GET_COURSE_REQUEST, getCourse),
    takeLatest(GET_TYPE_FORMATION_REQUEST, getTypeFormations),
    takeLatest(GET_INSTITUTIONTYPES_REQUEST, getInstitutionTypes),
    takeLatest(GET_INSTITUTIONTYPE_REQUEST, getInstitutionType),
    takeLatest(GET_INSTITUTIONEMPLOYEES_REQUEST, getInstitutionEmployees),
    takeLatest(GET_POLITICAL_POSITIONS_REQUEST, getPoliticalPositions),
    takeLatest(GET_POSITIONS_REQUEST, getPositions),
    takeLatest(GET_CATEGORIES_REQUEST, getCategories),
    takeLatest(GET_SPECIALREGIMETYPES_REQUEST, getSpecialRegimeTypes),
    takeLatest(GET_CAREERS_REQUEST, getCareers),
    takeLatest(GET_EMPLOYEESTOASSIGN_REQUEST, getEmployeesToAssign),
    takeLatest(GET_UNIT_REQUEST, getUnit),
    takeLatest(POST_ASSIGNEMPLOYEE_REQUEST, postAssignEmployee),
    takeLatest(UPDATE_ASSIGNEDEMPLOYEE_REQUEST, updateAssignedEmployee),
    takeLatest(DELETE_ASSIGNEDEMPLOYEE_REQUEST, deleteAssignedEmployee),
    takeLatest(PUT_CEASEFUNCTIONS_REQUEST, putCeaseFunctions),
    takeLatest(GET_CONTRACTUALREGIMENSTYPES_REQUEST, getContractualRegimeTypes),
    takeLatest(GET_JURIDICALBONDS_REQUEST, getJuridicalBonds),
    takeLatest(GET_JURIDICALBONDCHANGE_REQUEST, getJuridicalBondChange),
    takeLatest(GET_UNITEMPLOYEES_REQUEST, getUnitEmployees),
    takeLatest(GET_FAMILYKINSHIP_REQUEST, getFamilyKinship),
    takeLatest(GET_DISABILITYTYPES_REQUEST, getDisabilityTypes),
    takeLatest(GET_USERBYBI_REQUEST, getUserByBi),

    takeLatest(
      UPLOAD_LITERARY_QUALIFICATION_DOCUMENT_REQUEST,
      uploadHabilitationFile
    ),
    takeLatest(UPLOAD_TRAINING_DOCUMENT_REQUEST, uploadTrainingFile),
    takeLatest(DELETE_DOCUMENT_REQUEST, deleteDocument),
    takeLatest(GET_DOCUMENT_REQUEST, getDocument),
  ]);
}
