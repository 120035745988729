import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const FileContainer = styled.div`
  // width: 320px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 0px;

  input {
    display: none;
  }
`;
