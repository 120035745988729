import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { ErrorMessage } from './ErrorMessage';
import { Label, LabelContainer } from './Label';
import { Grid } from '@mui/material';

export default function Checkbox({
  name,
  label,
  isMandatory,
  styles = { justifyContent: 'flex-start', alignItems: 'center' },
  ...rest
}) {
  const inputRef = useRef();
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.checked;
      },
      clearValue: (ref) => {
        ref.current.checked = false;
      },
      setValue: (ref, value) => {
        ref.current.checked = value;
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    inputRef.current.checked = defaultValue;
  }, [defaultValue]);

  return (
    <Grid style={containerMargin} container {...styles}>
      <Grid xs={2.5} item>
        <LabelContainer>
          <Label htmlFor={fieldName}>{label}</Label>
          {isMandatory && <span style={{ color: 'red' }}>*</span>}
        </LabelContainer>
      </Grid>
      <Grid xs={2} item ml={!rest.left && '-5%'}>
        <input
          style={inputSize}
          id={fieldName}
          ref={inputRef}
          defaultValue={defaultValue}
          type='checkbox'
          {...rest}
        />
      </Grid>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Grid>
  );
}

const containerMargin = {
  margin: '20px 0',
};

const inputSize = {
  height: 15,
  width: 15,
};
