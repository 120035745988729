export default function b64toBlob(base64Str, sliceSize) {
  var block = base64Str.split(";");
  var contentType = block[0].split(":")[1];
  var b64Data = block[1].split(",")[1];
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }
  var blob = new Blob([JSON.stringify(byteArrays)], { type: contentType });
  var fileOfBlob = new File([blob], `image.${contentType.split("/")[1]}`);
  return fileOfBlob;
}
