import { takeLatest, all, put, call } from "redux-saga/effects";
import {
  GETALL_COUNTRIES_REQUEST,
  GET_ENTITIES_REQUEST,
  GET_EMPLOYEES_BY_INSTITUTION_REQUEST,
  SEARCH_ENTITIES_REQUEST,
  EDIT_ENTITY_REQUEST,
  DELETE_ENTITY_REQUEST,
  DEACTIVATE_ENTITY_REQUEST,
  ACTIVATE_ENTITY_REQUEST,
  ADD_ENTITY_REQUEST,
  GET_ENTITY_REQUEST,
  GET_ENTITY_IMAGE_REQUEST,
  GET_SECONDARY_ENTITIES_REQUEST,
  FILTER_ENTITIES_REQUEST,
  SUBMIT_ENTITY_ACTION_REQUEST,
  UPLOAD_ENTITY_FILES_REQUEST,
} from "./actionTypes";
import {
  searchEntitiesSuccess,
  searchEntitiesError,
  getEntitiesSuccess,
  getEntitiesError,
  getEmployeesByInsitutionSuccess,
  getEmployeesByInsitutionError,
  deleteEntityError,
  deactivateEntityError,
  activateEntityError,
  getEntitySuccess,
  getEntityError,
  getAllCountriesSuccess,
  getAllCountriesError,
  getEntityImageSuccess,
  getEntityImageError,
  setSnackbar,
  getSecondaryEntitiesSuccess,
  getSecondaryEntitiesError,
  addEntitySuccess,
  addEntityError,
  editEntitySuccess,
  editEntityError,
  deleteEntitySuccess,
  deactivateEntitySuccess,
  activateEntitySuccess,
  submitEntityActionError,
  submitEntityActionSuccess,
  uploadEntityFilesSuccess,
  uploadEntityFilesError,
} from "./actionCreators";
import KeycloakService from "../../../services/KeycloakService";
import axios from "../../../services/AxiosService";

export function* getAllCountries() {
  try {
    const response = yield call(axios.get, `/countries?sort=name`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
    });
    const countries = response.data.content.map((country) => {
      return {
        id: country.id,
        name: country.name,
        date: country.createdAt,
      };
    });

    yield put(
      getAllCountriesSuccess({
        data: countries,
        totalPages: response.data.totalPages,
        totalElements: response.data.totalElements,
      })
    );
  } catch (error) {
    yield put(getAllCountriesError(error));
  }
}

export function* getEntityImage(action) {
  try {
    const response = yield call(
      axios.get,
      `/${action.payload.entity}/${action.payload.entityId}/files`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      },
      { responseType: "arraybuffer" }
    );

    if (response.data) {
      let base64ImageString = Buffer.from(response.data, "binary").toString(
        "base64"
      );
      let srcValue = "data:image/png;base64," + base64ImageString;

      yield put(getEntityImageSuccess(srcValue));
    }
  } catch (error) {
    yield put(getEntityImageError(error));
  }
}

export function* addEntityImage(file, entity, entityId) {
  try {
    const formData = new FormData();
    formData.append("file", file);
    yield call(axios.post, `/${entity}/${entityId}/files`, formData, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
        "Content-Type": "multipart/form-data",
      },
    });
    yield getEntities({
      payload: {
        entity,
        queryDslFilters: [
          { label: "size", value: process.env.REACT_APP_TABLE_SIZE },
          { label: "page", value: 0 },
        ],
      },
    });
  } catch (error) {
    yield getEntities({
      payload: {
        entity,
        queryDslFilters: [
          { label: "size", value: process.env.REACT_APP_TABLE_SIZE },
          { label: "page", value: 0 },
        ],
      },
    });
  }
}

export function* getEntities(action) {
  const dslQuery = action.payload.queryDslFilters.reduce(
    (finalStr, field, index) => {
      if (index === 0) {
        return finalStr + `?${field.label}=${field.value}`;
      }
      return finalStr + `&${field.label}=${field.value}`;
    },
    ""
  );

  try {
    const response = yield call(
      axios.get,
      `/${action.payload.entity}${dslQuery}`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );

    yield put(
      getEntitiesSuccess({
        data: response.data,
        totalPages: response.data.totalPages,
        totalElements: response.data.totalElements,
      })
    );
  } catch (error) {
    yield put(getEntitiesError(error));
  }
}

export function* getEmployeesByInsitution(action) {
  const dslQuery = action.payload.queryDslFilters.reduce(
    (finalStr, field, index) => {
      if (index === 0) {
        return finalStr + `?${field.label}=${field.value}`;
      }
      return finalStr + `&${field.label}=${field.value}`;
    },
    ""
  );

  try {
    const response = yield call(
      axios.get,
      `/institutions/${action.payload.entity}/employees${dslQuery}`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );

    yield put(
      getEmployeesByInsitutionSuccess({
        data: response.data,
        totalPages: response.data.totalPages,
        totalElements: response.data.totalElements,
      })
    );
  } catch (error) {
    yield put(getEmployeesByInsitutionError(error));
  }
}

export function* getSecondaryEntities(action) {
  const dslQuery = action.payload.queryDslFilters.reduce(
    (finalStr, field, index) => {
      if (index === 0) {
        return finalStr + `?${field.label}=${field.value}`;
      }
      return finalStr + `&${field.label}=${field.value}`;
    },
    ""
  );

  try {
    const response = yield call(
      axios.get,
      `/${action.payload.entity}${dslQuery}`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );

    yield put(
      getSecondaryEntitiesSuccess({
        data: response.data.content,
        totalPages: response.data.totalPages,
        totalElements: response.data.totalElements,
      })
    );
  } catch (error) {
    yield put(getSecondaryEntitiesError(error));
  }
}

export function* getEntity(action) {
  try {
    const response = yield call(
      axios.get,
      `/${action.payload.entity}/${action.payload.id}`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );

    yield put(
      getEntitySuccess({
        data: response.data,
      })
    );
  } catch (error) {
    yield put(getEntityError(error));
  }
}

export function* addEntity(action) {
  try {
    const response = yield call(
      axios.post,
      `/${action.payload.entity}`,
      action.payload,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    if (!response.data) yield put(addEntityError(response.data.error));
    if (action.payload.hasImage) {
      yield addEntityImage(
        action.payload.image,
        action.payload.entity,
        response.data.id
      );
    } else {
      yield getEntities({
        payload: {
          entity: action.payload.entity,
          queryDslFilters: [
            { label: "size", value: process.env.REACT_APP_TABLE_SIZE },
            { label: "page", value: 0 },
          ],
        },
      });
    }
    if (action.payload.showNotification) {
      yield put(
        setSnackbar({
          snackbarIsOpen: true,
          snackbarMessage: `${action.payload.onAddSuccessMessage}`,
          snackbarType: "success",
        })
      );
    }
    yield put(addEntitySuccess());
  } catch (error) {
    yield getEntities({
      payload: {
        entity: action.payload.entity,
        queryDslFilters: [
          { label: "size", value: process.env.REACT_APP_TABLE_SIZE },
          { label: "page", value: 0 },
        ],
      },
    });
    yield put(addEntityError());
  }
}

export function* editEntity(action) {
  try {
    const entityId = !action.payload.noId ? action.payload.id : "";
    const response = yield call(
      axios.put,
      `/${action.payload.entity}/${entityId}`,
      action.payload,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    if (!response.data) yield put(editEntityError(response.data.error));
    if (action.payload.hasImage) {
      yield addEntityImage(
        action.payload.image,
        action.payload.entity,
        response.data.id
      );
    } else {
      yield getEntities({
        payload: {
          entity: action.payload.entity,
          queryDslFilters: [
            { label: "size", value: process.env.REACT_APP_TABLE_SIZE },
            { label: "page", value: 0 },
          ],
        },
      });
    }
    if (action.payload.showNotification) {
      yield put(
        setSnackbar({
          snackbarIsOpen: true,
          snackbarMessage: `${action.payload.onEditSuccessMessage}`,
          snackbarType: "success",
        })
      );
    }
    yield put(editEntitySuccess());
  } catch (error) {
    yield getEntities({
      payload: {
        entity: action.payload.entity,
        queryDslFilters: [
          { label: "size", value: process.env.REACT_APP_TABLE_SIZE },
          { label: "page", value: 0 },
        ],
      },
    });
    yield put(editEntityError());
  }
}

export function* deactivateEntity(action) {
  try {
    const response = yield call(
      axios.put,
      `/${action.payload.entity}/disable/${action.payload.id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    if (!response.data) yield put(deactivateEntityError(response.data.error));

    if (action.payload.showNotification) {
      yield put(
        setSnackbar({
          snackbarIsOpen: true,
          snackbarMessage: `${action.payload.onDeleteSuccessMessage}`,
          snackbarType: "success",
        })
      );
    }
    yield getEntities({
      payload: {
        entity: action.payload.entity,
        queryDslFilters: [
          { label: "size", value: process.env.REACT_APP_TABLE_SIZE },
          { label: "page", value: 0 },
          { label: "includeStatus", value: "DISABLED" },
        ],
      },
    });
    yield put(deactivateEntitySuccess());
  } catch (error) {
    yield getEntities({
      payload: {
        entity: action.payload.entity,
        queryDslFilters: [
          { label: "size", value: process.env.REACT_APP_TABLE_SIZE },
          { label: "page", value: 0 },
        ],
      },
    });
    yield put(deactivateEntityError());
  }
}

export function* activateEntity(action) {
  try {
    const response = yield call(
      axios.put,
      `/${action.payload.entity}/active/${action.payload.id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    if (!response.data) yield put(activateEntityError(response.data.error));

    if (action.payload.showNotification) {
      yield put(
        setSnackbar({
          snackbarIsOpen: true,
          snackbarMessage: `${action.payload.onDeleteSuccessMessage}`,
          snackbarType: "success",
        })
      );
    }
    yield getEntities({
      payload: {
        entity: action.payload.entity,
        queryDslFilters: [
          { label: "size", value: process.env.REACT_APP_TABLE_SIZE },
          { label: "page", value: 0 },
          { label: "includeStatus", value: "DISABLED" },
        ],
      },
    });
    yield put(activateEntitySuccess());
  } catch (error) {
    yield getEntities({
      payload: {
        entity: action.payload.entity,
        queryDslFilters: [
          { label: "size", value: process.env.REACT_APP_TABLE_SIZE },
          { label: "page", value: 0 },
        ],
      },
    });
    yield put(activateEntityError());
  }
}

export function* deleteEntity(action) {
  try {
    const response = yield call(
      axios.delete,
      `/${action.payload.entity}/${action.payload.id}`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    if (!response.data) yield put(deleteEntityError(response.data.error));
    if (action.payload.showNotification) {
      yield put(
        setSnackbar({
          snackbarIsOpen: true,
          snackbarMessage: `${action.payload.onDeleteSuccessMessage}`,
          snackbarType: "success",
        })
      );
    }

    yield getEntities({
      payload: {
        entity: action.payload.entity,
        queryDslFilters: [
          { label: "size", value: process.env.REACT_APP_TABLE_SIZE },
          { label: "page", value: 0 },
        ],
      },
    });
    yield put(deleteEntitySuccess());
  } catch (error) {
    yield put(deleteEntityError(error));
  }
}

export function* submitEntityAction(action) {
  try {
    const response = yield call(
      axios.put,
      `/${action.payload.entity}/${action.payload.id}/${action.payload.action}`,
      action.payload.body ? action.payload.body : action.payload,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );
    if (!response.data) return yield put(submitEntityActionError(response));
    yield getEntities({
      payload: {
        entity: action.payload.entity,
        queryDslFilters: [
          { label: "size", value: process.env.REACT_APP_TABLE_SIZE },
          { label: "page", value: 0 },
        ],
      },
    });
    if (action.payload.showNotification) {
      yield put(
        setSnackbar({
          snackbarIsOpen: true,
          snackbarMessage: `${action.payload.onSubmitActionSuccessMessage}`,
          snackbarType: "success",
        })
      );
    }
    yield put(submitEntityActionSuccess(response.data));
  } catch (error) {
    yield getEntities({
      payload: {
        entity: action.payload.entity,
        queryDslFilters: [
          { label: "size", value: process.env.REACT_APP_TABLE_SIZE },
          { label: "page", value: 0 },
        ],
      },
    });
    if (action.payload.showNotification) {
      yield put(
        setSnackbar({
          snackbarIsOpen: true,
          snackbarMessage: `${error.response.data.details.debugMessage}`,
          snackbarType: "error",
        })
      );
    }
    yield put(submitEntityActionError(error));
  }
}

export function* searchEntities(action) {
  const dslQuery = action.payload.queryDslFilters.reduce(
    (finalStr, field, index) => {
      if (index === 0) {
        return finalStr + `?${field.label}=${field.value}`;
      }
      return finalStr + `&${field.label}=${field.value}`;
    },
    ""
  );
  let searchQuery = "";
  if (action.payload.withDsl) {
    searchQuery = action.payload.searchFilters.reduce((finalStr, field) => {
      if (
        field === "createdAt" &&
        isNaN(action.payload.query.replaceAll("-", ""))
      ) {
        return finalStr;
      }
      return finalStr + `&${field}=${action.payload.query}`;
    }, "");
  }

  const url = action.payload.withDsl
    ? `/${action.payload.searchUrl}${dslQuery}${searchQuery}`
    : `/${action.payload.searchUrl}/${action.payload.query}`;

  try {
    const response = yield call(axios.get, url, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
      },
    });

    yield put(
      searchEntitiesSuccess({
        data: response.data,
        totalPages: response.data.totalPages,
        totalElements: response.data.totalElements,
      })
    );
  } catch (error) {
    yield put(searchEntitiesError(error));
  }
}

export function* filterEntities(action) {
  const dslQuery = action.payload.queryDslFilters.reduce(
    (finalStr, field, index) => {
      if (index === 0) {
        return finalStr + `?${field.label}=${field.value}`;
      }
      return finalStr + `&${field.label}=${field.value}`;
    },
    ""
  );

  try {
    const response = yield call(
      axios.get,
      `/${action.payload.entity}${dslQuery}`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }
    );

    yield put(
      searchEntitiesSuccess({
        data: response.data,
        totalPages: response.data.totalPages,
        totalElements: response.data.totalElements,
      })
    );
  } catch (error) {
    yield put(searchEntitiesError(error));
  }
}

export function* uploadEntityFiles(action) {
  try {
    const formData = new FormData();
    formData.append("file", action.payload.file);
    const response = yield call(
      axios.post,
      `/${action.payload.entity}/${action.payload.uploadUrl}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (!response.data) return yield put(uploadEntityFilesError(response));
    yield put(uploadEntityFilesSuccess());
    yield getEntities({
      payload: {
        entity: action.payload.entity,
        queryDslFilters: [
          { label: "size", value: process.env.REACT_APP_TABLE_SIZE },
          { label: "page", value: 0 },
        ],
      },
    });
    if (action.payload.showNotification) {
      yield put(
        setSnackbar({
          snackbarIsOpen: true,
          snackbarMessage: `${action.payload.onSuccessMessage}`,
          snackbarType: "success",
        })
      );
    }
  } catch (error) {
    yield put(uploadEntityFilesError(error));
    // yield getEntities({
    //   payload: {
    //     entity,
    //     queryDslFilters: [
    //       { label: "size", value: process.env.REACT_APP_TABLE_SIZE },
    //       { label: "page", value: 0 },
    //     ],
    //   },
    // });
  }
}

export default function* adminSagas() {
  yield all([
    takeLatest(GETALL_COUNTRIES_REQUEST, getAllCountries),
    takeLatest(GET_ENTITY_IMAGE_REQUEST, getEntityImage),
    takeLatest(GET_ENTITIES_REQUEST, getEntities),
    takeLatest(GET_EMPLOYEES_BY_INSTITUTION_REQUEST, getEmployeesByInsitution),
    takeLatest(GET_SECONDARY_ENTITIES_REQUEST, getSecondaryEntities),
    takeLatest(GET_ENTITY_REQUEST, getEntity),
    takeLatest(ADD_ENTITY_REQUEST, addEntity),
    takeLatest(EDIT_ENTITY_REQUEST, editEntity),
    takeLatest(DELETE_ENTITY_REQUEST, deleteEntity),
    takeLatest(DEACTIVATE_ENTITY_REQUEST, deactivateEntity),
    takeLatest(ACTIVATE_ENTITY_REQUEST, activateEntity),
    takeLatest(SUBMIT_ENTITY_ACTION_REQUEST, submitEntityAction),
    takeLatest(SEARCH_ENTITIES_REQUEST, searchEntities),
    takeLatest(FILTER_ENTITIES_REQUEST, filterEntities),
    takeLatest(UPLOAD_ENTITY_FILES_REQUEST, uploadEntityFiles),
  ]);
}
