import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import { CheckOutlined } from "@mui/icons-material";

const SideTable = ({
  items,
  displayColumns,
  onRowClicked,
  setSideTablePageIndex,
  institutionEmployeesTotalPages,
  currentPage, // Adicione essa prop para indicar a página atual
  totalElements, // Para mostrar o total de elementos, se necessário
  query
}) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: "13px !important",
      fontWeight: "bold !important",
      color: "#3B4144",
      borderBottom: `2px solid ${theme.palette.primary.dark}`,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 13,
      color: "#3B4144",
      borderBottom: "1px solid #F6F6F6",
    },
    [`.LinesEllipsis`]: {
      fontSize: "13px !important",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:first-of-type td, &:first-of-type th": {
      borderBottom: "1px solid #F6F6F6",
    },
    cursor: "pointer",
  }));

  const regex = /^(\d{9})([a-zA-Z]{2})(\d{3})$/;

  const theme = useTheme();

  return (
    <>
      {items?.length > 0 ? (
        <Table sx={{ marginLeft: "1rem" }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Nome</StyledTableCell>
              <StyledTableCell>BI</StyledTableCell>
              <StyledTableCell>Alocado</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row, index) => (
              <StyledTableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
                onClick={() => onRowClicked(row, row.status)}
              >
                {displayColumns.map((col, idx) => (
                  <StyledTableCell
                    key={idx}
                    align="left"
                    sx={{
                      color:
                        row?.status === "ACTIVE"
                          ? `${theme.palette.text.hint} !important`
                          : theme.palette.text.primary,
                    }}
                  >
                    {eval(`row?.${col?.field.split("[")[0]}`) !== undefined
                      ? eval(`row?.${col?.field}`)
                      : ""}
                  </StyledTableCell>
                ))}
                <StyledTableCell align="center">
                  {row?.status === "ACTIVE" && (
                    <CheckOutlined
                      style={{
                        fontSize: "18px",
                        paddingRight: "10px",
                        color: theme.palette.text.hint,
                      }}
                    />
                  )}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <div
          style={{
            marginTop: "1rem",
            marginBottom: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <span style={{ color: "#A0A0A0", fontSize: "14px" }}>
            Nenhum quadro encontrado!
          </span>
        </div>
      )}

      <div style={{ marginTop: "1rem", marginBottom: "1rem", display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "20px" }}>
        {
          items.length === 0 && regex.test(query) ?
            <span style={{ color: "#A0A0A0", fontSize: "14px" }}>
              Nenhum resultado encontrado!
            </span>
            :

            <>
              <span style={{ color: "#A0A0A0", fontSize: "14px" }}>
                Total - <strong>{totalElements}</strong>
              </span>
              <Pagination
                shape="rounded"
                sx={{
                  "& .MuiPaginationItem-root.Mui-selected": {
                    backgroundColor: theme.palette.primary.main,
                    color: "#fff",
                  },
                  "& .MuiPaginationItem-root": {
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    backgroundColor: "#f9f9f9",
                  },
                  "& .MuiPagination-ul": {
                    border: "1px solid #E0E2E3",
                    borderRadius: "4px",
                  },
                }}
                count={institutionEmployeesTotalPages}
                page={currentPage + 1}
                onChange={(_, page) => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  setSideTablePageIndex(page - 1);
                }}
              />
            </>
        }
      </div>
    </>
  );
};

export default SideTable;
