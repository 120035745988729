import * as Yup from "yup";
import CountrySchema from "./CountrySchema";

const ProvinceSchema = Yup.object().shape({
  id: Yup.string().optional().nullable(),
  name: Yup.string().when("id", {
    is: (id) => id === null || id === undefined,
    then: Yup.string().required("O nome é obrigatório"),
  }),
  country: CountrySchema.transform((value, original) => {
    return value === null ? { id: "" } : value;
  }).concat(
    Yup.object().shape({
      id: Yup.string().required("O país é obrigatório."),
    })
  ),
});

export default ProvinceSchema;
