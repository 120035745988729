export const GET_EMPLOYEE_REQUEST = "@USERS/GETEMPLOYEE_REQUEST";
export const GET_EMPLOYEE_SUCCESS = "@USERS/GETEMPLOYEE_SUCCESS";
export const GET_EMPLOYEE_FAILURE = "@USERS/GETEMPLOYEE_FAILURE";
export const GET_EMPLOYEE_SECTION_REQUEST = "@USERS/GETEMPLOYEESECTION_REQUEST";
export const GET_EMPLOYEE_SECTION2_REQUEST =
  "@USERS/GETEMPLOYEESECTION2_REQUEST";
export const GET_EMPLOYEE_SECTION3_REQUEST =
  "@USERS/GETEMPLOYEESECTION3_REQUEST";
export const GET_EMPLOYEE_SECTION4_REQUEST =
  "@USERS/GETEMPLOYEESECTION4_REQUEST";
export const GET_EMPLOYEE_SECTION5_REQUEST =
  "@USERS/GETEMPLOYEESECTION5_REQUEST";
export const GET_EMPLOYEE_SECTION6_REQUEST =
  "@USERS/GETEMPLOYEESECTION6_REQUEST";
export const GET_EMPLOYEE_SECTION7_REQUEST =
  "@USERS/GETEMPLOYEESECTION7_REQUEST";
export const GET_EMPLOYEE_SECTION8_REQUEST =
  "@USERS/GETEMPLOYEESECTION8_REQUEST";
export const GET_EMPLOYEE_SECTION_SUCCESS = "@USERS/GETEMPLOYEEECTION_SUCCESS";
export const GET_EMPLOYEE_SECTION2_SUCCESS =
  "@USERS/GETEMPLOYEEECTION2_SUCCESS";
export const GET_EMPLOYEE_SECTION3_SUCCESS =
  "@USERS/GETEMPLOYEEECTION3_SUCCESS";
export const GET_EMPLOYEE_SECTION4_SUCCESS =
  "@USERS/GETEMPLOYEEECTION4_SUCCESS";
export const GET_EMPLOYEE_SECTION5_SUCCESS =
  "@USERS/GETEMPLOYEEECTION5_SUCCESS";
export const GET_EMPLOYEE_SECTION6_SUCCESS =
  "@USERS/GETEMPLOYEEECTION6_SUCCESS";
export const GET_EMPLOYEE_SECTION7_SUCCESS =
  "@USERS/GETEMPLOYEEECTION7_SUCCESS";
export const GET_EMPLOYEE_SECTION8_SUCCESS =
  "@USERS/GETEMPLOYEEECTION8_SUCCESS";
export const GET_EMPLOYEE_SECTION_FAILURE = "@USERS/GETEMPLOYEEECTION_FAILURE";

export const GET_POLITICAL_POSITIONS_REQUEST =
  "@USERS/GET_POLITICAL_POSITIONS_REQUEST";
export const GET_POLITICAL_POSITIONS_SUCCESS =
  "@USERS/GET_POLITICAL_POSITIONS_SUCCESS";
export const GET_POLITICAL_POSITIONS_FAILURE =
  "@USERS/GET_POLITICAL_POSITIONS_FAILURE";

export const GET_INSTITUTION_REQUEST = "@USERS/GETINSTITUTION_REQUEST";
export const GET_INSTITUTION_SUCCESS = "@USERS/GETINSTITUTION_SUCCESS";
export const GET_INSTITUTION_FAILURE = "@USERS/GETINSTITUTION_FAILURE";

export const GETALL_INSTITUTIONS_REQUEST = "@USERS/GETALL_INSTITUTION_REQUEST";
export const GETALL_INSTITUTIONS_SUCCESS = "@USERS/GETALL_INSTITUTION_SUCCESS";
export const GETALL_INSTITUTIONS_FAILURE = "@USERS/GETALL_INSTITUTION_FAILURE";

export const GETALL_INSTITUTIONS_PRIVATE_REQUEST = "@USERS/GETALL_INSTITUTION_PRIVATE_REQUEST";
export const GETALL_INSTITUTIONS_PRIVATE_SUCCESS = "@USERS/GETALL_INSTITUTION_PRIVATE_SUCCESS";
export const GETALL_INSTITUTIONS_PRIVATE_FAILURE = "@USERS/GETALL_INSTITUTION_PRIVATE_FAILURE";

export const GETALL_TYPES_ACCOMPLISHMENTS_REQUEST =
  "@USERS/GETALL_TYPES_ACCOMPLISHMENTS_REQUEST";
export const GETALL_TYPES_ACCOMPLISHMENTS_SUCCESS =
  "@USERS/GETALL_TYPES_ACCOMPLISHMENTS_SUCCESS";
export const GETALL_TYPES_ACCOMPLISHMENTS_FAILURE =
  "@USERS/GETALL_TYPES_ACCOMPLISHMENTS_FAILURE";

export const GETALL_TYPES_VOLUNTEERS_REQUEST =
  "@USERS/GETALL_TYPES_VOLUNTEERS_REQUEST";
export const GETALL_TYPES_VOLUNTEERS_SUCCESS =
  "@USERS/GETALL_TYPES_VOLUNTEERS_SUCCESS";
export const GETALL_TYPES_VOLUNTEERS_FAILURE =
  "@USERS/GETALL_TYPES_VOLUNTEERS_FAILURE";

export const POST_EMPLOYEE_REQUEST = "@USERS/POST_EMPLOYEE_REQUEST";
export const POST_EMPLOYEE_SUCCESS = "@USERS/POST_EMPLOYEE_SUCCESS";
export const POST_EMPLOYEE_FAILURE = "@USERS/POST_EMPLOYEE_FAILURE";

export const EDIT_EMPLOYEE_REQUEST = "@USERS/EDITEMPLOYEE_REQUEST";
export const EDIT_EMPLOYEE_SUCCESS = "@USERS/EDITEMPLOYEE_SUCCESS";
export const EDIT_EMPLOYEE_FAILURE = "@USERS/EDITEMPLOYEE_FAILURE";
export const EDIT_EMPLOYEE_SECTION_REQUEST =
  "@USERS/EDITEMPLOYEESECTION_REQUEST";
export const EDIT_EMPLOYEE_SECTION_SUCESS = "@USERS/EDITEMPLOYEESECTION_SUCESS";
export const EDIT_EMPLOYEE_SECTION_FAILURE =
  "@USERS/EDITEMPLOYEESECTION_FAILURE";

export const EDIT_INSTITUTION_REQUEST = "@USERS/EDITINSTITUTION_REQUEST";
export const EDIT_INSTITUTION_SUCCESS = "@USERS/EDITINSTITUTION_SUCCESS";
export const EDIT_INSTITUTION_FAILURE = "@USERS/EDITINSTITUTION_FAILURE";

export const GET_PROVINCES_REQUEST = "@USERS/GETPROVINCES_REQUEST";
export const GET_PROVINCES_SUCCESS = "@USERS/GETPROVINCES_SUCCESS";
export const GET_PROVINCES_FAILURE = "@USERS/GETPROVINCES_FAILURE";

export const GET_PROVINCES_MUNICIPALITIES_REQUEST =
  "@USERS/GETPROVINCES_MUNICIPALITIES_REQUEST";
export const GET_PROVINCES_MUNICIPALITIES_SUCCESS =
  "@USERS/GETPROVINCES_MUNICIPALITIES_SUCCESS";
export const GET_PROVINCES_MUNICIPALITIES_FAILURE =
  "@USERS/GETPROVINCES_MUNICIPALITIES_FAILURE";

export const GET_MUNICIPALITIES_REQUEST = "@USERS/GETMUNICIPALITIES_REQUEST";
export const GET_MUNICIPALITIES_SUCCESS = "@USERS/GETMUNICIPALITIES_SUCCESS";
export const GET_MUNICIPALITIES_FAILURE = "@USERS/GETMUNICIPALITIES_FAILURE";

export const GET_DISABILITYTYPES_REQUEST = "@USERS/GETDISABILITYTYPES_REQUEST";
export const GET_DISABILITYTYPES_SUCCESS = "@USERS/GETDISABILITYTYPES_SUCCESS";
export const GET_DISABILITYTYPES_FAILURE = "@USERS/GETDISABILITYTYPES_FAILURE";

export const GET_MUNICIPALITIES_COMMUNES_REQUEST =
  "@USERS/GET_MUNICIPALITIES_COMMUNES_REQUEST";
export const GET_MUNICIPALITIES_COMMUNES_SUCCESS =
  "@USERS/GETMUNICIPALITIES_COMMUNES_SUCCESS";
export const GET_MUNICIPALITIES_COMMUNES_FAILURE =
  "@USERS/GETMUNICIPALITIES_COMMUNES_FAILURE";

export const SET_MUNICIPALITIES = "@USERS/SETMUNICIPALITIES";
export const SET_DISABILITYTYPES = "@USERS/SET_DISABILITYTYPES";

export const GET_NATIONALITY_REQUEST = "@USERS/GETNATIONALITY_REQUEST";
export const GET_NATIONALITY_SUCCESS = "@USERS/GETNATIONALITY_SUCCESS";
export const GET_NATIONALITY_FAILURE = "@USERS/GETNATIONALITY_FAILURE";

export const GET_COUNTRIES_REQUEST = "@USERS/GETCOUNTRIES_REQUEST";
export const GET_COUNTRIES_SUCCESS = "@USERS/GETCOUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAILURE = "@USERS/GETCOUNTRIES_FAILURE";

export const GET_IDIOMS_REQUEST = "@USERS/GETIDIOMS_REQUEST";
export const GET_IDIOMS_SUCCESS = "@USERS/GETIDIOMS_SUCCESS";
export const GET_IDIOMS_FAILURE = "@USERS/GETIDIOMS_FAILURE";

export const GET_IDIOMS_BY_TYPE_REQUEST = "@USERS/GETIDIOMS_BY_TYPE_REQUEST";
export const GET_IDIOMS_BY_TYPE_SUCCESS = "@USERS/GETIDIOMS_BY_TYPE_SUCCESS";
export const GET_IDIOMS_BY_TYPE_FAILURE = "@USERS/GETIDIOMS_BY_TYPE_FAILURE";

export const GET_COURSES_BY_KNOWLEDGE_AREA_REQUEST =
  "@USERS/GET_COURSES_BY_KNOWLEDGE_AREA_REQUEST";
export const GET_COURSES_BY_KNOWLEDGE_AREA_SUCCESS =
  "@USERS/GET_COURSES_BY_KNOWLEDGE_AREA_SUCCESS";
export const GET_COURSES_BY_KNOWLEDGE_AREA_FAILURE =
  "@USERS/GET_COURSES_BY_KNOWLEDGE_AREA_FAILURE";

export const GET_COURSES_REQUEST = "@USERS/GETCOURSES_REQUEST";
export const GET_COURSES_SUCCESS = "@USERS/GETCOURSES_SUCCESS";
export const GET_COURSES_FAILURE = "@USERS/GETCOURSES_FAILURE";

export const GET_COURSE_REQUEST = "@USERS/GETCOURSE_REQUEST";
export const GET_COURSE_SUCCESS = "@USERS/GETCOURSE_SUCCESS";
export const GET_COURSE_FAILURE = "@USERS/GETCOURSE_FAILURE";

export const GET_KNOWLEDGE_AREA_REQUEST = "@USERS/GETKNOWLEDGE_AREA_REQUEST";
export const GET_KNOWLEDGE_AREA_SUCCESS = "@USERS/GETKNOWLEDGE_AREA_SUCCESS";
export const GET_KNOWLEDGE_AREA_FAILURE = "@USERS/GETKNOWLEDGE_AREA_FAILURE";

export const GET_TYPE_FORMATION_REQUEST = "@USERS/GETTYPE_FORMATION_REQUEST";
export const GET_TYPE_FORMATION_SUCCESS = "@USERS/GETTYPE_FORMATION_SUCCESS";
export const GET_TYPE_FORMATION_FAILURE = "@USERS/GETTYPE_FORMATION_FAILURE";

export const GET_SCHOOLINGCYCLE_REQUEST = "@USERS/GETSCHOOLINGCYCLE_REQUEST";
export const GET_SCHOOLINGCYCLE_SUCCESS = "@USERS/GETSCHOOLINGCYCLE_SUCCESS";
export const GET_SCHOOLINGCYCLE_FAILURE = "@USERS/GETSCHOOLINGCYCLE_FAILURE";

export const GET_PROFILEPICTURE_REQUEST = "@USERS/GETPROFILEPICTURE_REQUEST";
export const GET_PROFILEPICTURE_SUCCESS = "@USERS/GETPROFILEPICTURE_SUCCESS";
export const GET_PROFILEPICTURE_FAILURE = "@USERS/GETPROFILEPICTURE_FAILURE";

export const GET_MARITALSTATUS_REQUEST = "@USERS/GETMARITALSTATUS_REQUEST";
export const GET_MARITALSTATUS_SUCCESS = "@USERS/GETMARITALSTATUS_SUCCESS";
export const GET_MARITALSTATUS_FAILURE = "@USERS/GETMARITALSTATUS_FAILURE";

export const GET_PROFESSION_REQUEST = "@USERS/GETPROFESSION_REQUEST";
export const GET_PROFESSION_SUCCESS = "@USERS/GETPROFESSION_SUCCESS";
export const GET_PROFESSION_FAILURE = "@USERS/GETPROFESSION_FAILURE";

export const GET_PROFESSIONAL_ORDERS_REQUEST =
  "@USERS/GETPROFESSIONAL_ORDERS_REQUEST";
export const GET_PROFESSIONAL_ORDERS_SUCCESS =
  "@USERS/GETPROFESSIONAL_ORDERS_SUCCESS";
export const GET_PROFESSIONAL_ORDERS_FAILURE =
  "@USERS/GETPROFESSIONAL_ORDERS_FAILURE";

export const GET_JOB_OFFERS_REQUEST = "@USERS/GETJOB_OFFERS_REQUEST";
export const GET_JOB_OFFERS_SUCCESS = "@USERS/GETJOB_OFFERS_SUCCESS";
export const GET_JOB_OFFERS_FAILURE = "@USERS/GETJOB_OFFERS_FAILURE";

export const GET_EDUCATIONALINSTITUTIONS_REQUEST =
  "@USERS/GETEDUCATIONALINSTITUTIONS_REQUEST";
export const GET_EDUCATIONALINSTITUTIONS_SUCCESS =
  "@USERS/GETEDUCATIONALINSTITUTIONS_SUCCESS";
export const GET_EDUCATIONALINSTITUTIONS_FAILURE =
  "@USERS/GETEDUCATIONALINSTITUTIONS_FAILURE";

export const GET_ACTIVITYSECTOR_REQUEST = "@USERS/GETACTIVITYSECTOR_REQUEST";
export const GET_ACTIVITYSECTOR_SUCCESS = "@USERS/GETACTIVITYSECTOR_SUCCESS";
export const GET_ACTIVITYSECTOR_FAILURE = "@USERS/GETACTIVITYSECTOR_FAILURE";

export const GET_INSTITUTIONTYPES_REQUEST =
  "@USERS/GETINSTITUTIONTYPES_REQUEST";
export const GET_INSTITUTIONTYPES_SUCCESS =
  "@USERS/GETINSTITUTIONTYPES_SUCCESS";
export const GET_INSTITUTIONTYPES_FAILURE =
  "@USERS/GETINSTITUTIONTYPES_FAILURE";

export const GET_INSTITUTIONTYPE_REQUEST = "@USERS/GETINSTITUTIONTYPE_REQUEST";
export const GET_INSTITUTIONTYPE_SUCCESS = "@USERS/GETINSTITUTIONTYPE_SUCCESS";
export const GET_INSTITUTIONTYPE_FAILURE = "@USERS/GETINSTITUTIONTYPE_FAILURE";

export const GET_INSTITUTIONEMPLOYEES_REQUEST =
  "@USERS/GETINSTITUTIONEMPLOYEES_REQUEST";
export const GET_INSTITUTIONEMPLOYEES_SUCCESS =
  "@USERS/GETINSTITUTIONEMPLOYEES_SUCCESS";
export const GET_INSTITUTIONEMPLOYEES_FAILURE =
  "@USERS/GETINSTITUTIONEMPLOYEES_FAILURE";

export const GET_TOTAL_INSTITUTIONEMPLOYEES_REQUEST =
  "@USERS/GETTOTALINSTITUTIONEMPLOYEES_REQUEST";
export const GET_TOTAL_INSTITUTIONEMPLOYEES_SUCCESS =
  "@USERS/GETTOTALINSTITUTIONEMPLOYEES_SUCCESS";
export const GET_TOTAL_INSTITUTIONEMPLOYEES_FAILURE =
  "@USERS/GETTOTALINSTITUTIONEMPLOYEES_FAILURE";

export const SEARCH_INSTITUTIONEMPLOYEES_REQUEST =
  "@USERS/SEARCHINSTITUTIONEMPLOYEES_REQUEST";
export const SEARCH_INSTITUTIONEMPLOYEES_SUCCESS =
  "@USERS/SEARCHINSTITUTIONEMPLOYEES_SUCCESS";
export const SEARCH_INSTITUTIONEMPLOYEES_FAILURE =
  "@USERS/SEARCHINSTITUTIONEMPLOYEES_FAILURE";

export const FILTER_INSTITUTIONEMPLOYEES_REQUEST =
  "@USERS/FILTERINSTITUTIONEMPLOYEES_REQUEST";
export const FILTER_INSTITUTIONEMPLOYEES_SUCCESS =
  "@USERS/FILTERINSTITUTIONEMPLOYEES_SUCCESS";
export const FILTER_INSTITUTIONEMPLOYEES_FAILURE =
  "@USERS/FILTERINSTITUTIONEMPLOYEES_FAILURE";

export const SET_SNACKBAR = "@ADMIN/SET_SNACKBAR";

export const GET_POSITIONS_REQUEST = "@USERS/GET_POSITIONS_REQUEST";
export const GET_POSITIONS_SUCCESS = "@USERS/GET_POSITIONS_SUCCESS";
export const GET_POSITIONS_FAILURE = "@USERS/GET_POSITIONS_FAILURE";

export const GET_CATEGORIES_REQUEST = "@USERS/GET_CATEGORIES_REQUEST";
export const GET_CATEGORIES_SUCCESS = "@USERS/GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAILURE = "@USERS/GET_CATEGORIES_FAILURE";

export const GET_SPECIALREGIMETYPES_REQUEST =
  "@USERS/GET_SPECIALREGIMETYPES_REQUEST";
export const GET_SPECIALREGIMETYPES_SUCCESS =
  "@USERS/GET_SPECIALREGIMETYPES_SUCCESS";
export const GET_SPECIALREGIMETYPES_FAILURE =
  "@USERS/GET_SPECIALREGIMETYPES_FAILURE";

export const GET_CAREERS_REQUEST = "@USERS/GET_CAREERS_REQUEST";
export const GET_CAREERS_SUCCESS = "@USERS/GET_CAREERS_SUCCESS";
export const GET_CAREERS_FAILURE = "@USERS/GET_CAREERS_FAILURE";

export const GET_EMPLOYEESTOASSIGN_REQUEST =
  "@USERS/GET_EMPLOYEESTOASSIGN_REQUEST";
export const GET_EMPLOYEESTOASSIGN_SUCCESS =
  "@USERS/GET_EMPLOYEESTOASSIGN_SUCCESS";
export const GET_EMPLOYEESTOASSIGN_FAILURE =
  "@USERS/GET_EMPLOYEESTOASSIGN_FAILURE";

export const GET_UNIT_REQUEST = "@USERS/GET_UNIT_REQUEST";
export const GET_UNIT_SUCCESS = "@USERS/GET_UNIT_SUCCESS";
export const GET_UNIT_FAILURE = "@USERS/GET_UNIT_FAILURE";

export const POST_ASSIGNEMPLOYEE_REQUEST = "@USERS/POST_ASSIGNEMPLOYEE_REQUEST";
export const POST_ASSIGNEMPLOYEE_SUCCESS = "@USERS/POST_ASSIGNEMPLOYEE_SUCCESS";
export const POST_ASSIGNEMPLOYEE_FAILURE = "@USERS/POST_ASSIGNEMPLOYEE_FAILURE";

export const UPDATE_ASSIGNEDEMPLOYEE_REQUEST =
  "@USERS/UPDATE_ASSIGNEDEMPLOYEE_REQUEST";
export const UPDATE_ASSIGNEDEMPLOYEE_SUCCESS =
  "@USERS/UPDATE_ASSIGNEDEMPLOYEE_SUCCESS";
export const UPDATE_ASSIGNEDEMPLOYEE_FAILURE =
  "@USERS/UPDATE_ASSIGNEDEMPLOYEE_FAILURE";

export const DELETE_ASSIGNEDEMPLOYEE_REQUEST =
  "@USERS/DELETE_ASSIGNEDEMPLOYEE_REQUEST";
export const DELETE_ASSIGNEDEMPLOYEE_SUCCESS =
  "@USERS/DELETE_ASSIGNEDEMPLOYEE_SUCCESS";
export const DELETE_ASSIGNEDEMPLOYEE_FAILURE =
  "@USERS/DELETE_ASSIGNEDEMPLOYEE_FAILURE";

export const PUT_CEASEFUNCTIONS_REQUEST = "@USERS/PUT_CEASEFUNCTIONS_REQUEST";
export const PUT_CEASEFUNCTIONS_SUCCESS = "@USERS/PUT_CEASEFUNCTIONS_SUCCESS";
export const PUT_CEASEFUNCTIONS_FAILURE = "@USERS/PUT_CEASEFUNCTIONS_FAILURE";

export const GET_CONTRACTUALREGIMENSTYPES_REQUEST =
  "@USERS/GET_CONTRACTUALREGIMENSTYPES_REQUEST";
export const GET_CONTRACTUALREGIMENSTYPES_SUCCESS =
  "@USERS/GET_CONTRACTUALREGIMENSTYPES_SUCCESS";
export const GET_CONTRACTUALREGIMENSTYPES_FAILURE =
  "@USERS/GET_CONTRACTUALREGIMENSTYPES_FAILURE";

export const GET_JURIDICALBONDS_REQUEST = "@USERS/GET_JURIDICALBONDS_REQUEST";
export const GET_JURIDICALBONDS_SUCCESS = "@USERS/GET_JURIDICALBONDS_SUCCESS";
export const GET_JURIDICALBONDS_FAILURE = "@USERS/GET_JURIDICALBONDS_FAILURE";

export const GET_JURIDICALBONDCHANGE_REQUEST =
  "@USERS/GET_JURIDICALBONDCHANGE_REQUEST";
export const GET_JURIDICALBONDCHANGE_SUCCESS =
  "@USERS/GET_JURIDICALBONDCHANGE_SUCCESS";
export const GET_JURIDICALBONDCHANGE_FAILURE =
  "@USERS/GET_JURIDICALBONDCHANGE_FAILURE";

export const GET_UNITEMPLOYEES_REQUEST = "@USERS/GET_UNITEMPLOYEES_REQUEST";
export const GET_UNITEMPLOYEES_SUCCESS = "@USERS/GET_UNITEMPLOYEES_SUCCESS";
export const GET_UNITEMPLOYEES_FAILURE = "@USERS/GET_UNITEMPLOYEES_FAILURE";

export const GET_FAMILYKINSHIP_REQUEST = "@USERS/GET_FAMILYKINSHIP_REQUEST";
export const GET_FAMILYKINSHIP_SUCCESS = "@USERS/GET_FAMILYKINSHIP_SUCCESS";
export const GET_FAMILYKINSHIP_FAILURE = "@USERS/GET_FAMILYKINSHIP_FAILURE";

export const GET_USERBYBI_REQUEST = "@USERS/GET_USERBYBI_REQUEST";
export const GET_USERBYBI_SUCCESS = "@USERS/GET_USERBYBI_SUCCESS";
export const GET_USERBYBI_FAILURE = "@USERS/GET_USERBYBI_FAILURE";

export const SET_ISLOADING = "@USERS/SET_ISLOADING";

export const UPLOAD_LITERARY_QUALIFICATION_DOCUMENT_REQUEST = "@USERS/UPLOAD_LITERARY_QUALIFICATION_DOCUMENT_REQUEST";
export const UPLOAD_LITERARY_QUALIFICATION_DOCUMENT_SUCCESS = "@USERS/UPLOAD_LITERARY_QUALIFICATION_DOCUMENT_SUCCESS";
export const UPLOAD_LITERARY_QUALIFICATION_DOCUMENT_FAILURE = "@USERS/UPLOAD_LITERARY_QUALIFICATION_DOCUMENT_FAILURE";

export const UPLOAD_TRAINING_DOCUMENT_REQUEST = "@USERS/UPLOAD_TRAINING_DOCUMENT_RESQUEST";
export const UPLOAD_TRAINING_DOCUMENT_SUCCESS = "@USERS/UPLOAD_TRAINING_DOCUMENT_SUCCESS";
export const UPLOAD_TRAINING_DOCUMENT_FAILURE = "@USERS/UPLOAD_TRAINING_DOCUMENT_FAILURE";

export const DELETE_DOCUMENT_REQUEST = "@USERS/DELETE_DOCUMENT_REQUEST";
export const DELETE_DOCUMENT_SUCCESS = "@USERS/DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_FAILURE = "@USERS/DELETE_DOCUMENT_FAILURE";

export const GET_DOCUMENT_REQUEST = "@USERS/GET_DOCUMENT_REQUEST";
export const GET_DOCUMENT_SUCCESS = "@USERS/GET_DOCUMENT_SUCCESS";
export const GET_DOCUMENT_FAILURE = "@USERS/GET_DOCUMENT_FAILURE";

