import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "../../../services/AxiosService";
import KeycloakService from "../../../services/KeycloakService";
import { setSnackbar } from "../../../store/reducers/users/actionCreators";

const useAlterarPin = () => {
  const [error, setError] = useState({
    oldPin: "",
    newPin: "",
    confirmPin: "",
    confirmationCode: "",
  });

  const [formValues, setFormValues] = useState({
    oldPin: "",
    newPin: "",
    confirmPin: "",
    confirmationCode: "",
  });

  const dispatch = useDispatch();
  const history = useHistory();
  const logsContaId = localStorage.getItem('logsContaId');
  const [isNewPinVisible, setIsNewPinVisible] = useState(false);
  const [isActualPinVisible, setIsActualPinVisible] = useState(false);
  const [showConfirmationCode, setShowConfirmationCode] = useState(false);
  const [isConfirmationPinVisible, setIsConfirmationPinVisible] =
    useState(false);

  const employee = useSelector((state) => state.usersReducer.employee);

  const checkEmptyValues = (formData) => {
    if (formData.oldPin.length === 0) {
      setError((prevState) => {
        return { ...prevState, oldPin: "Este campo deve ser preenchido" };
      });
    } else {
      setError((prevState) => {
        return { ...prevState, oldPin: "" };
      });
    }
    if (formData.newPin.length === 0) {
      setError((prevState) => {
        return { ...prevState, newPin: "Este campo deve ser preenchido" };
      });
    } else {
      setError((prevState) => {
        return { ...prevState, newPin: "" };
      });
    }
    if (formData.confirmPin.length === 0) {
      setError((prevState) => {
        return { ...prevState, confirmPin: "Este campo deve ser preenchido" };
      });
    } else {
      setError((prevState) => {
        return { ...prevState, confirmPin: "" };
      });
    }
  };

  const checkLengths = (formData) => {
    if (formData.oldPin.length > 0 && formData.oldPin.length < 4) {
      setError((prevState) => {
        return {
          ...prevState,
          oldPin: "O pin antigo deve ter no mínimo 4 dígitos",
        };
      });
    } else {
      setError((prevState) => {
        return { ...prevState, oldPin: "" };
      });
    }
    if (formData.newPin.length > 0 && formData.newPin.length < 4) {
      setError((prevState) => {
        return { ...prevState, newPin: "O pin deve ter no mínimo 4 dígitos" };
      });
    } else {
      setError((prevState) => {
        return { ...prevState, newPin: "" };
      });
    }
  };

  const checkConfirm = (formData) => {
    if (formData.newPin !== formData.confirmPin) {
      setError((prevState) => {
        return {
          ...prevState,
          confirmPin: "Os pins não correspondem",
        };
      });
    } else {
      setError((prevState) => {
        return { ...prevState, confirmPin: "" };
      });
    }
  };

  const hasErrors = (error) => {
    return (
      error.oldPin.length > 0 ||
      error.newPin.length > 0 ||
      error.confirmPin.length > 0
    );
  };

  const submitForm = (formData) => {

    const data = {
      phone: employee?.phone,
      oldPin: { value: formData.oldPin },
      newPin: { value: formData.newPin },
      logsContaId: logsContaId
    };
    axios
      .put(`/employees/${employee.id}/access/pin`, data, {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      })
      .then((response) => {
        if (response.data.status === "OK") {
          dispatch(
            setSnackbar({
              snackbarIsOpen: true,
              snackbarMessage: response.data.message,
              snackbarType: "success",
            })
          );
          setShowConfirmationCode(true);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleSubmit = (formData) => {
    checkEmptyValues(formData);
    checkLengths(formData);
    checkConfirm(formData);

    if (!hasErrors(error)) {
      submitForm(formData);
    }
  };

  const sendConfirmation = (formData) => {

    const data = {
      value: formData.confirmationCode,
      logsContaId: logsContaId
    };

    axios
      .put(`/employees/${employee.id}/access/pin/confirmation-code`, data, {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      })
      .then((response) => {
        if (response.data.status === "OK") {
          dispatch(
            setSnackbar({
              snackbarIsOpen: true,
              snackbarMessage: response.data.message,
              snackbarType: "success",
            })
          );
          setShowConfirmationCode(false);
          history.push(`/welcome`);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleConfirmationCodeSubmit = (formData) => {
    sendConfirmation(formData);
  };

  const onFormValueChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "oldPin") {
      setFormValues((prevState) => {
        return { ...prevState, oldPin: value };
      });
    }
    if (name === "newPin") {
      setFormValues((prevState) => {
        return { ...prevState, newPin: value };
      });
    }
    if (name === "confirmPin") {
      setFormValues((prevState) => {
        return { ...prevState, confirmPin: value };
      });
    }
    if (name === "confirmationCode") {
      setFormValues((prevState) => {
        return { ...prevState, confirmationCode: value };
      });
    }
  };

  return {
    error,
    formValues,
    onFormValueChange,
    handleSubmit,
    handleConfirmationCodeSubmit,
    showConfirmationCode,
    isNewPinVisible,
    setIsNewPinVisible,
    isActualPinVisible,
    setIsActualPinVisible,
    isConfirmationPinVisible,
    setIsConfirmationPinVisible,
  };
};

export default useAlterarPin;
