import React, { useState, useRef, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import imgLogo from "../../../assets/img/equadros_logo.png";
import imgLogout from "../../../assets/img/logout.png";
import {
  BurguerIconContainer,
  HamburguerContainer,
  HeaderContainer,
  Links,
  LinksButton,
  LinksContainer,
  LogoContainer,
  NavLink,
} from "./styles";
import { BurguerIcon } from "./icons";
import KeycloakService from "../../../services/KeycloakService";
import { Box, Button, Grid } from "@mui/material";

function TopMenu({ menuItems = [], menuButtons = []}) {
  const [showBurguerMenu, setShowBurguerMenu] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const hamburguerRef = useRef();
  const [activePage, setActivePage] = useState(
    location.pathname.split("/").reverse()[0]
  );
  const onLogout = () => {
    history.push("/");
    KeycloakService.doLogout();
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        hamburguerRef.current &&
        !hamburguerRef.current.contains(event.target)
      ) {
        setShowBurguerMenu(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [hamburguerRef]);

  useEffect(() => {
    setActivePage(location.pathname.split("/").reverse()[0]);
  }, [location]);

  const HamburguerView = (
    <HamburguerContainer visible={showBurguerMenu} ref={hamburguerRef}>
      {menuItems.map((item, i) => {
        return (
          <li key={i}>
            <Link to={item.link}>{item.icon} {item.label}</Link>
          </li>
        );
      })}
      {menuButtons.map((item, i) => {
        return (
          <li key={i}>
            <LinksButton>
               <Button
                title={item.label}
                className={""}
                variant={"primary"}
                onClick={(e) => item.event()}
                size="large"
              >
                {item.icon} {item.label}
              </Button></LinksButton>
          </li>
        );
      })}
      <li>
        <img src={imgLogout} alt="logout" onClick={() => onLogout()} />
      </li>
    </HamburguerContainer>
  );

  return (
    <HeaderContainer>
      <LogoContainer>
        <img
          src={imgLogo}
          onClick={() => history.push("/")}
          alt="App logo"
          style={{ width: "59px", height: "65px", marginRight: "auto" }}
        />
      </LogoContainer>
      <BurguerIconContainer
        alignCenter={!showBurguerMenu}
        onClick={() => setShowBurguerMenu(true)}
      >
        <BurguerIcon fill="#fff" />
        {HamburguerView}
      </BurguerIconContainer>
      <LinksContainer>
        {menuItems.map((item, i) => {
          return (
            <Links key={i} active={activePage === item.urlActive}>
              <NavLink>
                <Link to={item.link}> {item.label}</Link>
              </NavLink>
            </Links>
          );
        })}
        {menuButtons.map((item, i) => {
        return (
          <LinksButton key={i}>
             <Button
                title={item.label}
                className={""}
                variant={"outlined"}
                size="large"
                onClick={(e) => item.event()}
              >
                {item.icon} {item.label}
              </Button>
          </LinksButton>
        );
      })}

        <Links>
          <img src={imgLogout} alt="logout" onClick={() => onLogout()} />
        </Links>
      </LinksContainer>
    </HeaderContainer>
  );
}

export default TopMenu;
